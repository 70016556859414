/*-- Routing --*/
import { Route, Switch, Redirect } from 'react-router-dom';
import { ONBOARDING_ROUTES } from 'lib/routes';

/* API */
import { useAuth0 } from '@auth0/auth0-react';

/* Redux */
import { useSelector } from 'react-redux';

/*-- Pages --*/
import OnboardingContact from 'components/forms/OnboardingContact';
import OnboardingResumeUpload from 'components/forms/OnboardingResumeUpload';
import OnboardingExperience from 'components/forms/OnboardingExperience';
import OnboardingEducation from 'components/forms/OnboardingEducation';
import OnboardingGoals from 'components/forms/OnboardingGoals';
import LoadingRecommendations from 'components/LoadingRecommendations';

/*-- Components --*/
import OnboardingHeader from 'components/layout/OnboardingHeader';

/*-- Styles --*/
import styles from 'styles/login.module.scss';

const OnboardingPagesSwitch = () => {
  const { user } = useAuth0();
  const { needsContactInfo, urlPrefix, loadingRecommendations } = useSelector((state) => state.app);
  const redirectRoute = `${urlPrefix}/${needsContactInfo ? ONBOARDING_ROUTES.CONTACT : ONBOARDING_ROUTES.RESUME}`;

  return loadingRecommendations ? (
    <LoadingRecommendations />
  ) : (
    <>
      <OnboardingHeader />
      <article className={styles.loginFlow}>
        <div className={styles.loginFlow__formContainer}>
          <Switch>
            <Route path={[`/${ONBOARDING_ROUTES.CONTACT}`, `/:lang/${ONBOARDING_ROUTES.CONTACT}`]}>
              {needsContactInfo ? <OnboardingContact user={user} /> : <Redirect to={redirectRoute} />}
            </Route>
            <Route path={[`/${ONBOARDING_ROUTES.RESUME}`, `/:lang/${ONBOARDING_ROUTES.RESUME}`]}>
              <OnboardingResumeUpload />
            </Route>
            <Route path={[`/${ONBOARDING_ROUTES.WORK_EXPERIENCE}`, `/:lang/${ONBOARDING_ROUTES.WORK_EXPERIENCE}`]}>
              <OnboardingExperience />
            </Route>
            <Route path={[`/${ONBOARDING_ROUTES.SKILLS_EDUCATION}`, `/:lang/${ONBOARDING_ROUTES.SKILLS_EDUCATION}`]}>
              <OnboardingEducation />
            </Route>
            <Route path={[`/${ONBOARDING_ROUTES.GOALS}`, `/:lang/${ONBOARDING_ROUTES.GOALS}`]}>
              <OnboardingGoals />
            </Route>
            <Route>
              <Redirect to={redirectRoute} />
            </Route>
          </Switch>
        </div>
      </article>
    </>
  );
};

export default OnboardingPagesSwitch;
