/* Material UI and other UI Dependencies */
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useExternalWindow } from 'lib/utils';
import PropTypes from 'prop-types';

/* styles */
import layoutStyles from 'styles/layout.module.scss';

const CloseTabButton = ({ inlineStyle = {}, className = '' }) => {
  const { closeWindow } = useExternalWindow();

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={closeWindow}
      style={inlineStyle}
      className={`${layoutStyles.closeButton} ${className}`}
      endIcon={<CloseIcon />}
    >
      CLOSE
    </Button>
  );
};

CloseTabButton.propTypes = {
  inlineStyle: PropTypes.object,
  className: PropTypes.string,
};

export default CloseTabButton;
