import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
  Typography,
  TextField,
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import ZipcodeInput from 'components/inputs/ZipcodeInput';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { css } from '@emotion/css';
import cn from 'classnames';

/* Styles */
import cssVars from 'styles/vars.module.scss';
import GenericAutocompleteV2 from '../GenericAutocompleteV2';

/* CONSTANTS */
const SALARY_FILTER = 'salary';
const LOCATION_FILTER = 'location';
const CAREER_PATHS_FILTER = 'career';
const DISTANCE_FILTER = 'distance';
const DATE_POSTED_FILTER = 'datePosted';
const FOCUS_FILTER = 'focus';

const styles = {
  textFieldRoot: css(
    {
      marginTop: '0 !important',
    },
    { label: 'text-field-root' }
  ),
  formControl: css(
    {
      width: '100% !important',
      position: 'relative',
      marginBottom: '0 !important',
      '@media screen and (min-width: 768px)': {
        maxWidth: '203px !important',
      },
    },
    { label: 'form-control' }
  ),
  ratingsSortingFocused: css({
    '@media screen and (max-width: 767px)': {
      height: '155px',
    },
  }),
  selectLabel: css(
    {
      position: 'absolute',
      zIndex: 1,
      fontSize: '12px',
      color: cssVars.darkGray,
      left: '13px',
      top: '13px',
      pointerEvents: 'none',
      '@media screen and (max-width: 767px)': {
        fontSize: '16px',
      },
    },
    { label: 'select-label' }
  ),
  selectMenu: css(
    {
      boxSizing: 'border-box',
      width: '100% !important',
      borderRadius: '0 !important',
      borderLeft: `solid 1px ${cssVars.mediumGray}`,
      borderRight: `solid 1px ${cssVars.mediumGray}`,
      borderBottom: `solid 1px ${cssVars.mediumGray}`,
      '@media screen and (min-width: 768px)': {
        width: '203px !important',
      },
    },
    { label: 'select-menu' }
  ),
  arrowDown: css(
    {
      width: 15,
      position: 'absolute',
      top: 18,
      right: 14,
      zIndex: 2,
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    { label: 'arrow-down' }
  ),
  selectRoot: css(
    {
      boxSizing: 'border-box',
      border: `solid 1px ${cssVars.mediumGray} !important`,
      borderRadius: 0,
      height: 40,
      width: '100%',
      padding: '12px',
      fontSize: '12px',
      left: '-1px',
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: cssVars.darkGray,
        opacity: 1 /* Firefox */,
      },
      '&:-ms-input-placeholder': {
        /* Internet Explorer 10-11 */ color: cssVars.darkGray,
      },
      '&::-ms-input-placeholder': {
        /* Microsoft Edge */ color: cssVars.darkGray,
      },
      '&__tall': {
        height: '56px',
      },
      '&__MobileTall': {
        '@media screen and (max-width: 767px)': {
          height: '56px',
        },
      },
    },
    { label: 'select-root' }
  ),
  selectSelect: css(
    {
      boxSizing: 'border-box !important',
      width: '100% !important',
      height: '40px !important',
      backgroundColor: 'white !important',
      '&__tall': {
        height: '56px !important',
      },
      '&__MobileTall': {
        '@media screen and (max-width: 767px)': {
          height: '58px !important',
        },
      },
    },
    { label: 'select-select' }
  ),
  checkboxRoot: css({
    color: 'black !important',
  }),
  checkboxLabel: css({
    color: cssVars.darkGray,
    fontSize: '12px',
    lineHeight: '18px',
    whiteSpace: 'break-spaces',
  }),
  separator: css(
    {
      width: '100%',
      borderTop: `solid 1px ${cssVars.mediumGray}`,
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      marginTop: '3px',
      marginBottom: '8px',
    },
    { label: 'separator' }
  ),
  focused: css({
    '@media screen and (max-width: 767px)': {
      height: '201px',
    },
  }),
  filterLabel: css({
    marginBottom: '0 !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px !important',
  }),
  formControlLabeLabel: css({
    fontSize: '0.8rem !important',
    color: cssVars.darkGray,
    '@media screen and (max-width: 767px)': {
      fontSize: '0.68rem !important',
    },
  }),
  transparent: css(
    {
      opacity: 0.5,
    },
    { label: 'transparent' }
  ),
  biggerMargin: css(
    {
      marginTop: 32,
    },
    { label: 'bigger-margin' }
  ),
};

function FilterHeader({ title, isOpen, handleToggleOpen, transparent = false, locality }) {
  return (
    <DialogContentText className={cn({ [styles.filterLabel]: true, [styles.transparent]: transparent })}>
      {title}
      <IconButton
        onClick={handleToggleOpen}
        color={locality === 'ar' ? 'secondary' : 'primary'}
        style={{ padding: 8 }}
        size="large"
      >
        {isOpen ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
      </IconButton>
    </DialogContentText>
  );
}

FilterHeader.propTypes = {
  title: PropTypes.string,
  locality: PropTypes.string,
  isOpen: PropTypes.bool,
  handleToggleOpen: PropTypes.func,
  transparent: PropTypes.bool,
};

function JobsFiltersV2({
  popper,
  handleClose,
  handleReset,
  //Focus
  focusValue,
  setFocusValue,
  // Salary
  minSalary,
  maxSalary,
  salaryFilterValues,
  handleChangeSalaryFilterValues,
  // Location
  locations,
  locationsFilterValues,
  handleChangeLocationsFilterValues,
  // Career paths
  careerPaths,
  careerPathsFilterValues,
  handleChangeCareerPathsFilterValues,
  // Distance
  minDistance,
  maxDistance,
  distanceFilterValue,
  handleChangeDistanceFilterValue,
  handleChangeZipcodeFilterValue,
  zipcode,
  zipcodeFailure,
  // Date posted
  datePostedFilterValue,
  handleChangeDatePostedFilterValue,
  AutocompleteComponent = GenericAutocompleteV2,
}) {
  const intl = useIntl();
  const locality = process.env.REACT_APP_LOCALITY;
  const [openFilter, setOpenFilter] = useState('');
  const [locationTransparent, setLocationTransparent] = useState(false);
  const [distanceTransparent, setDistanceTransparent] = useState(false);
  const [zipcodeFieldValue, setZipcodeFieldValue] = useState(zipcode);
  const [zipcodeFieldError, setZipcodeFieldError] = useState(false);

  const localityDict = {
    wi: 'Wisconsin',
    ripl: 'Colorado',
    co: 'Colorado',
    hi: "Hawai'i",
    md: 'Maryland',
    nj: 'New Jersey',
  };

  const [salarySliderValue, setSalarySliderValue] = useState([
    salaryFilterValues[0] || minSalary,
    salaryFilterValues[1] || maxSalary,
  ]);
  const [distanceSliderValue, setDistanceSliderValue] = useState(
    !distanceFilterValue && distanceFilterValue !== 0 ? maxDistance : distanceFilterValue
  );

  const handleToggleOpenFilter = (section) => {
    if (openFilter === section) {
      setOpenFilter('');
    } else {
      setOpenFilter(section);
    }
  };

  const handleChangeSalarySliderValue = (event, newValue) => {
    setSalarySliderValue(newValue);
  };
  const handleChangeDistanceSliderValue = (event, newValue) => {
    setDistanceSliderValue(newValue);
  };
  const handleChangeZipcodeField = (e) => {
    const newZipCode = e.target?.value;
    const newZipCodeLength = e.target?.value?.length;
    setZipcodeFieldValue(newZipCode);
    if (newZipCodeLength === 5) {
      setZipcodeFieldError(false);
    }
    handleChangeZipcodeFilterValue(newZipCodeLength === 5 ? newZipCode : '');
  };
  const handleDistanceSliderClick = () => {
    if (zipcodeFieldValue?.length !== 5) {
      setZipcodeFieldError(true);
    } else {
      setZipcodeFieldError(false);
    }
  };

  const formatCurrency = (value) => {
    return intl.formatNumber(value, {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
    });
  };

  const handleFocusClick = (newVal) => {
    if (newVal === focusValue) {
      setFocusValue('');
    } else {
      setFocusValue(newVal);
    }
  };

  return (
    <div>
      {!popper ? (
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h5" component="h2" style={{ paddingTop: '16px' }}>
            {intl.formatMessage({ id: 'jobs.filter' })}
          </Typography>
        </DialogTitle>
      ) : null}
      <DialogContent>
        {/* Focus Filter */}
        {locality !== 'md' ? (
          <>
            <FilterHeader
              title={intl.formatMessage({ id: 'jobs.filters.jobSearchFocus' })}
              isOpen={openFilter === FOCUS_FILTER}
              handleToggleOpen={() => handleToggleOpenFilter(FOCUS_FILTER)}
              locality={locality}
            />
            <Collapse in={openFilter === FOCUS_FILTER}>
              <Box display="flex">
                <Box ml={1}>
                  <RadioGroup
                    aria-label={intl.formatMessage({ id: 'jobSearchActivity.form.applicationFiled' })}
                    name="job_search_focus"
                    value={focusValue}
                  >
                    <FormControlLabel
                      classes={{ label: styles.formControlLabeLabel }}
                      value="currentCareer"
                      control={
                        <Radio
                          color={locality === 'ar' ? 'secondary' : 'primary'}
                          className={styles.checkBoxRoot}
                          onClick={() => handleFocusClick('currentCareer')}
                        />
                      }
                      label={intl.formatMessage({ id: 'jobs.filters.inMyCareer' })}
                    />
                    <FormControlLabel
                      classes={{ label: styles.formControlLabeLabel }}
                      value="similarCareer"
                      control={
                        <Radio
                          color={locality === 'ar' ? 'secondary' : 'primary'}
                          className={styles.checkBoxRoot}
                          onClick={() => handleFocusClick('similarCareer')}
                        />
                      }
                      label={intl.formatMessage({ id: 'jobs.filters.similarToMyCareer' })}
                    />
                    <FormControlLabel
                      classes={{ label: styles.formControlLabeLabel }}
                      value="newCareer"
                      control={
                        <Radio
                          color={locality === 'ar' ? 'secondary' : 'primary'}
                          className={styles.checkBoxRoot}
                          onClick={() => handleFocusClick('newCareer')}
                        />
                      }
                      label={intl.formatMessage({ id: 'jobs.filters.newCareer' })}
                    />
                  </RadioGroup>
                </Box>
              </Box>
            </Collapse>
            <hr className={styles.separator} />
          </>
        ) : null}
        {/* Career paths Filter */}
        <FilterHeader
          title={intl.formatMessage({ id: 'jobs.filters.careerPaths' })}
          isOpen={openFilter === CAREER_PATHS_FILTER}
          handleToggleOpen={() => handleToggleOpenFilter(CAREER_PATHS_FILTER)}
          locality={locality}
        />
        <Collapse in={openFilter === CAREER_PATHS_FILTER}>
          <Box mb={1}>
            <FormControl className={styles.formControl}>
              <AutocompleteComponent
                activeOptions={careerPathsFilterValues}
                label={intl.formatMessage({ id: 'jobs.filters.startTyping' })}
                options={careerPaths}
                getOptionLabel={(option) => option.title}
                handleChange={(_, newValue) => {
                  handleChangeCareerPathsFilterValues({ target: { value: newValue } });
                }}
              />
            </FormControl>
          </Box>
        </Collapse>
        <hr className={styles.separator} />

        {/* Salary Filter */}
        <FilterHeader
          title={intl.formatMessage({ id: 'jobs.filters.salaryRange' })}
          isOpen={openFilter === SALARY_FILTER}
          handleToggleOpen={() => handleToggleOpenFilter(SALARY_FILTER)}
          locality={locality}
        />

        <Collapse in={openFilter === SALARY_FILTER}>
          <Box px={2}>
            <Slider
              min={minSalary}
              max={maxSalary}
              value={salarySliderValue}
              onChange={handleChangeSalarySliderValue}
              onChangeCommitted={handleChangeSalaryFilterValues}
              aria-labelledby="range-slider"
              valueLabelDisplay="auto"
              valueLabelFormat={formatCurrency}
              marks={[
                {
                  value: minSalary,
                  label: formatCurrency(minSalary),
                },
                {
                  value: maxSalary,
                  label: formatCurrency(maxSalary),
                },
              ]}
            />
          </Box>
        </Collapse>
        <hr className={styles.separator} />

        {/* Location Filter */}
        <FilterHeader
          title={intl.formatMessage({ id: 'jobs.filters.location' })}
          isOpen={openFilter === LOCATION_FILTER}
          handleToggleOpen={() => handleToggleOpenFilter(LOCATION_FILTER)}
          transparent={locationTransparent}
          locality={locality}
        />
        <Collapse in={openFilter === LOCATION_FILTER} className={cn({ [styles.transparent]: locationTransparent })}>
          <Box mb={1}>
            <FormControl className={styles.formControl}>
              <AutocompleteComponent
                activeOptions={locationsFilterValues}
                label={intl.formatMessage({ id: 'jobs.filters.startTyping' })}
                options={locations}
                handleChange={(event, newValue) => {
                  handleChangeDistanceFilterValue(null, maxDistance);
                  setLocationTransparent(false);
                  if (!distanceTransparent && distanceSliderValue !== 100) {
                    setDistanceTransparent(true);
                  }
                  handleChangeLocationsFilterValues(event, newValue);
                }}
              />
            </FormControl>
          </Box>
        </Collapse>
        <hr className={styles.separator} />

        {/* Distance Filter */}
        {maxDistance > 0 ? (
          <>
            <FilterHeader
              title={intl.formatMessage({ id: 'jobs.filters.distance' })}
              isOpen={openFilter === DISTANCE_FILTER}
              handleToggleOpen={() => handleToggleOpenFilter(DISTANCE_FILTER)}
              transparent={distanceTransparent}
              locality={locality}
            />
            <Collapse in={openFilter === DISTANCE_FILTER} className={cn({ [styles.transparent]: distanceTransparent })}>
              <TextField
                error={zipcodeFieldError || zipcodeFailure}
                fullWidth
                helperText={
                  (zipcodeFieldError || zipcodeFailure) &&
                  intl.formatMessage(
                    {
                      id: zipcodeFailure
                        ? 'training.filters.zipcodeFromStateValidation'
                        : 'training.filters.zipcodeValidation',
                    },
                    { state: localityDict[locality] }
                  )
                }
                label={intl.formatMessage({
                  id: zipcodeFieldValue?.length ? 'training.filters.distanceFrom' : 'training.filters.zipcode',
                })}
                margin="normal"
                classes={{ root: styles.textFieldRoot }}
                onChange={handleChangeZipcodeField}
                type="text"
                variant="filled"
                value={zipcodeFieldValue}
                InputProps={{
                  inputComponent: ZipcodeInput,
                }}
                inputProps={{
                  title: intl.formatMessage({ id: 'training.filters.zipcode' }),
                }}
              />
              <Box
                pr={3}
                pl={3}
                onClick={handleDistanceSliderClick}
                className={cn({ [styles.biggerMargin]: zipcodeFailure })}
              >
                <Slider
                  disabled={zipcodeFieldValue?.length !== 5}
                  min={minDistance}
                  max={maxDistance}
                  value={distanceSliderValue}
                  onChange={handleChangeDistanceSliderValue}
                  onChangeCommitted={(event, newValue) => {
                    handleChangeLocationsFilterValues(null, []);
                    setDistanceTransparent(false);
                    if (!locationTransparent && locationsFilterValues.length) {
                      setLocationTransparent(true);
                    }
                    handleChangeDistanceFilterValue(event, newValue);
                  }}
                  aria-labelledby="range-slider"
                  valueLabelDisplay="auto"
                  marks={[
                    {
                      value: minDistance,
                      label: `${intl.formatNumber(minDistance)} ${intl.formatMessage({ id: 'jobs.filters.miles' })}`,
                    },
                    {
                      value: maxDistance,
                      label: `${intl.formatNumber(maxDistance)}+ ${intl.formatMessage({ id: 'jobs.filters.miles' })}`,
                    },
                  ]}
                />
              </Box>
            </Collapse>
            <hr className={styles.separator} />
            <Box mb={2} />
          </>
        ) : null}

        {/* Date posted Filter */}
        <FilterHeader
          title={intl.formatMessage({ id: 'jobs.filters.datePosted' })}
          isOpen={openFilter === DATE_POSTED_FILTER}
          handleToggleOpen={() => handleToggleOpenFilter(DATE_POSTED_FILTER)}
          locality={locality}
        />

        <Collapse in={openFilter === DATE_POSTED_FILTER}>
          <Box display="flex">
            <Box ml={1}>
              <RadioGroup
                aria-label={intl.formatMessage({ id: 'jobSearchActivity.form.applicationFiled' })}
                name="application_filed"
                value={datePostedFilterValue}
                onChange={handleChangeDatePostedFilterValue}
              >
                <FormControlLabel
                  classes={{ label: styles.formControlLabeLabel }}
                  value="anyTime"
                  control={
                    <Radio color={locality === 'ar' ? 'secondary' : 'primary'} className={styles.checkBoxRoot} />
                  }
                  label={intl.formatMessage({ id: 'jobs.filters.datePosted.anyTime' })}
                />
                <FormControlLabel
                  classes={{ label: styles.formControlLabeLabel }}
                  value="pastDay"
                  control={
                    <Radio color={locality === 'ar' ? 'secondary' : 'primary'} className={styles.checkBoxRoot} />
                  }
                  label={intl.formatMessage({ id: 'jobs.filters.datePosted.pastDay' })}
                />
                <FormControlLabel
                  classes={{ label: styles.formControlLabeLabel }}
                  value="pastWeek"
                  control={
                    <Radio color={locality === 'ar' ? 'secondary' : 'primary'} className={styles.checkBoxRoot} />
                  }
                  label={intl.formatMessage({ id: 'jobs.filters.datePosted.pastWeek' })}
                />
                <FormControlLabel
                  classes={{ label: styles.formControlLabeLabel }}
                  value="pastMonth"
                  control={
                    <Radio color={locality === 'ar' ? 'secondary' : 'primary'} className={styles.checkBoxRoot} />
                  }
                  label={intl.formatMessage({ id: 'jobs.filters.datePosted.pastMonth' })}
                />
              </RadioGroup>
            </Box>
          </Box>
        </Collapse>
        <hr className={styles.separator} />
        <Box mb={2} />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#e0e0e0',
            '&:hover': { backgroundColor: '#d0d0d0' },
            borderRadius: 0,
            color: 'rgba(0, 0, 0, 0.87)',
          }}
          onClick={() => {
            // Reset local state
            setSalarySliderValue([minSalary, maxSalary]);
            setDistanceSliderValue(maxDistance);
            // Reset parent state
            handleReset();
          }}
        >
          {intl.formatMessage({ id: 'jobs.filters.reset' })}
        </Button>
        <Button variant="contained" onClick={handleClose} color="primary">
          {intl.formatMessage({ id: 'layout.hamburger.close' })}
        </Button>
      </DialogActions>
    </div>
  );
}

JobsFiltersV2.propTypes = {
  popper: PropTypes.bool,
  handleClose: PropTypes.func,
  handleReset: PropTypes.func,
  minSalary: PropTypes.number,
  maxSalary: PropTypes.number,
  salaryFilterValues: PropTypes.arrayOf(PropTypes.number.isRequired),
  handleChangeSalaryFilterValues: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.string.isRequired),
  locationsFilterValues: PropTypes.arrayOf(PropTypes.string.isRequired),
  handleChangeLocationsFilterValues: PropTypes.func,
  careerPaths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      soc: PropTypes.string.isRequired,
    })
  ),
  careerPathsFilterValues: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      soc: PropTypes.string.isRequired,
    })
  ),
  handleChangeCareerPathsFilterValues: PropTypes.func,
  minDistance: PropTypes.number,
  maxDistance: PropTypes.number,
  distanceFilterValue: PropTypes.number,
  handleChangeDistanceFilterValue: PropTypes.func,
  datePostedFilterValue: PropTypes.string,
  handleChangeDatePostedFilterValue: PropTypes.func,
  handleChangeZipcodeFilterValue: PropTypes.func,
  zipcode: PropTypes.string,
  zipcodeFailure: PropTypes.bool,
  focusValue: PropTypes.string,
  setFocusValue: PropTypes.func,
};

export default JobsFiltersV2;
