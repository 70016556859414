import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { pathOr } from 'ramda';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { updateExperience } from 'shared/src/lib/API/experience';
import { useQuery } from '@tanstack/react-query';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setEducation } from 'app/slices/onboardingSlice';
import { setExperienceForm } from 'app/slices/experienceSlice';
import { setCurrentSoc } from 'app/slices/jobsSlice';

/* Material UI and other UI Dependencies */
import Typography from '@mui/material/Typography';
import OnboardingSteps from 'components/OnboardingSteps';
import { Box, CircularProgress } from '@mui/material';
import { EducationForm, SkillsAutocompleteV2 } from 'shared/components';
import { useRateUserSkill, useUserRecSkills } from 'shared/hooks';

/* Analytics */
import { trackEvent } from 'lib/analytics';

export default function OnboardingEducation() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { urlPrefix } = useSelector((state) => state.app);
  const { education } = useSelector((state) => state.onboarding);

  const [submitting, setSubmitting] = useState(false);
  const [uploadedResume] = useState(false);

  const educationBase = { degree: '', field: '', school_name: '' };

  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/'],
    refetchOnMount: 'always',
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const userSkillsQuery = useUserRecSkills();
  const likedSkills = userSkillsQuery?.data?.filter((skill) => skill.rating === 1) ?? [];
  const rateUserSkill = useRateUserSkill();

  const validationSchema = yup.object({
    degree: yup.string('forms.onboarding.educationValidation').required('forms.onboarding.educationRequired'),
  });

  let initialValues = {
    ...educationBase,
    language: intl.locale,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    async onSubmit({ school_name, degree, field, ...rest }) {
      trackEvent('ONBOARDING_SKILLS_NEXT');
      setSubmitting(true);

      const education = [{ school_name, degree, field, ...rest }];

      // Call trackEvent function with one argument if school_name has length
      if (school_name?.length) {
        trackEvent('ONBOARDING_EDUCATION_NAME');
      }

      if (field?.length) {
        trackEvent('ONBOARDING_EDUCATION_FOS');
      }
      const experienceResponse = await updateExperience({ education });
      dispatch(setExperienceForm({ education }));
      const currentSoc = pathOr('', ['job_experience', 0, 'soc'], experienceResponse);
      if (currentSoc !== '') {
        dispatch(setCurrentSoc(currentSoc));
      }
      setSubmitting(false);
      history.push(`${urlPrefix}/onboarding/goals`);
    },
  });

  useEffect(() => {
    if (experienceQuery.isFetching || !experienceQuery.isSuccess) {
      return;
    }
    if (Object.keys(education).length) {
      formik.setValues(education);
      dispatch(setEducation({}));
    } else if (!experienceQuery.isFetching && experienceQuery.isSuccess) {
      formik.setValues({ ...formik.values, ...pathOr({}, ['data', 'education', 0], experienceQuery) });
    }
  }, [experienceQuery.isSuccess, experienceQuery.isFetching]);

  const handleAddSkill = (newSkill) => {
    rateUserSkill.mutate({ skill: newSkill, rating: 1 });
  };

  const handleRemoveSkill = (removedSkill) => {
    rateUserSkill.mutate({ skill: removedSkill, rating: -1 });
  };

  const headerProps = { align: 'center', variant: 'h5', gutterBottom: true };

  return (
    <>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3}>
          <Typography {...headerProps} variant="h1">
            {intl.formatMessage({ id: 'onboarding.resume.title' })}
          </Typography>
        </Box>
        <OnboardingSteps activeStep={1} />
      </div>
      <hr className={layoutStyles.separatorBold} />

      <div className={styles.onboardingExperience__cardsContainer}>
        {!experienceQuery.isLoading && !experienceQuery.isFetching && !userSkillsQuery.isLoading ? (
          <EducationForm
            formik={formik}
            classNames={cn(styles.loginFlow__form, styles.signInFormNoStripe, onboardingStyles.formContainer)}
            uploadedResume={uploadedResume}
            submitting={submitting}
            submitLabel={intl.formatMessage({ id: 'forms.onboarding.next' })}
            onboarding
          >
            <Box mb={2} mt={2}>
              <Typography {...headerProps}>
                {intl.formatMessage({ id: 'forms.onboarding.education.skills' }, { maxSkills: 10 })}
              </Typography>
            </Box>
            {!!likedSkills.length && (
              <Box mb={1}>{intl.formatMessage({ id: 'forms.onboarding.education.yourSkills' })}</Box>
            )}
            <Box mt={2}>
              <SkillsAutocompleteV2
                addSkillHandler={handleAddSkill}
                removeSkillHandler={handleRemoveSkill}
                activeSkills={likedSkills}
                onboarding
                isDisabled={userSkillsQuery.isLoading}
              />
            </Box>
          </EducationForm>
        ) : (
          <Box m={2} pb={6} pt={4} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </div>
    </>
  );
}
