import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Radio,
  RadioGroup,
  CircularProgress,
} from '@mui/material';
import DateFnsUtils from '@date-io/date-fns';
import { apiRequestV1 } from 'lib/API';

import { PhoneNumberInput } from 'shared/components';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

/**
 * i18n
 */
import { useIntl } from 'react-intl';

/* Analytics */
import { trackEvent } from 'lib/analytics';

import styles from 'styles/apply-job-form.module.scss';
import onbStyles from 'styles/onboarding.module.scss';
import layoutStyles from 'styles/layout.module.scss';

import BrowseDocuments from 'components/BrowseDocuments';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function ScheduleMeeting({
  addActivityItem,
  setOpen,
  updateActivityItem,
  setGeneralActivity,
  selectedActivity,
  editting = false,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [refreshConter, setRefreshConter] = useState(0);
  const [documentsLinks, setDocumentsLinks] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    setRefreshConter(refreshConter + 1);
    if (selectedActivity.upload_documents && selectedActivity.upload_documents.length > 0) {
      setDocumentsLinks([...selectedActivity.upload_documents]);
    }
  }, [selectedActivity, editting]);

  useEffect(() => {
    if (documentsLinks.length > 0) {
      documentsLinks.forEach((doc, idx) => {
        apiRequestV1(
          'get',
          `/worksearch?worksearch_id=${encodeURIComponent(
            selectedActivity.worksearch_id
          )}&file_uuid=${encodeURIComponent(doc.file_uuid)}`
        ).then((res) => {
          setDocumentsLinks([
            ...documentsLinks.slice(0, idx),
            { ...doc, downloadUrl: res.download_url },
            ...documentsLinks.slice(idx + 1),
          ]);
        });
      });
    }
  }, [documentsLinks]);

  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; // eslint-disable-line no-useless-escape
  const emailRegExp = /^\S+@\S+\.\w\w+$/; // eslint-disable-line no-useless-escape

  const validationSchema = yup.object({
    contact_phone_number: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumberRequired' }))
      .matches(phoneRegExp, intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumberRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumberRequired' })),
    contact_email: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .matches(emailRegExp, intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    activity_date: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    attended_skills_assessment: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    result_of_coach_meeting: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    method_of_coach_meeting: yup
      .array()
      .min(1, intl.formatMessage({ id: 'forms.onboarding.minSelected' }, { length: 1 })),
  });

  const toggleCheckbox = (currentValues, key) => {
    const valuesSet = new Set(currentValues);
    if (valuesSet.has(key)) {
      valuesSet.delete(key);
    } else {
      valuesSet.add(key);
    }
    return [...valuesSet];
  };

  const defaultValues = {
    contact_name: '',
    contact_phone_number: '',
    contact_email: '',
    activity_date: new Date(),
    result_of_coach_meeting: '',
    method_of_coach_meeting: [],
    notes: '',
    upload_documents: [],
    attended_skills_assessment: '',
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={
        selectedActivity
          ? {
              ...selectedActivity,
              upload_documents: [],
              attended_skills_assessment: selectedActivity.attended_skills_assessment ? 'yes' : 'no',
              activity_date: new Date(
                selectedActivity.activity_date.slice(0, 4),
                selectedActivity.activity_date.slice(5, 7) - 1,
                selectedActivity.activity_date.slice(8, 10)
              ),
            }
          : defaultValues
      }
      onSubmit={async (values) => {
        setSubmitting(true);
        let year = values.activity_date.getFullYear();
        let month = values.activity_date.getMonth() + 1;
        let day = values.activity_date.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        const vals4submit = {
          ...values,
          activity_type: 'job_coach_meeting',
          activity_date: `${year}-${month}-${day}`,
        };
        vals4submit.upload_documents = values.upload_documents.map((doc) => ({
          filename: doc.name,
          upload_contents: doc.contents,
        }));
        if (editting) {
          trackEvent('WEEK_VIEWACTIVITY_SAVE');
        } else {
          trackEvent('WEEK_SAVEACTIVITY_JOBCOACH');
        }
        apiRequestV1(editting ? 'put' : 'post', '/worksearch', { data: { activity: vals4submit } })
          .then(({ activity, status }) => {
            if (editting) {
              updateActivityItem(activity);
            } else {
              addActivityItem(activity);
            }
            setGeneralActivity(status);
            setOpen(false);
            setSubmitting(false);
          })
          .catch((err) => {
            setSubmitting(false);
            console.log(err);
          });
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => {
        function isValidDate(d) {
          return d instanceof Date && !isNaN(d);
        }
        const handleDateChange = (date) => {
          if (isValidDate(date)) {
            setFieldValue('activity_date', date);
          } else {
            setFieldValue('activity_date', '');
          }
        };
        return (
          <form onSubmit={handleSubmit} className={styles.loginFlow__form}>
            {submitting ? (
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <div className={styles.fields}>
                  <div style={{ position: 'relative' }}>
                    <TextField
                      error={touched.contact_name && Boolean(errors.contact_name)}
                      disabled={submitting || (editting && !editMode)}
                      fullWidth
                      helperText={touched.contact_name && errors.contact_name}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactName' })}
                      margin="normal"
                      name="contact_name"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.contact_name}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactName' }),
                      }}
                    />
                    <HelpOutlineIcon className={styles.helperTextIcon} />
                    <span className={cn(styles.fieldDescription, styles.fieldDescriptionSemiClose)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.contactNameSub' })}
                    </span>
                  </div>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      error={touched.contact_phone_number && Boolean(errors.contact_phone_number)}
                      disabled={submitting || (editting && !editMode)}
                      fullWidth
                      helperText={touched.contact_phone_number && errors.contact_phone_number}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumber' })}
                      margin="normal"
                      name="contact_phone_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.contact_phone_number}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumber' }),
                      }}
                      InputProps={{
                        inputComponent: PhoneNumberInput,
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      error={touched.contact_email && Boolean(errors.contact_email)}
                      disabled={submitting || (editting && !editMode)}
                      fullWidth
                      helperText={touched.contact_email && errors.contact_email}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' })}
                      name="contact_email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      classes={{ root: styles.textFieldRoot }}
                      type="text"
                      value={values.contact_email}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' }),
                      }}
                    />
                  </div>
                  <div>
                    <FormControl>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          invalidDateMessage={intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })}
                          disabled={submitting || (editting && !editMode)}
                          disableToolbar
                          autoOk
                          variant="filled"
                          inputVariant="filled"
                          format="MM/dd/yyyy"
                          classes={{ root: styles.textFieldRoot }}
                          label={intl.formatMessage({ id: 'jobSearchActivity.form.dateOfCoachMeeting' })}
                          value={values.activity_date}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </div>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      disabled={submitting || (editting && !editMode)}
                      error={touched.result_of_coach_meeting && Boolean(errors.result_of_coach_meeting)}
                      fullWidth
                      helperText={touched.result_of_coach_meeting && errors.result_of_coach_meeting}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.resultOfCoachMeeting' })}
                      name="result_of_coach_meeting"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.result_of_coach_meeting}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfCoachMeeting' }),
                      }}
                    />
                  </div>
                </div>
                <div className={styles.checksAndRadios}>
                  <div className={styles.checks}>
                    <span
                      className={cn({
                        [styles.fieldLabel]: true,
                        [styles.fieldLabel__errored]: touched.method_of_coach_meeting && errors.method_of_coach_meeting,
                      })}
                    >
                      {intl.formatMessage({ id: 'jobSearchActivity.form.methodOfCoachMeeting' })}*
                    </span>
                    <span className={cn(styles.fieldDescription, styles.labelPadding)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.allThatApply' })}
                    </span>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.form.inPerson' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            value={values.method_of_coach_meeting.includes('in_person')}
                            color="primary"
                            onChange={() =>
                              handleChange({
                                target: {
                                  name: 'method_of_coach_meeting',
                                  value: toggleCheckbox(values.method_of_coach_meeting, 'in_person'),
                                },
                              })
                            }
                            name="method_of_coach_meeting"
                          />
                        }
                      />
                    </FormControl>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.form.virtual' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            value={values.method_of_coach_meeting.includes('virtual')}
                            color="primary"
                            onChange={() =>
                              handleChange({
                                target: {
                                  name: 'method_of_coach_meeting',
                                  value: toggleCheckbox(values.method_of_coach_meeting, 'virtual'),
                                },
                              })
                            }
                            name="method_of_coach_meeting"
                          />
                        }
                      />
                    </FormControl>
                    <FormControl classes={{ root: onbStyles.formControlRoot }}>
                      <FormControlLabel
                        labelPlacement="end"
                        classes={{ root: onbStyles.formControlLabelRoot, label: onbStyles.formControlLabeLabel }}
                        label={intl.formatMessage({ id: 'jobSearchActivity.form.telephone' })}
                        control={
                          <Checkbox
                            className={styles.checkBoxRoot}
                            disabled={submitting || (editting && !editMode)}
                            value={values.method_of_coach_meeting.includes('telephone')}
                            color="primary"
                            onChange={(e) =>
                              handleChange({
                                target: {
                                  name: 'method_of_coach_meeting',
                                  value: toggleCheckbox(values.method_of_coach_meeting, 'telephone'),
                                },
                              })
                            }
                            name="method_of_coach_meeting"
                          />
                        }
                      />
                    </FormControl>
                    <div style={{ position: 'relative', top: '-27px', left: '-26px' }}>
                      {touched.method_of_coach_meeting && errors.method_of_coach_meeting && (
                        <FormHelperText classes={{ root: onbStyles.tosHelperTextRoot }}>
                          {errors.method_of_coach_meeting}
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                  <FormControl
                    component="fieldset"
                    error={touched.attended_skills_assessment && Boolean(errors.attended_skills_assessment)}
                    className={styles.radios}
                  >
                    <span
                      className={cn({
                        [styles.fieldLabel]: true,
                        [styles.fieldLabel__errored]:
                          touched.attended_skills_assessment && errors.attended_skills_assessment,
                      })}
                    >
                      {intl.formatMessage({ id: 'jobSearchActivity.form.attendedSkills' })}*
                    </span>
                    <span className={cn(styles.fieldDescription, styles.labelPadding)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.selectOne' })}
                    </span>
                    <RadioGroup
                      aria-label={intl.formatMessage({ id: 'jobSearchActivity.form.attendedSkills' })}
                      name="attended_skills_assessment"
                      value={values.attended_skills_assessment}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        classes={{ label: onbStyles.formControlLabeLabel }}
                        value="yes"
                        control={
                          <Radio
                            color="primary"
                            disabled={submitting || (editting && !editMode)}
                            className={styles.checkBoxRoot}
                          />
                        }
                        label={intl.formatMessage({ id: 'jobSearchActivity.form.yes' })}
                      />
                      <FormControlLabel
                        classes={{ label: onbStyles.formControlLabeLabel }}
                        value="no"
                        control={
                          <Radio
                            color="primary"
                            disabled={submitting || (editting && !editMode)}
                            className={styles.checkBoxRoot}
                          />
                        }
                        label={intl.formatMessage({ id: 'jobSearchActivity.form.no' })}
                      />
                    </RadioGroup>
                    <div style={{ position: 'relative', top: '-27px', left: '-26px' }}>
                      {touched.attended_skills_assessment && errors.attended_skills_assessment && (
                        <FormHelperText classes={{ root: onbStyles.tosHelperTextRoot }}>
                          {errors.attended_skills_assessment}
                        </FormHelperText>
                      )}
                    </div>
                  </FormControl>
                </div>
                <div className={styles.boxes}>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.uploadDocuments' })}
                      </span>
                    </Box>
                    <Field
                      name="upload_documents"
                      disabled={submitting || (editting && !editMode)}
                      component={BrowseDocuments}
                    />
                    <div className={styles.downloadLinks}>
                      {documentsLinks.map((doc) =>
                        doc.downloadUrl ? (
                          <a href={doc.downloadUrl} key={doc.downloadUrl}>
                            {doc.filename}
                          </a>
                        ) : (
                          <span key={JSON.stringify(doc)}>...</span>
                        )
                      )}
                    </div>
                  </div>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      </span>
                    </Box>
                    <TextField
                      disabled={submitting || (editting && !editMode)}
                      placeholder={intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      multiline
                      rows={5}
                      variant="outlined"
                      InputProps={{ style: { fontSize: 12, height: '112px' } }}
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="notes"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <Box display="flex" justifyContent="space-between" mt={4}>
              <a
                href="https://www.honolulu.gov/cms-dcs-menu/site-dcs-sitearticles/735-job-seekers.html"
                target="_blank"
                rel="noopener noreferrer"
                className={layoutStyles.link}
              >
                {intl.formatMessage({ id: 'jobSearchActivity.form.meetWithCoach' })}
              </a>
              <Box display="flex">
                <Box mr={1}>
                  <Button
                    color="primary"
                    disabled={submitting}
                    size="large"
                    type="button"
                    onClick={() => setOpen(false)}
                    variant="contained"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })
                    )}
                  </Button>
                </Box>
                {editting && !editMode ? (
                  <Button
                    color="secondary"
                    disabled={submitting}
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={(evt) => {
                      evt.preventDefault();
                      trackEvent('WEEK_VIEWACTIVITY_EDIT');
                      setEditMode(true);
                    }}
                  >
                    {intl.formatMessage({ id: 'layout.header.edit' })}
                  </Button>
                ) : (
                  <Button color="secondary" disabled={submitting} size="large" type="submit" variant="contained">
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.save' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.save' })
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}
ScheduleMeeting.propTypes = {
  addActivityItem: PropTypes.func,
  setOpen: PropTypes.func,
  updateActivityItem: PropTypes.func,
  setGeneralActivity: PropTypes.func,
  selectedActivity: PropTypes.bool,
  editting: PropTypes.bool,
};
export default ScheduleMeeting;
