import cn from 'classnames';
import PropTypes from 'prop-types';

/* Styles */
import styles from 'styles/search-activity-card.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Material UI and other UI Dependencies */
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

/* i18n */
import { useIntl } from 'react-intl';

const SkillCard = ({
  item = { activity_type: 'add' },
  type,
  date = '',
  title = '',
  notClickable = false,
  onClick = () => {},
}) => {
  const intl = useIntl();
  if (item.activity_type === 'applied_for_job') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.applied' }, { title: item.position_applied_for });
  }
  if (item.activity_type === 'attend_job_seminar') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.attendedSeminar' }, { title: item.company_name });
  }
  if (item.activity_type === 'resume_upload') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.uploadedResume' });
  }
  if (item.activity_type === 'job_coach_meeting') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.scheduledMeeting' });
  }
  if (item.activity_type === 'interview_with_employer') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.interviewed' });
  }
  if (item.activity_type === 'follow_up_with_employer') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.followedUp' });
  }
  if (item.activity_type === 'register_with_school') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.registered' });
  }

  return (
    <div
      className={cn({
        [styles.card]: true,
        [styles.cardYellow]: type === 'ADD_NEW',
        [styles.notClickable]: notClickable,
      })}
      onClick={onClick}
    >
      <div>
        <div className={styles.iconSection}>
          <span
            className={cn({
              [styles.icon]: true,
              [styles.completeIcon]: type !== 'ADD_NEW',
              [styles.addNewIcon]: type === 'ADD_NEW',
            })}
          >
            {type !== 'ADD_NEW' && <CheckIcon className={styles.checkIcon} />}
            {type === 'ADD_NEW' && <AddIcon className={styles.addIcon} />}
          </span>
          <span>
            {intl.formatMessage({
              id: type !== 'ADD_NEW' ? 'jobSearchActivity.card.complete' : 'jobSearchActivity.card.addNew',
            })}
          </span>
        </div>
        <span className={styles.card__title}>{title}</span>
      </div>

      <div className={styles.dateSection}>
        {type !== 'ADD_NEW' ? (
          date
        ) : (
          <span className={cn(layoutStyles.link, styles.addActivityLink)}>
            {intl.formatMessage({ id: 'jobSearchActivity.card.addActivity' })}
          </span>
        )}
      </div>
    </div>
  );
};

SkillCard.propTypes = {
  item: PropTypes.any,
  type: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  notClickable: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SkillCard;
