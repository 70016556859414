import { createSlice } from '@reduxjs/toolkit';
import { defaultLanguage } from 'i18n/config';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    drawerOpen: false,
    loading: true,
    loadingRecommendations: false,
    loadingLanguage: false,
    needsContactInfo: false,
    needsOnboarding: false,
    contactUpdate: false,
    preferredName: '',
    unrecoverableError: false,
    newHero: true,
    locale: defaultLanguage,
    user_settings: {},
    chat_welcome_shown: false,
    survey: {
      completed: false,
      loaded: false,
    },
    urlPrefix: '',
    activePath: '',
    onboarding_guid: {},
    newUser: false,
  },
  reducers: {
    setNewUser: (state, action) => {
      state.newUser = action.payload;
    },
    setActivePath: (state, action) => {
      state.activePath = action.payload.split('/').reverse()[0];
    },
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
      state.urlPrefix = action.payload === 'en' ? '' : `/${action.payload}`;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingRecommendations: (state, action) => {
      state.loadingRecommendations = action.payload;
    },
    setLoadingLanguage: (state, action) => {
      state.loadingLanguage = action.payload;
    },
    setNeedsContactInfo: (state, action) => {
      state.needsContactInfo = action.payload;
    },
    setNeedsOnboarding: (state, action) => {
      state.needsOnboarding = action.payload;
    },
    setContactUpdate: (state, action) => {
      state.contactUpdate = action.payload;
    },
    setPreferredName: (state, action) => {
      state.preferredName = action.payload;
    },
    setUnrecoverableError: (state, action) => {
      state.unrecoverableError = action.payload;
    },
    setUserSettings: (state, action) => {
      state.user_settings = { ...state.user_settings, ...action.payload };
    },
    setSurvey: (state, action) => {
      state.survey = action.payload;
    },
    setWelcomeShown: (state, action) => {
      state.chat_welcome_shown = action.payload;
    },
    setOnboardingGuid: (state, action) => {
      state.onboarding_guid = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setActivePath,
  setDrawerOpen,
  setLoading,
  setLoadingRecommendations,
  setLoadingLanguage,
  setNeedsContactInfo,
  setSurvey,
  setWelcomeShown,
  setNeedsOnboarding,
  setPreferredName,
  setUnrecoverableError,
  setLocale,
  setUserSettings,
  setContactUpdate,
  setOnboardingGuid,
  setNewUser,
} = appSlice.actions;

export default appSlice.reducer;
