import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useMediaQuery from '@mui/material/useMediaQuery';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import filterStyles from 'styles/filter-input.module.scss';

/* API */
import { apiRequestV1 } from 'lib/API';
import { apiRequest } from 'shared/API';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import {
  addAppliedId,
  removeAppliedId,
  setTagsActive,
  setKeywordFilter,
  setLocationsFilterValues,
  setSalaryFilterValues,
  setFilterOptions,
  setCareerPathsFilterValues,
  setDistanceFilterValue,
  setDatePostedFilterValue,
  setZipcodeFilterValue,
} from 'app/slices/jobsSlice';
import { setActivities, setStatus } from 'app/slices/workSearchSlice';
import { useInfiniteJobs, useRateJob, useInfiniteRatings } from 'shared/hooks';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Container, CircularProgress, Typography, Box, Button, Dialog } from '@mui/material';
import ScrollToTopButton from 'components/ScrollToTopButton';
import { useSnackbar } from 'notistack';

/* UI Components */
import {
  Tag,
  RecommendationsCard,
  CustomNotification,
  JobFiltersV2,
  KeywordSearchInput,
  FiltersPopper,
  JobNotificationContents,
} from 'shared/components';

/* Utils */
import { debounce } from 'lib/performance';

const ABBR_BY_TAG = {
  currentCareer: 'es',
  similarCareer: 'slm',
  newCareer: 'rec',
};

const ACTIVE_TAGS_DICT = {
  currentCareer: 'inMyCareer',
  similarCareer: 'similarToMyCareer',
  newCareer: 'newCareer',
};

const DATE_POSTED_TO_DAYS = {
  anyTime: 0,
  pastDay: 1,
  pastWeek: 7,
  pastMonth: 30,
};

const Jobs = () => {
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [resetCounter, setResetCounter] = useState(0);

  const {
    filterOptions,
    keywordFilter,
    activeSoc,

    tagsActive,
    appliedLookup,
    locationsFilterValues,
    careerPathsFilterValues,
    salaryFilterValues,
    distanceFilterValue,
    datePostedFilterValue,
    zipcodeFilterValue,
  } = useSelector((state) => state.jobs);
  const zipcode = useSelector((state) => state.experience.form.zipcode);
  const { loadingLanguage } = useSelector((state) => state.app);

  const filtersQuery = useQuery({
    queryKey: [
      '/users/me/jobs_filters',
      {
        params: {
          ...(tagsActive ? { filter_by_tag: ABBR_BY_TAG[tagsActive] } : {}),
          ...(locationsFilterValues && locationsFilterValues.length
            ? { filter_by_city: locationsFilterValues.join('|') }
            : {}),
          ...(salaryFilterValues[0] > 0 ? { salary_min: salaryFilterValues[0] } : {}),
          ...(salaryFilterValues[1] > 0 ? { salary_max: salaryFilterValues[1] } : {}),
          ...(careerPathsFilterValues.length > 0
            ? { filter_by_soc: careerPathsFilterValues.map((c) => c.soc).join('|') }
            : {}),
          ...(DATE_POSTED_TO_DAYS[datePostedFilterValue]
            ? { since_date_posted: DATE_POSTED_TO_DAYS[datePostedFilterValue] }
            : {}),
          ...(distanceFilterValue !== 100 && zipcodeFilterValue?.length === 5
            ? { distance_max: distanceFilterValue, zipcode: zipcodeFilterValue }
            : {}),
        },
      },
    ],
    queryFn: async ({ queryKey }) => apiRequest('GET', queryKey[0], queryKey[1]),
    onSuccess: (data) => {
      dispatch(
        setFilterOptions({
          minSalary: data.jobs_salary_low,
          maxSalary: data.jobs_salary_high,
          locations: [...(data.cities ? data.cities : [])].sort(),
          careerPaths: data.career_paths,
          minDistance: data.distance_min,
          maxDistance: data.distance_max,
        })
      );
      if (data.distance_max && data.distance_max !== 100 && distanceFilterValue === 100) {
        dispatch(setDistanceFilterValue(data.distance_max));
      }
      if (zipcodeFilterValue === '' && zipcode !== '') {
        dispatch(setZipcodeFilterValue(zipcode));
      }
    },
  });

  const filters = {
    filter_by_name: keywordFilter,
    filter_by_tag: ABBR_BY_TAG[tagsActive],
    filter_by_soc: careerPathsFilterValues,
    filter_by_city: locationsFilterValues,
    since_date_posted: DATE_POSTED_TO_DAYS[datePostedFilterValue],
    distance_max: distanceFilterValue,
    zipcode: zipcodeFilterValue,
    salary_min: salaryFilterValues[0],
    salary_max: salaryFilterValues[1],
  };

  const { isSuccess, data, isFetching, isFetched, hasNextPage, fetchNextPage, failureReason } =
    useInfiniteJobs(filters);
  const rateJob = useRateJob(filters);

  const intl = useIntl();
  const ratingsQuery = useInfiniteRatings({});
  const allRatings = ratingsQuery.isSuccess ? ratingsQuery.data.pages.flat(1) : [];
  const [rated, setRated] = useState(false);

  const worksearch_status = useSelector((state) => state.worksearch.status);
  const worksearch_activities = useSelector((state) => state.worksearch.activities);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  let history = useHistory();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const { enqueueSnackbar } = useSnackbar();
  const searchInputEl = useRef(null);

  const debouncedSetKeywordFilter = debounce((val) => dispatch(setKeywordFilter(val)), 400);

  const filtersActive =
    !filtersQuery.isLoading &&
    (careerPathsFilterValues.length > 0 ||
      locationsFilterValues.length > 0 ||
      keywordFilter.length > 0 ||
      datePostedFilterValue !== 'anyTime' ||
      salaryFilterValues[0] !== 0 ||
      salaryFilterValues[1] !== 0 ||
      tagsActive !== '' ||
      (zipcodeFilterValue?.length === 5 && distanceFilterValue !== filterOptions.maxDistance));

  const allJobs = isSuccess ? data.pages.flat(1) : [];
  const responses = allJobs.slice(0, 10);

  /**
   * This additional response items will be used with the 'ShowMore' button
   * if there are no filters applied
   */
  const more = allJobs.slice(10);

  const showMore = () => {
    trackEvent('JOBS_MORE');
    fetchNextPage();
  };

  const handleApplyClick = (item) => {
    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    dispatch(addAppliedId(item.id));
    if (worksearch_status.worksearch_enabled) {
      apiRequestV1('post', '/worksearch', {
        data: {
          activity: {
            company_name: item.org,
            contact_phone_number: '0000000000',
            contact_email: 'dlir.workforce.develop@hawaii.gov',
            position_applied_for: item.title,
            result_of_contact: 'Applied for Job through HI CAN',
            method_of_contact: ['internet'],
            application_filed: 'yes',
            activity_type: 'applied_for_job',
            activity_date: `${year}-${month}-${day}`,
            autopopulated: true,
          },
        },
      })
        .then(({ activity, status }) => {
          dispatch(setActivities(worksearch_activities.concat(activity)));
          dispatch(setStatus(status));
        })
        .catch((err) => {
          dispatch(removeAppliedId(item.id));
          console.log(err);
        });
    }
    trackEvent('JOBS_GLOBAL_APPLY', item);
    if (activeSoc !== '' && item.soc === activeSoc) {
      trackEvent('JOBS_APPLY_FILTEREDBYCAREER', activeSoc);
    } else {
      trackEvent('JOBS_APPLY', item);
    }
  };

  const handleLikeClick = (job) => () => {
    const newRating = job.rating === 1 ? 0 : 1;
    setRated(true);
    rateJob.mutate(
      { job, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                <JobNotificationContents rate="like" intl={intl} history={history} />
              </CustomNotification>
            ),
          });
        },
      }
    );

    trackEvent('JOBS_GLOBAL_THUMBSUP', job);
    if (activeSoc !== '' && job.soc === activeSoc) {
      trackEvent('JOBS_THUMBSUP_FILTEREDBYCAREER', activeSoc);
    } else {
      trackEvent('JOBS_THUMBSUP', job);
    }
  };

  const handleDislikeClick = (job) => () => {
    const newRating = job.rating === -1 ? 0 : -1;
    setRated(true);

    rateJob.mutate(
      { job, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                <JobNotificationContents rate="dislike" intl={intl} history={history} />
              </CustomNotification>
            ),
          });
        },
      }
    );

    trackEvent('JOBS_GLOBAL_THUMBSDOWN', job);
    if (activeSoc !== '' && job.soc === activeSoc) {
      trackEvent('JOBS_THUMBSDOWN_FILTEREDBYCAREER', activeSoc);
    } else {
      trackEvent('JOBS_THUMBSDOWN', job);
    }
  };

  const smallScreen = useMediaQuery('(max-width:599px)');

  const removeCareerFilter = (career) => {
    dispatch(setTagsActive(''));
    const newActiveCareers = careerPathsFilterValues.filter((f) => f.soc !== career.soc);
    dispatch(setCareerPathsFilterValues(newActiveCareers));
  };

  const removeLocationFilter = (loc) => (e) => {
    e.preventDefault();
    const newALocationsFilter = locationsFilterValues.filter((l) => l !== loc);
    dispatch(setLocationsFilterValues(newALocationsFilter));
  };
  const removeSalaryFilter = (idx) => (e) => {
    e.preventDefault();
    const newSalaryFilter = salaryFilterValues.slice(0);
    newSalaryFilter[idx] = 0;
    dispatch(setSalaryFilterValues(newSalaryFilter));
  };

  const clearAllFilters = () => {
    dispatch(setCareerPathsFilterValues([]));
    dispatch(setSalaryFilterValues([0, 0]));
    dispatch(setLocationsFilterValues([]));
    dispatch(setKeywordFilter(''));
    searchInputEl.current.value = '';
    dispatch(setDatePostedFilterValue('anyTime'));
    setResetCounter(resetCounter + 1);
    dispatch(setDistanceFilterValue(filterOptions.maxDistance));
    dispatch(setTagsActive(''));
  };

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOBS');
    TimeMe.startTimer();
    return () => {
      console.log('Leaving Jobs');
    };
  }, []);

  const handleChangeSalaryFilterValues = (event, newValue) => {
    dispatch(setSalaryFilterValues(newValue));
  };

  const handleChangeLocationsFilterValues = (event, newValue) => {
    dispatch(setLocationsFilterValues(newValue));
  };

  const handleChangeCareerPathsFilterValues = ({ target: { value: newValue } }) => {
    trackEvent('JOBS_FILTER_BYCAREER', { selectedCareers: newValue });
    dispatch(setTagsActive(''));
    dispatch(setCareerPathsFilterValues(newValue));
  };

  const handleChangeDistanceFilterValue = (event, newValue) => {
    dispatch(setDistanceFilterValue(newValue));
  };

  const handleChangeDatePostedFilterValue = (event, newValue) => {
    dispatch(setDatePostedFilterValue(newValue));
  };

  const noResults = careerPathsFilterValues.length === 0 && responses.length === 0 && isSuccess;

  const handleChangeZipcodeFilterValue = (value) => {
    dispatch(setZipcodeFilterValue(value));
  };
  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <div className={styles.titleFilters}>
            <Typography variant="h1" component="h2">
              {noResults ? intl.formatMessage({ id: 'jobs.noJobs' }) : intl.formatMessage({ id: 'jobs.title' })}
            </Typography>
            <div className={cn(styles.autocompleteContainer, styles.autocompleteModernContainer)}>
              <KeywordSearchInput
                keywordFilter={keywordFilter}
                setKeywordFilter={debouncedSetKeywordFilter}
                uncontrolled
                inputRef={searchInputEl}
              />
              <FiltersPopper setOpen={setFiltersModalOpen} open={filtersModalOpen && !smallScreen}>
                <JobFiltersV2
                  popper
                  handleClose={() => setFiltersModalOpen(false)}
                  handleReset={clearAllFilters}
                  //Focus
                  focusValue={tagsActive}
                  setFocusValue={(newValues) => {
                    dispatch(setCareerPathsFilterValues([]));
                    dispatch(setLocationsFilterValues([]));
                    dispatch(setTagsActive(newValues));
                  }}
                  // Salary
                  minSalary={filterOptions.minSalary}
                  maxSalary={filterOptions.maxSalary}
                  salaryFilterValues={salaryFilterValues}
                  handleChangeSalaryFilterValues={handleChangeSalaryFilterValues}
                  // Location
                  locations={filterOptions.locations}
                  locationsFilterValues={locationsFilterValues}
                  handleChangeLocationsFilterValues={handleChangeLocationsFilterValues}
                  // Career paths
                  careerPaths={filterOptions.careerPaths}
                  careerPathsFilterValues={careerPathsFilterValues}
                  handleChangeCareerPathsFilterValues={handleChangeCareerPathsFilterValues}
                  // Distance
                  minDistance={filterOptions.minDistance}
                  maxDistance={filterOptions.maxDistance}
                  distanceFilterValue={distanceFilterValue}
                  handleChangeDistanceFilterValue={handleChangeDistanceFilterValue}
                  zipcode={zipcodeFilterValue}
                  zipcodeFailure={failureReason?.message?.includes('zip code')}
                  handleChangeZipcodeFilterValue={handleChangeZipcodeFilterValue}
                  // Date posted
                  datePostedFilterValue={datePostedFilterValue}
                  handleChangeDatePostedFilterValue={handleChangeDatePostedFilterValue}
                />
              </FiltersPopper>
            </div>
          </div>
          <>
            <div className={filterStyles.tags}>
              {!loadingLanguage && filtersActive && (
                <>
                  <hr className={layoutStyles.separator} style={{ marginBottom: '10px' }} />
                  <Typography variant="h5" style={{ paddingRight: '8px' }}>
                    {intl.formatMessage({ id: 'recommendations.filterBy' })}
                  </Typography>
                  {tagsActive !== '' && (
                    <Tag
                      text={intl.formatMessage({ id: `jobs.filters.${ACTIVE_TAGS_DICT[tagsActive]}` })}
                      onClick={() => dispatch(setTagsActive(''))}
                      type="filter"
                    />
                  )}
                  {careerPathsFilterValues.map((f) =>
                    f ? <Tag key={f.soc} text={f.title} onClick={() => removeCareerFilter(f)} type="filter" /> : null
                  )}
                  {keywordFilter.length > 0 && (
                    <Tag
                      text={keywordFilter}
                      onClick={() => {
                        dispatch(setKeywordFilter(''));
                        searchInputEl.current.value = '';
                      }}
                      type="filter"
                    />
                  )}
                  {locationsFilterValues.map((l) => (
                    <Tag key={l} text={l} onClick={removeLocationFilter(l)} type="filter" />
                  ))}
                  {salaryFilterValues[0] !== 0 && (
                    <Tag
                      text={`${intl.formatMessage({ id: 'jobs.filters.from' })} $${intl.formatNumber(
                        salaryFilterValues[0]
                      )}`}
                      onClick={removeSalaryFilter(0)}
                      type="filter"
                    />
                  )}
                  {salaryFilterValues[1] !== 0 && (
                    <Tag
                      text={`${intl.formatMessage({ id: 'jobs.filters.to' })} $${intl.formatNumber(
                        salaryFilterValues[1]
                      )}`}
                      onClick={removeSalaryFilter(1)}
                      type="filter"
                    />
                  )}
                  {!filtersQuery.isLoading &&
                    zipcodeFilterValue?.length === 5 &&
                    distanceFilterValue !== filterOptions.maxDistance && (
                      <Tag
                        text={`${intl.formatMessage({
                          id: 'jobs.filters.distanceTag',
                        })} <= ${distanceFilterValue} mi ${intl.formatMessage({
                          id: 'jobs.filters.from',
                        })} ${zipcodeFilterValue}`}
                        onClick={() => dispatch(setDistanceFilterValue(filterOptions.maxDistance))}
                        type="filter"
                      />
                    )}
                  {datePostedFilterValue !== 'anyTime' && (
                    <Tag
                      text={intl.formatMessage({ id: `jobs.filters.datePosted.${datePostedFilterValue}` })}
                      onClick={() => {
                        clearAllFilters();
                        setFiltersModalOpen(false);
                      }}
                      type="filter"
                    />
                  )}
                  <Box display="flex">
                    <span className={cn(layoutStyles.link, filterStyles.clearAll)} onClick={clearAllFilters}>
                      {intl.formatMessage({ id: 'recommendations.clearAllFilters', description: 'string' })}
                    </span>
                  </Box>
                </>
              )}
            </div>
            <Box m={1} />
          </>
          {(isFetching && !isFetched) || loadingLanguage ? (
            <>
              <hr className={layoutStyles.separator} />
              <Box m={3} />
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            </>
          ) : null}
          {!loadingLanguage && isFetched && responses && responses.length === 0 ? (
            <>
              <hr className={layoutStyles.separator} />
              <Box m={3} />
              <Typography variant="body2">{intl.formatMessage({ id: 'jobs.filters.noResults' })}</Typography>
            </>
          ) : null}
          {!loadingLanguage && isFetched && responses && responses.length > 0 ? (
            <>
              <hr className={layoutStyles.separator} />
              <Box m={3} />
              {responses.map((item) => (
                <RecommendationsCard
                  key={item.guid}
                  type="job"
                  item={item}
                  link={item.apply}
                  rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                  applied={appliedLookup[item.id]}
                  onButtonClick={() => handleApplyClick(item)}
                  onLikeClick={handleLikeClick(item)}
                  onDislikeClick={handleDislikeClick(item)}
                  rangeLabel={intl.formatMessage({ id: 'careers.salaryRange', description: 'string' })}
                  hideRateTooltip={allRatings.length > 0 || rated}
                />
              ))}
            </>
          ) : null}
          {!loadingLanguage && isFetched && more.length > 0 && (
            <>
              <hr className={layoutStyles.separator} />
              <Box m={4} />
              {more.map((item) => (
                <RecommendationsCard
                  key={item.guid}
                  type="job"
                  item={item}
                  link={item.apply}
                  rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                  applied={appliedLookup[item.id]}
                  onButtonClick={() => handleApplyClick(item)}
                  onLikeClick={handleLikeClick(item)}
                  onDislikeClick={handleDislikeClick(item)}
                  rangeLabel={intl.formatMessage({ id: 'careers.salaryRange', description: 'string' })}
                  hideRateTooltip={allRatings.length > 0 || rated}
                />
              ))}
            </>
          )}
          <div className={styles.bottomBar}>
            <span className={styles.backToTopPlaceholder} />
            {!loadingLanguage && isFetched && hasNextPage ? (
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                onClick={showMore}
                disabled={isFetching}
              >
                {intl.formatMessage({ id: 'jobs.showMore', description: 'string' })}
              </Button>
            ) : (
              <span />
            )}
            <ScrollToTopButton toTopEvent="JOBS_TOP" />
          </div>
        </Container>
      </div>
      <Dialog
        maxWidth="xs"
        open={filtersModalOpen && smallScreen}
        onClose={() => setFiltersModalOpen(false)}
        fullWidth
        aria-labelledby="max-width-dialog-title"
      >
        <JobFiltersV2
          handleClose={() => setFiltersModalOpen(false)}
          handleReset={clearAllFilters}
          //Focus
          focusValue={tagsActive}
          setFocusValue={(newValues) => {
            dispatch(setCareerPathsFilterValues([]));
            dispatch(setLocationsFilterValues([]));
            dispatch(setTagsActive(newValues));
          }}
          // Salary
          minSalary={filterOptions.minSalary}
          maxSalary={filterOptions.maxSalary}
          salaryFilterValues={salaryFilterValues}
          handleChangeSalaryFilterValues={handleChangeSalaryFilterValues}
          // Location
          locations={filterOptions.locations}
          locationsFilterValues={locationsFilterValues}
          handleChangeLocationsFilterValues={handleChangeLocationsFilterValues}
          // Career paths
          careerPaths={filterOptions.careerPaths}
          careerPathsFilterValues={careerPathsFilterValues}
          handleChangeCareerPathsFilterValues={handleChangeCareerPathsFilterValues}
          // Distance
          minDistance={filterOptions.minDistance}
          maxDistance={filterOptions.maxDistance}
          distanceFilterValue={distanceFilterValue}
          handleChangeDistanceFilterValue={handleChangeDistanceFilterValue}
          zipcode={zipcodeFilterValue}
          zipcodeFailure={failureReason?.message?.includes('zip code')}
          handleChangeZipcodeFilterValue={handleChangeZipcodeFilterValue}
          // Date posted
          datePostedFilterValue={datePostedFilterValue}
          handleChangeDatePostedFilterValue={handleChangeDatePostedFilterValue}
        />
      </Dialog>
    </>
  );
};

Jobs.propTypes = {
  width: PropTypes.string,
};

export default Jobs;
