/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { FormattedMessage } from 'react-intl';

/* Material UI and other UI Dependencies */
import Typography from '@mui/material/Typography';
import CardPlaceholder from 'components/CardPlaceholder';

export default function LoadingRecommendations() {
  return (
    <div className={styles.loginFlow}>
      <div className={styles.loginFlow__formContainer}>
        <div className={styles.loginFlow__form}>
          <Typography align="center" variant="h1" gutterBottom>
            <FormattedMessage id="app.loadingRecommendations" />
          </Typography>
        </div>
        <hr className={layoutStyles.separatorBold} />
        <div className={styles.loadingScreen__cardsContainer}>
          <CardPlaceholder />
          <CardPlaceholder />
        </div>
      </div>
    </div>
  );
}
