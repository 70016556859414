import { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

/* Styles */
import styles from 'styles/edit-job-activity.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import filterStyles from 'styles/filter-input.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Assets */
import arrowDown from 'assets/images/Arrow_Dropdown.svg';
import Close from 'assets/images/Close';

/* Redux */
import { useSelector } from 'react-redux';

/* Material UI and other UI Dependencies */
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';

/* Forms */
import ApplyJobForm from 'components/forms/ApplyJobForm';
import AttendJobSeminar from 'components/forms/AttendJobSeminar';
import ScheduleMeeting from 'components/forms/ScheduleMeeting';
import InterviewWithEmployer from 'components/forms/InterviewWithEmployer';
import FollowUpActivity from 'components/forms/FollowUpActivity';
import RegisterSchoolFacility from 'components/forms/RegisterSchoolFacility';

export default function EditJobActivity({
  open,
  setOpen,
  addActivityItem,
  updateActivityItem,
  setGeneralActivity,
  selectedActivity = false,
}) {
  const [activity, setActivity] = useState('');
  const [activityToEdit, setActivityToEdit] = useState(selectedActivity);
  const [editOpen, setEditOpen] = useState(false);
  const [editting, setEditting] = useState(false);
  const user_settings = useSelector((state) => state.app.user_settings);

  const intl = useIntl();
  const validActivities = [
    'applied_for_job',
    'attend_job_seminar',
    'job_coach_meeting',
    'interview_with_employer',
    'follow_up_with_employer',
    'register_with_school',
  ];
  useEffect(() => {
    if (selectedActivity && validActivities.includes(selectedActivity.activity_type)) {
      setActivity(selectedActivity.activity_type);
      setActivityToEdit(selectedActivity);
      setEditting(true);
    } else {
      setActivity(false);
      setActivityToEdit(false);
      setEditting(false);
    }
  }, [selectedActivity]);

  const editActivityOptions = {
    ...(user_settings.job_search_activity_form_option_apply && {
      applied_for_job: intl.formatMessage({ id: 'jobSearchActivity.applyForAJob' }),
    }),
    ...(user_settings.job_search_activity_form_option_attend_seminar && {
      attend_job_seminar: intl.formatMessage({ id: 'jobSearchActivity.attendJobSearch' }),
    }),
    ...(user_settings.job_search_activity_form_option_schedule_meeting && {
      job_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.scheduleMeeting' }),
    }),
    ...(user_settings.job_search_activity_form_option_interview && {
      interview_with_employer: intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' }),
    }),
    ...(user_settings.job_search_activity_form_option_make_contact && {
      follow_up_with_employer: intl.formatMessage({ id: 'jobSearchActivity.directContact' }),
    }),
    ...(user_settings.job_search_activity_form_option_register_school_facility && {
      register_with_school: intl.formatMessage({ id: 'jobSearchActivity.registerFacility' }),
    }),
  };

  const formsDict = {
    applied_for_job: ApplyJobForm,
    attend_job_seminar: AttendJobSeminar,
    job_coach_meeting: ScheduleMeeting,
    interview_with_employer: InterviewWithEmployer,
    follow_up_with_employer: FollowUpActivity,
    register_with_school: RegisterSchoolFacility,
  };
  const ActiveForm = activity ? formsDict[activity] : null;

  const handleActivityChange = ({ target: { value } }) => {
    setEditting(false);
    setActivityToEdit(false);
    setActivity(value);
  };

  const getSelectClass = (label) => {
    return cn(filterStyles.selectRoot, {
      [filterStyles.selectRoot__tall]: label.length > 65,
      [filterStyles.selectRoot__MobileTall]: label.length > 35,
    });
  };
  const getSelectSelectClass = (label) => {
    return cn(filterStyles.selectSelect, {
      [filterStyles.selectSelect__tall]: label.length > 65,
      [filterStyles.selectSelect__MobileTall]: label.length > 35,
    });
  };
  const getSelectLabel = () => {
    if (activity === false) {
      return intl.formatMessage({ id: 'jobSearchActivity.chooseActivity' });
    }
    return activity === ''
      ? intl.formatMessage({ id: 'jobSearchActivity.chooseActivity' })
      : editActivityOptions[activity];
  };

  return (
    <>
      <div
        className={cn({
          [styles.editJobContact]: true,
          [styles.hidden]: !open,
          [styles.opaque]: open,
        })}
      >
        <Close className={styles.close} onClick={() => setOpen(false)} />
        <section className={styles.topSection}>
          <span className={styles.title}>{intl.formatMessage({ id: 'jobSearchActivity.editJobContactActivity' })}</span>
          <Typography variant="caption" className={styles.label}>
            {intl.formatMessage({ id: 'jobSearchActivity.chooseContactMethod' })}
          </Typography>
          <FormControl
            className={cn({
              [styles.formControl]: true,
              [filterStyles.ratingsSortingFocused]: editOpen,
            })}
          >
            <span className={filterStyles.selectLabel}>{getSelectLabel()}</span>
            <Select
              disableUnderline
              value={activity}
              onChange={handleActivityChange}
              onOpen={() => {
                trackEvent('WEEK_ADDACTIVITY_DROPDOWN');
                setEditOpen(true);
              }}
              onClose={() => setEditOpen(false)}
              input={<Input />}
              renderValue={() => ''}
              IconComponent={(_props) => <img alt="Arrow down" className={filterStyles.arrowDown} src={arrowDown} />}
              MenuProps={{
                PaperProps: {
                  className: filterStyles.selectMenu,
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              classes={{
                root: getSelectClass(getSelectLabel()),
                select: getSelectSelectClass(getSelectLabel()),
              }}
            >
              {Object.keys(editActivityOptions).map((option) => (
                <MenuItem key={option} value={option}>
                  <span className={filterStyles.checkboxLabel}>{editActivityOptions[option]}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </section>
        {open && activity !== '' ? <hr className={layoutStyles.separator} /> : null}
      </div>
      {open && activity !== '' ? (
        <section className={styles.formSection}>
          {activity ? (
            <ActiveForm
              addActivityItem={addActivityItem}
              updateActivityItem={updateActivityItem}
              setGeneralActivity={setGeneralActivity}
              setOpen={setOpen}
              selectedActivity={activityToEdit}
              editting={editting}
            />
          ) : null}
        </section>
      ) : null}
    </>
  );
}

EditJobActivity.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  addActivityItem: PropTypes.func,
  updateActivityItem: PropTypes.func,
  setGeneralActivity: PropTypes.func,
  selectedActivity: PropTypes.bool,
};
