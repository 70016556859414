import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import MetaContent from 'components/layout/MetaContent';

/* i18n */
import { useIntl, FormattedMessage } from 'react-intl';

/* Redux */
import { useDispatch } from 'react-redux';
import { setLoading } from 'app/slices/appSlice';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Material UI and other UI Dependencies */
import { Button, Box, Typography, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import OnboardingHeader from 'components/layout/OnboardingHeader';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Images */
import MSImg from 'assets/images/microsoft.svg';
import GoImg from 'assets/images/Google.svg';

const useStyles = makeStyles({
  rootAbsolute: {
    width: 290,
    position: 'relative',
    '& .MuiButton-startIcon': {
      position: 'absolute',
      left: 12,
      top: -1,
      bottom: 0,
      margin: 'auto 0',
    },
  },
});

const getSvgIconMaterial = (Icon) => {
  return (
    <SvgIcon component="object">
      <embed
        type="image/svg+xml"
        src={Icon}
        style={{ height: 16, opacity: 0.7, position: 'absolute', top: '32%', left: '8%' }}
      />
    </SvgIcon>
  );
};

function LoginAuth0() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { loginWithRedirect } = useAuth0();
  const classes = useStyles();

  const handleLogin = (provider = '') => {
    return () => {
      dispatch(setLoading(true));
      trackEvent(`LOGIN_${provider.toUpperCase()}`);
      // see https://community.auth0.com/t/request-for-federated-logout-support-for-the-auth0-oidc-connection-type/72574
      // prompt: login was needed as a workaround to logoff with OIDC
      // connection goes direct to social login: https://community.auth0.com/t/how-to-skip-aut0-login-page-and-directly-redirect-in-to-social-login-site/28211
      if (provider) {
        loginWithRedirect({ prompt: 'login', connection: provider.toLowerCase(), ui_locales: intl.locale });
      } else {
        loginWithRedirect({ prompt: 'login', ui_locales: intl.locale });
      }
    };
  };

  const handleSignUp = () => {
    dispatch(setLoading(true));
    trackEvent(`SIGNUP_AUTH0`);
    loginWithRedirect({ screen_hint: 'signup', ui_locales: intl.locale });
  };

  return (
    <>
      <MetaContent intlDescription={intl.formatMessage({ id: 'recommendations.title' })} />
      <OnboardingHeader />
      <article className={styles.loginFlow}>
        <div className={styles.loginFlow__formContainer}>
          <header className={styles.loginFlow__form}>
            <Typography align="center" variant="h1" gutterBottom>
              <FormattedMessage default="Sign in to your account" id="recommendations.title" />
            </Typography>
            <Typography align="center" variant="body1" gutterBottom>
              {intl.formatMessage(
                { id: 'forms.signIn.subtitle' },
                { toolName: 'CO Career Trail Guide', state: 'Colorado' }
              )}
            </Typography>
            <Typography align="center" variant="body1" gutterBottom>
              {intl.formatMessage({ id: 'forms.signIn.subtitleLine2' })}
            </Typography>
          </header>
          <hr className={layoutStyles.separatorBold} />
          <div className={styles.signInContainerSingleColumn}>
            <div className={styles.socialSignInContainer}>
              <Typography variant="h3" component="h2" align="center">
                {intl.formatMessage({ id: 'forms.signIn.prompt' })}
              </Typography>
              <Box my={2}>
                <Button
                  className={classes.rootAbsolute}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleLogin('')}
                >
                  {intl.formatMessage({ id: 'forms.signIn.continueCO' })}
                </Button>
              </Box>
              <Box my={2}>
                <Button
                  className={classes.rootAbsolute}
                  startIcon={getSvgIconMaterial(GoImg)}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleLogin('google-oauth2')}
                >
                  {intl.formatMessage({ id: 'forms.signIn.continueGoogle' })}
                </Button>
              </Box>
              <Box my={2}>
                <Button
                  className={classes.rootAbsolute}
                  startIcon={getSvgIconMaterial(MSImg)}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleLogin('windowslive')}
                >
                  {intl.formatMessage({ id: 'forms.signIn.continueMicrosoft' })}
                </Button>
              </Box>
              <div>
                <label className={styles.infoMessageDark}>
                  {intl.formatMessage({ id: 'forms.signIn.needAccount' })}{' '}
                  <a className={styles.fakeLink} onClick={handleSignUp}>
                    {intl.formatMessage({ id: 'forms.signIn.clickHere' })}
                  </a>
                </label>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default LoginAuth0;
