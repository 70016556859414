import React from 'react';

/* Styles */
import recommedationStyles from 'styles/recommendations-list.module.scss';
import loginStyles from 'styles/login.module.scss';

/* i18n */
import { FormattedMessage } from 'react-intl';

/* Material UI and other UI Dependencies */
import { Divider, Container, Typography, Box } from '@mui/material';
import MetaContent from 'components/layout/MetaContent';
import CloseTabButton from 'components/CloseTabButton';

function About() {
  return (
    <>
      <MetaContent />
      <div className={loginStyles.loginFlow__formContainer__innerShadow} />
      <div className={recommedationStyles.content}>
        <Container component="article" maxWidth="lg">
          <header>
            <CloseTabButton />
            <Typography variant="h1" component="h1" gutterBottom>
              <FormattedMessage id="about.title" />
            </Typography>
          </header>
          <Box my={2}>
            <Divider />
          </Box>
          <Box p={2}>
            <Typography variant="body2" component="p" gutterBottom>
              <FormattedMessage id="about.description" />
            </Typography>
          </Box>
        </Container>
      </div>
    </>
  );
}

export default About;
