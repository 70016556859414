import { createSlice } from '@reduxjs/toolkit';
import { getLastExperience, getDateFromString } from 'shared/utils';

export const experienceSlice = createSlice({
  name: 'experience',
  initialState: {
    skills: [],
    industry: {
      naics2_code: '',
    },
    form: {
      soc_code: '',
      soc_title: '',
      soc_query: '',
      naics_code: '',
      naics_title: '',
      naics_query: '',
      years_experience: '',
      education_level: '',
      zipcode: '',
      banner_text: {
        recentJob: {
          title: '',
          start_date: '',
          month: -1,
          year: 0,
        },
        educationLevel: '',
      },
    },
    has_resume: false,
    loading: true,
    reload_pending: false,
    open_contact_info: false,
  },
  reducers: {
    setExperienceSkills: (state, action) => {
      state.skills = action.payload;
    },
    setExperienceIndustry: (state, action) => {
      state.industry = action.payload;
    },
    setZipcode: (state, action) => {
      state.form = { ...state.form, zipcode: action.payload };
    },
    setExperienceForm: (state, action) => {
      state.form = { ...state.form, ...action.payload };

      if ('education' in action.payload) {
        const educationDescriptions = {
          11: 'forms.onboarding.education.noDiploma',
          12: 'forms.onboarding.education.diploma',
          13: 'forms.onboarding.education.someCollege',
          14: 'forms.onboarding.education.assocDegree',
          16: 'forms.onboarding.education.bachDegree',
          17: 'forms.onboarding.education.mastersDegree',
          20: 'forms.onboarding.education.advancedDegree',
        };

        try {
          const degrees = action.payload.education.reduce(
            (acc, item) => (item.degree ? [...acc, item.degree] : acc),
            []
          );
          const maxDeg = Math.max(...degrees);
          state.form.banner_text.educationLevel = educationDescriptions[maxDeg];
        } catch (e) {
          console.error(e);
        }
      }

      if ('job_experience' in action.payload) {
        try {
          const lastExperience = getLastExperience(action.payload.job_experience);
          const dobj = new getDateFromString(lastExperience.start_date);
          state.form.banner_text.recentJob.title = lastExperience.title;
          state.form.banner_text.recentJob.start_date =
            dobj.toLocaleString(action.payload.language || 'en', { month: 'long' }) + ' ' + dobj.getFullYear();
          state.form.banner_text.recentJob.year = dobj.getFullYear();
          state.form.banner_text.recentJob.month = dobj.getMonth();
        } catch (e) {
          console.error(e);
        }
      }
    },
    setExperienceHasResume: (state, action) => {
      state.has_resume = action.payload;
    },
    setSkillsExperienceLoading: (state, action) => {
      state.loading = action.payload;
    },
    setReloadPending: (state, action) => {
      state.reload_pending = action.payload;
    },
    setOpenContactInfo: (state, action) => {
      state.open_contact_info = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setExperienceSkills,
  setExperienceIndustry,
  setExperienceForm,
  setZipcode,
  setExperienceHasResume,
  setSkillsExperienceLoading,
  setReloadPending,
  setOpenContactInfo,
} = experienceSlice.actions;

export default experienceSlice.reducer;
