import { css } from '@emotion/css';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pathOr, propOr } from 'ramda';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { useQuery } from '@tanstack/react-query';
import { updateExperience } from 'shared/src/lib/API/experience';

/* Styles */
import recStyles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import cssVars from 'styles/vars.module.scss';
import { darken } from 'polished';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Box, Container, Typography, Chip, IconButton, Collapse, useMediaQuery } from '@mui/material';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setReloadPending, setExperienceForm, setOpenContactInfo } from 'app/slices/experienceSlice';

/* UI Components */
import { useSnackbar } from 'notistack';
import { LoadingSmall } from 'components/Loading';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  EducationForm,
  ContactForm,
  ExperienceSavedNotificationContents,
  CustomNotification,
  UploadForm,
  initialExperienceFormValues,
  getExperienceInitialValues,
  OnboardingExperienceForm,
  ExperienceStepper,
  SkillsAutocompleteV2,
  LeavingPageDialog,
} from 'shared/components';

/* Utils */
import { getTodayMmDdYyyy } from 'shared/utils';
import { useRateUserSkill, useUserSkills } from 'shared/hooks';
import validZipCodes from 'lib/validZipCodes.json';

const emptyEducation = { degree: '', field: '', school_name: '', empty: true };

const styles = {
  resumeEducation: css(
    {
      display: 'grid',
      gap: '30px',
      gridTemplateColumns: '1fr',
      paddingTop: '16px',
      '@media screen and (min-width: 768px)': {
        gridTemplateColumns: '1fr 1fr',
      },
    },
    { label: 'resume-education' }
  ),
  titlePadding: css({
    paddingBottom: '16px',
  }),
  chipRoot: css(
    {
      borderRadius: '0px !important',
      backgroundColor: `${cssVars.tagSkill} !important`,
      marginRight: '5px !important',
      marginBottom: '5px !important',
      '&:hover': {
        backgroundColor: `${darken(0.04, cssVars.tagSkill)} !important`,
      },
      height: '22px !important',
      '& .MuiChip-deleteIcon': {
        width: 14,
        height: 14,
      },
      '& .MuiChip-label': {
        fontWeight: 700,
        fontSize: '10px',
        color: cssVars.darkGray,
        textTransform: 'uppercase',
      },
    },
    { label: 'chip-root' }
  ),
  focusTwoColumns: css({
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr',
    paddingTop: '31px',
    '@media screen and (min-width: 672px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  }),
  cardsContainer: css(
    {
      display: 'grid',
      gap: '16px',
      gridTemplateColumns: '1fr',
      paddingTop: '31px',
      '@media screen and (min-width: 672px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
    { label: 'cards-container' }
  ),
};

const Experience = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [openSkills, setOpenSkills] = useState(true);
  const [openExperience, setOpenExperience] = useState(true);
  const [choseToStay, setChoseToStay] = useState(false);
  const queryClient = useQueryClient();
  const { open_contact_info } = useSelector((state) => state.experience);

  const isSmall = useMediaQuery('(max-width:767px)');

  /*-- initial load --*/
  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/'],
    refetchOnMount: 'always',
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
  const contactQuery = useQuery({
    queryKey: ['/users/me'],
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  /*-- skills --*/
  const userSkillsQuery = useUserSkills();
  const likedSkills = userSkillsQuery?.data?.filter((skill) => skill.rating === 1) ?? [];
  const suggestedSkills = userSkillsQuery?.data?.filter((skill) => skill.rating === 0).slice(0, 10) ?? [];
  const rateUserSkill = useRateUserSkill();

  /*-- resume --*/
  const [uploadingResume, setUploadingResume] = useState(false);
  const [uploadedResume, setUploadedResume] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  /*-- education --*/
  const [submittingEducation, setSubmittingEducation] = useState(false);

  /*-- work experience --*/
  const [workHistory, setWorkHistory] = useState(initialExperienceFormValues);
  const [loadedWorkHistory, setLoadedWorkHistory] = useState(false);

  // These variables are used to warn the user when trying to leave the page
  // with unsaved changes.
  const [educationHasChanged, setEducationHasChanged] = useState(false);
  const [experienceHasChanged, setExperienceHasChanged] = useState(false);
  const [experienceLengthChanged, setExperienceLengthChanged] = useState(false);
  const [currentJobsChanged, setCurrentJobsChanged] = useState(false);
  const [contactHasChanged, setContactHasChanged] = useState(false);

  const handleAddSkill = (newSkill) => {
    rateUserSkill.mutate({ skill: newSkill, rating: 1 });
    trackEvent('EXPERIENCE_SKILLS_THUMBSUP', newSkill);
  };

  const handleRemoveSkill = (removedSkill) => {
    rateUserSkill.mutate({ skill: removedSkill, rating: -1 });
    trackEvent('EXPERIENCE_SKILLS_THUMBSDOWN', removedSkill);
  };

  const resumeUpdated = (value) => {
    if (value.uploading) {
      return setUploadingResume(true);
    }
    setUploadedResume(true);
    setLastUpdated(getTodayMmDdYyyy());

    setUploadingResume(false);
    //TODO: refetch experience and skills
    dispatch(setReloadPending(true));
  };

  /*-- manage education --*/
  const educationValidationSchema = yup.object({
    degree: yup
      .string(intl.formatMessage({ id: 'forms.onboarding.educationValidation' }))
      .required(intl.formatMessage({ id: 'forms.onboarding.educationRequired' })),
  });

  const educationInitialValues = {
    ...emptyEducation,
    language: intl.locale,
  };

  const educationFormik = useFormik({
    initialValues: educationInitialValues,
    validationSchema: educationValidationSchema,
    onSubmit: async ({ school_name, degree, field, ...rest }) => {
      setSubmittingEducation(true);
      const education = [{ school_name, degree, field, ...rest }];
      await updateExperience({ education });
      enqueueSnackbar('', {
        variant: 'default',
        content: (key) => (
          <CustomNotification id={key} check>
            <ExperienceSavedNotificationContents intl={intl} history={history} />
          </CustomNotification>
        ),
      });
      dispatch(setExperienceForm({ education }));
      setSubmittingEducation(false);
      dispatch(setReloadPending(true));
      trackEvent('EXPERIENCE_SAVE');
    },
  });
  useEffect(() => {
    if (!experienceQuery.isFetching && experienceQuery.isSuccess) {
      educationFormik.setValues({
        ...educationFormik.values,
        ...pathOr({}, ['data', 'education', 0], experienceQuery),
      });
      setWorkHistory((prevState) => ({
        ...prevState,
        ...getExperienceInitialValues(propOr({}, 'data', experienceQuery), 2),
        empty: false,
      }));
      if (pathOr(false, ['data', 'has_resume'], experienceQuery)) {
        setUploadedResume(true);
      }
      if (pathOr(false, ['data', 'last_updated_resume'], experienceQuery)) {
        const lastUpdated = pathOr('', ['data', 'last_updated_resume'], experienceQuery);
        setLastUpdated(`${lastUpdated.slice(4, 6)}-${lastUpdated.slice(6, 8)}-${lastUpdated.slice(0, 4)}`);
      }
      setLoadedWorkHistory(true);
    }
  }, [experienceQuery.isSuccess, experienceQuery.isFetching]);
  useEffect(() => {
    if (!uploadingResume) {
      educationFormik.setValues(educationInitialValues);
      setLoadedWorkHistory(false);
      queryClient.resetQueries();
    }
  }, [uploadingResume]);

  /*-- analytics --*/
  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('SKILLS');
    TimeMe.startTimer();
    return () => {
      dispatch(setOpenContactInfo(false));
    };
  }, []);

  useEffect(() => {
    if (
      open_contact_info &&
      !experienceQuery.isLoading &&
      !experienceQuery.isFetching &&
      loadedWorkHistory &&
      !contactQuery.isLoading &&
      !contactQuery.isFetching
    ) {
      const section = document.querySelector('#contact-section');
      if (section && section.scrollIntoView) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [
    experienceQuery.isLoading,
    experienceQuery.isFetching,
    loadedWorkHistory,
    contactQuery.isLoading,
    contactQuery.isFetching,
  ]);

  return (
    <>
      <LeavingPageDialog
        when={
          educationHasChanged ||
          experienceHasChanged ||
          experienceLengthChanged ||
          currentJobsChanged ||
          contactHasChanged
        }
        onOK={() => true}
        onCancel={() => false}
        setChoseToStay={setChoseToStay}
      />
      <div className={recStyles.content}>
        <Container maxWidth="lg">
          <div className={recStyles.titleFilters}>
            <div>
              <Typography variant="h1">{intl.formatMessage({ id: 'experience.updateExperience' })}</Typography>
              <Typography variant="body2">{intl.formatMessage({ id: 'experience.subheader' })}</Typography>
            </div>

            <div className={recStyles.stepperContainer}>
              <ExperienceStepper didUploadResume={uploadedResume} defaultLikedSkills={likedSkills} />
            </div>
          </div>
          <hr className={layoutStyles.separator} />
          {experienceQuery.isLoading ||
          experienceQuery.isFetching ||
          !loadedWorkHistory ||
          contactQuery.isLoading ||
          contactQuery.isFetching ? (
            <LoadingSmall />
          ) : (
            <div>
              <Box pt={2}>
                <Typography variant="h4" component="h2">
                  {intl.formatMessage({ id: 'experience.workEducationSkills' })}
                </Typography>
              </Box>
              <div className={styles.resumeEducation}>
                <Box className={recStyles.onboardingExperience__cardsContainer}>
                  <Typography align="left" variant="h5" className={styles.titlePadding} id="resume-section">
                    {intl.formatMessage({ id: 'experience.addUpdateResume' })}
                  </Typography>
                  <UploadForm redirect={false} cb={resumeUpdated} lastUpdated={lastUpdated} />
                </Box>
                <div>
                  <Typography align="left" variant="h5" className={styles.titlePadding} id="education-section">
                    {intl.formatMessage({ id: 'forms.onboarding.education.edit' })}
                  </Typography>
                  <EducationForm
                    formik={educationFormik}
                    valueChanged={(hasItChanged) => setEducationHasChanged(hasItChanged)}
                    choseToStay={choseToStay}
                    classNames={recStyles.educationForm}
                    submitting={submittingEducation}
                    submitLabel={intl.formatMessage({ id: 'jobSearchActivity.form.save' })}
                  />
                </div>
              </div>
              <Box m={3} />
              <Typography variant="h5" component="h2">
                {intl.formatMessage({ id: 'forms.onboarding.education.yourSkillsSection' })}
                <IconButton
                  onClick={() => setOpenSkills(!openSkills)}
                  color="primary"
                  style={{ padding: 8 }}
                  title={intl.formatMessage({ id: 'accessibility.expandButton' })}
                  size="large"
                >
                  {openSkills ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                </IconButton>
              </Typography>
              <Collapse in={openSkills}>
                <Typography variant="body1">{intl.formatMessage({ id: 'experience.yourSkillsText' })}</Typography>
                <Box mt={1} style={{ minWidth: isSmall ? null : 300, maxWidth: likedSkills.length * 160 }}>
                  <SkillsAutocompleteV2
                    activeSkills={likedSkills}
                    addSkillHandler={handleAddSkill}
                    removeSkillHandler={handleRemoveSkill}
                  />
                </Box>
                {suggestedSkills.length > 0 || userSkillsQuery.isLoading ? (
                  <div>
                    <Box m={3} />
                    <Typography variant="h5" component="h2" gutterBottom>
                      {intl.formatMessage({ id: 'forms.onboarding.education.recommendedSkillsSection' })}
                    </Typography>
                    <Box m={1} />
                    <Typography variant="body1" gutterBottom>
                      {intl.formatMessage({ id: 'experience.addRecommendedSkillsText' })}
                    </Typography>
                    <div>
                      {userSkillsQuery.isLoading ? (
                        <LoadingSmall />
                      ) : (
                        suggestedSkills.map((item) => (
                          <Chip
                            key={item.id}
                            label={propOr('', 'name', item)}
                            onClick={() => handleAddSkill(item)}
                            onDelete={() => handleRemoveSkill(item)}
                            classes={{ root: styles.chipRoot }}
                          />
                        ))
                      )}
                    </div>
                  </div>
                ) : null}
              </Collapse>
              {uploadingResume ? null : (
                <div>
                  <Box m={4} />
                  <Typography variant="h5" component="h2">
                    {intl.formatMessage({ id: 'onboarding.experience.enterOrUpdateJobExperience' })}{' '}
                    <IconButton
                      onClick={() => setOpenExperience(!openExperience)}
                      color="primary"
                      style={{ padding: 8 }}
                      title={intl.formatMessage({ id: 'accessibility.expandButton' })}
                      size="large"
                    >
                      {openExperience ? (
                        <KeyboardArrowUpIcon fontSize="large" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="large" />
                      )}
                    </IconButton>
                  </Typography>
                  <Collapse in={openExperience}>
                    <OnboardingExperienceForm
                      valueChanged={(hasItChanged) => setExperienceHasChanged(hasItChanged)}
                      lengthChanged={(hasItChanged) => setExperienceLengthChanged(hasItChanged)}
                      currentJobsChanged={(hasItChanged) => setCurrentJobsChanged(hasItChanged)}
                      choseToStay={choseToStay}
                      workHistory={workHistory}
                      submitLabel={intl.formatMessage({ id: 'jobSearchActivity.form.save' })}
                      redirect={false}
                      submitCallback={() => {
                        enqueueSnackbar('', {
                          variant: 'default',
                          content: (key) => (
                            <CustomNotification id={key} check>
                              <ExperienceSavedNotificationContents intl={intl} history={history} />
                            </CustomNotification>
                          ),
                        });
                        dispatch(setReloadPending(true));
                        setLoadedWorkHistory(false);
                        queryClient.resetQueries();
                        trackEvent('EXPERIENCE_SAVE');
                      }}
                    />
                  </Collapse>
                </div>
              )}
              <Box m={3} />
              <Typography variant="h5" component="h2" id="contact-section">
                {intl.formatMessage({ id: 'experience.updateContactInformation' })}
                <IconButton
                  onClick={() => dispatch(setOpenContactInfo(!open_contact_info))}
                  color="primary"
                  style={{ padding: 8 }}
                  title={intl.formatMessage({ id: 'accessibility.expandButton' })}
                  size="large"
                >
                  {open_contact_info ? (
                    <KeyboardArrowUpIcon fontSize="large" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="large" />
                  )}
                </IconButton>
              </Typography>
              <Collapse in={open_contact_info}>
                <Box mt={1} style={{ minWidth: 300 }}>
                  <ContactForm
                    initialValues={contactQuery.data}
                    valueChanged={(hasItChanged) => setContactHasChanged(hasItChanged)}
                    choseToStay={choseToStay}
                    classNames={recStyles.educationForm}
                    submitting={submittingEducation}
                    submitLabel={intl.formatMessage({ id: 'jobSearchActivity.form.save' })}
                    validZipCodes={validZipCodes}
                    state="Colorado"
                    submitCallback={() => {
                      enqueueSnackbar('', {
                        variant: 'default',
                        content: (key) => (
                          <CustomNotification id={key} check>
                            {intl.formatMessage({ id: 'experience.changesSaved' })}
                          </CustomNotification>
                        ),
                      });
                    }}
                  />
                </Box>
              </Collapse>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Experience;
