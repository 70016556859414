import cn from 'classnames';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import financialStyles from 'styles/financial-help-card.module.scss';

/* Redux */
import { useSelector } from 'react-redux';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import Container from '@mui/material/Container';
import { Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import FinancialHelpCard from 'components/FinancialHelpCard';
import Button from '@mui/material/Button';
import ScrollToTopButton from 'components/ScrollToTopButton';

/* Assets */
import foodIcon from 'assets/images/icon_food_help.svg';
import cashIcon from 'assets/images/icon_cash_help.svg';
import medicalIcon from 'assets/images/icon_medical_help.svg';
import SNAP from 'assets/images/SNAP.png';
import TANF from 'assets/images/TANF.png';
import MEDQUEST from 'assets/images/MEDQUEST.png';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const FinancialHelp = () => {
  let history = useHistory();

  const user_settings = useSelector((state) => state.app.user_settings);
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  const isSmall = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('FINANCIAL_HELP');
    TimeMe.startTimer();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    return () => {
      console.log('Leaving Financial Help');
    };
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <div className={styles.header__content}>
            <Typography variant="h1" component="h2" gutterBottom>
              {intl.formatMessage({ id: 'financialHelp.title' })}
            </Typography>
            <hr className={layoutStyles.separator} />
            <Box m={4} />
            <Typography variant="h3" gutterBottom>
              {intl.formatMessage({ id: 'financialHelp.subtitle' })}
            </Typography>
            <Box m={3} />
            {user_settings.financial_benefits_groceries ? (
              <>
                <FinancialHelpCard
                  title={intl.formatMessage({ id: 'financialHelp.groceries.title' })}
                  description={intl.formatMessage({ id: 'financialHelp.groceries.description' })}
                  toQualifyTitle={intl.formatMessage({ id: 'financialHelp.groceries.toQualify' })}
                  toQualifyItems={[
                    intl.formatMessage({ id: 'financialHelp.groceries.qualification1' }),
                    'Next qualification 1',
                    'Next qualification 2',
                  ]}
                  image={foodIcon}
                  imageStyles={{ width: isSmall ? 45.87 : 69.45 }}
                  imageDescription={intl.formatMessage({ id: 'financialHelp.groceries.imageDescription' })}
                >
                  <img src={SNAP} style={{ width: 48 }} />
                  <Box mb={1} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.snap.whatIs' })}
                  </h3>
                  <Typography variant="body2" component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.snap.whatIsP' })}
                  </Typography>
                  <Box mb={4} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmitTitle' })}
                  </h3>
                  <ol className={financialStyles.noMargin}>
                    <li>
                      <Typography variant="body2">
                        {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit1' })}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit2' })}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2" gutterBottom>
                        {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmit3' })}
                      </Typography>
                    </li>
                  </ol>
                  <Box mb={4} />
                  <Typography variant="body2" style={{ fontStyle: 'italic' }} component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.snap.waysToSubmitNotes' })}
                  </Typography>
                  <Box mb={4} />
                  <div>
                    <Button
                      color="secondary"
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {}}
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                      style={{ marginRight: 10 }}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
                    </Button>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {}}
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
                    </Button>
                  </div>
                </FinancialHelpCard>
                <Box mb={5} />
              </>
            ) : null}
            {user_settings.financial_benefits_bills_and_expenses ? (
              <>
                <FinancialHelpCard
                  title={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.title' })}
                  description={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.description' })}
                  toQualifyTitle={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.toQualify' })}
                  toQualifyItems={[
                    intl.formatMessage({ id: 'financialHelp.billsAndExpenses.qualification1' }),
                    'Next qualification 1',
                    'Next qualification 2',
                  ]}
                  image={cashIcon}
                  imageStyles={{ width: isSmall ? 53.63 : 80.32 }}
                  imageDescription={intl.formatMessage({ id: 'financialHelp.billsAndExpenses.imageDescription' })}
                >
                  <img src={TANF} style={{ width: 115 }} />
                  <Box mb={1} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.tanf.whatIs' })}
                  </h3>
                  <Typography variant="body2" component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.tanf.whatIsP' })}
                  </Typography>
                  <Box mb={4} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmitTitle' })}
                  </h3>
                  <ol className={financialStyles.noMargin}>
                    <li>
                      <Typography variant="body2">
                        {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit1' })}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit2' })}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2" gutterBottom>
                        {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit3' })}
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2" gutterBottom>
                        {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmit4' })}
                      </Typography>
                    </li>
                  </ol>
                  <Box mb={4} />
                  <Typography variant="body2" style={{ fontStyle: 'italic' }} component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.tanf.waysToSubmitNotes' })}
                  </Typography>
                  <Box mb={4} />
                  <div>
                    <Button
                      color="secondary"
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {}}
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                      style={{ marginRight: 10 }}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
                    </Button>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {}}
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
                    </Button>
                  </div>
                </FinancialHelpCard>
                <Box mb={5} />
              </>
            ) : null}
            {user_settings.financial_benefits_medial_care ? (
              <>
                <FinancialHelpCard
                  title={intl.formatMessage({ id: 'financialHelp.medicalCare.title' })}
                  description={intl.formatMessage({ id: 'financialHelp.medicalCare.description' })}
                  toQualifyTitle={intl.formatMessage({ id: 'financialHelp.medicalCare.toQualify' })}
                  toQualifyItems={[
                    intl.formatMessage({ id: 'financialHelp.groceries.qualification1' }),
                    'Next qualification 1',
                    'Next qualification 2',
                  ]}
                  image={medicalIcon}
                  imageStyles={{ width: isSmall ? 52 : 73 }}
                  imageDescription={intl.formatMessage({ id: 'financialHelp.medicalCare.imageDescription' })}
                >
                  <img src={MEDQUEST} style={{ width: 50 }} />
                  <Box mb={1} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.medQuest.whatIs' })}
                  </h3>
                  <Typography variant="body2" component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.medQuest.whatIsP' })}
                  </Typography>
                  <Box mb={3} />
                  <Typography variant="body2" component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.medQuest.whatIsP2' })}
                  </Typography>
                  <Box mb={4} />
                  <h3 className={cn(financialStyles.toQualifyTitle, financialStyles.noMargin)}>
                    {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmitTitle' })}
                  </h3>
                  <ol className={financialStyles.noMargin}>
                    <li>
                      <Typography variant="body2">
                        {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit1' })}{' '}
                        <span className={layoutStyles.link}>
                          {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit1link' })}
                        </span>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2">
                        {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmit2' })}
                      </Typography>
                    </li>
                  </ol>
                  <Box mb={4} />
                  <Typography variant="body2" style={{ fontStyle: 'italic' }} component="p" gutterBottom>
                    {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmitNotes' })}{' '}
                    <span className={layoutStyles.link}>
                      {intl.formatMessage({ id: 'financialHelp.medQuest.waysToSubmitNotesLink' })}
                    </span>
                  </Typography>
                  <Box mb={4} />
                  <div>
                    <Button
                      color="secondary"
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {}}
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                      style={{ marginRight: 10 }}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.apply' })}
                    </Button>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {}}
                      endIcon={<OpenInNewIcon style={{ fontSize: 19 }} />}
                    >
                      {intl.formatMessage({ id: 'financialHelp.snap.learnMore' })}
                    </Button>
                  </div>
                </FinancialHelpCard>
                <Box mb={5} />
              </>
            ) : null}
            <div className={styles.bottomBar}>
              <span className={styles.backToTopPlaceholder} />
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                onClick={() => history.push(`${urlPrefix}/careers`)}
              >
                {intl.formatMessage({ id: 'financialHelp.returnToCareers' })}
              </Button>
              <ScrollToTopButton toTopEvent="CAREERS_TOP" />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

FinancialHelp.PropTypes = {
  width: PropTypes.string,
};

export default FinancialHelp;
