/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/layout.module.scss';
import cn from 'classnames';

/* Assets */
// import { co as vars } from 'lib/varsDict';
import vars from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useExternalWindow } from 'lib/utils';
import { grey } from '@mui/material/colors';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const Footer = () => {
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const { openNewWindow: openTerms } = useExternalWindow(`/#${urlPrefix}/terms-of-use`);
  const { openNewWindow: openCredits } = useExternalWindow(`#${urlPrefix}/credits-and-citations`);
  const { openNewWindow: openAbout } = useExternalWindow(`#${urlPrefix}/about`);

  return (
    <footer className={styles.footer}>
      <Container maxWidth="lg">
        <Grid container className={styles.footerContent} spacing={0} justifyContent="space-between">
          <Grid item xs={12} sm={8} lg={'auto'} className={styles.footerLeft}>
            <div className={styles.footerImages}>
              {'stateSealUrl' in vars ? (
                <a href={vars.stateSealHref.slice(1, -1)} target="_blank" rel="noopener noreferrer">
                  <img
                    className={styles.footerLogo}
                    src={vars.stateSealUrl.slice(1, -1)}
                    alt={`${vars.organizationName}'s seal`}
                  />
                </a>
              ) : (
                <a href={vars.stateSealHref.slice(1, -1)} target="_blank" rel="noopener noreferrer">
                  <img
                    className={styles.footerLogo}
                    href={vars.stateSealHref.slice(1, -1)}
                    src={vars.logoFooterUrl.slice(1, -1)}
                    alt={vars.organizationName}
                  />
                </a>
              )}
            </div>
            <div className={cn(styles.footerText, styles.officialText)}>
              <FormattedMessage id="layout.footer.officialWebsite" />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={'auto'} className={styles.footerRight}>
            {vars.footerEmail && (
              <div className={styles.footerText}>
                <FormattedMessage id="layout.footer.feedbackText" />
                <a
                  href={`mailto:${vars.footerEmail.slice(1, -1)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                  onClick={() => trackEvent('FOOTER_EMAIL')}
                >
                  <FormattedMessage id="layout.footer.feedbackLink" />
                </a>
                .
                <FormattedMessage id="layout.footer.feedbackAfterLink" defaultMessage=" " />
              </div>
            )}
            <div className={styles.footerText}>
              <a
                style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                onClick={() => {
                  trackEvent('FOOTER_TERMS');
                  openTerms();
                }}
              >
                <FormattedMessage id="layout.footer.privacy" />
              </a>
            </div>
            <div className={styles.footerText}>
              <a
                style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                onClick={() => {
                  trackEvent('FOOTER_TERMS');
                  openCredits();
                }}
              >
                <FormattedMessage id="layout.footer.credits" />
              </a>
            </div>
            <div className={styles.footerText}>
              <a
                style={{ color: 'white', paddingBottom: 8, '&:active': { color: grey[100] } }}
                onClick={() => {
                  trackEvent('FOOTER_ABOUT');
                  openAbout();
                }}
              >
                <FormattedMessage
                  id="layout.footer.aboutLink"
                  values={{ productName: process.env.REACT_APP_PRODUCT_NAME }}
                />
              </a>
            </div>
            <div className={`${styles.footerText} ${styles.footerCopy}`} style={{ marginRight: 0 }}>
              <FormattedMessage id="layout.footer.copy" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
