import { useEffect, Fragment, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/css';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import filterStyles from 'styles/filter-input.module.scss';

/* Assets */
import info from 'assets/images/Info_Cards.svg';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setTagsActive, setKeywordFilter } from 'app/slices/careersSlice';
import {
  setActiveSoc,
  setActiveCareer,
  setTagsActive as setJobsTagsActive,
  setCareerPathsFilterValues,
} from 'app/slices/jobsSlice';
import { setNewUser } from 'app/slices/appSlice';
import { useInfiniteCareers, useRateCareer, useInfiniteRatings } from 'shared/hooks';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Button, Tooltip, Box, Container, CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ScrollToTopButton from 'components/ScrollToTopButton';
import { useSnackbar } from 'notistack';
import EmploymentSupportCard from './EmploymentSupportCard';
import {
  Tag,
  RecommendationsCard,
  CustomNotification,
  CareersFocusDropDown,
  CareersNewUserPopupContents,
  KeywordSearchInput,
  JobNotificationContents,
} from 'shared/components';

/* Utils */
import { debounce } from 'lib/performance';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: '#151515',
  },
}));

const stateName = process.env.REACT_APP_STATE_NAME;

const focusDict = {
  boostEarnings: 'onboarding.goals.boostEarnings',
  similarSkills: 'onboarding.goals.similarSkills',
  manyJobs: 'careers.tags.manyJobsToggle',
};

const Careers = () => {
  const { keywordFilter, tagsActive } = useSelector((state) => state.careers);
  const filters = { filter_by_name: keywordFilter, filter_by_tag: tagsActive };
  const { isSuccess, data, isFetching, isFetched, hasNextPage, fetchNextPage } = useInfiniteCareers(filters);
  const rateCareer = useRateCareer(filters);
  const tooltipClasses = useStylesBootstrap();
  const { newUser, loadingLanguage } = useSelector((state) => state.app);
  const ratingsLookup = useSelector((state) => state.ratings.results.lookup);
  const dispatch = useDispatch();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const searchInputEl = useRef(null);

  const intl = useIntl();
  const debouncedSetKeywordFilter = debounce((val) => dispatch(setKeywordFilter(val)), 400);
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const currentSoc = useSelector((state) => state.jobs.currentSoc);
  const results = isSuccess
    ? data.pages.flat(1).map((c) => {
        if (c.soc === currentSoc) {
          return { ...c, flag: 'currentCareer' };
        }
        return c;
      })
    : [];

  const userGoal = 'notSure';
  const ratingsQuery = useInfiniteRatings({});
  const allRatings = ratingsQuery.isSuccess ? ratingsQuery.data.pages.flat(1) : [];
  const [rated, setRated] = useState(false);

  const responses = results.reduce(
    (prev, curr) => {
      return prev[curr.section] ? { ...prev, [curr.section]: prev[curr.section].concat(curr) } : prev;
    },
    { top: [], goal: [], more: [] }
  );
  const combined = [...responses.goal, ...responses.more];

  const filtersActive = keywordFilter.trim().length > 0 || tagsActive.length > 0;
  const more = combined.slice(10);

  const showMore = () => {
    trackEvent('CAREERS_MORE');
    fetchNextPage();
  };

  const showJobs = (item) => {
    const { soc: soc_code } = item;
    dispatch(setActiveSoc(soc_code));
    dispatch(setActiveCareer(item));
    dispatch(setJobsTagsActive(''));
    history.push(`${urlPrefix}/jobs`);
    if (window) {
      window.scrollTo({ top: 0 });
    }
  };

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('CAREERS');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    if (newUser) {
      setTimeout(() => {
        dispatch(setNewUser(false));
        enqueueSnackbar('', {
          variant: 'default',
          content: (key) => (
            <CustomNotification id={key} check>
              <CareersNewUserPopupContents intl={intl} history={history} />
            </CustomNotification>
          ),
        });
      }, 1000 * 60);
    }
    return () => {
      console.log('Leaving Careers');
    };
  }, []);

  const handleShowJobsClick = (groupIndex, item) => () => {
    dispatch(setCareerPathsFilterValues([{ title: item?.title, soc: item?.soc }]));
    trackEvent('CAREERS_GLOBAL_SEEJOBS', item);

    switch (groupIndex) {
      case 0:
        trackEvent('CAREERS_TOPCAREER_SEEJOBS', item);
        break;
      case 1:
        trackEvent('CAREERS_GOAL_SEEJOBS', item);
        break;
      case 'MORE':
        trackEvent('CAREERS_MORE_SEEJOBS', item);
        break;
      default:
        break;
    }

    showJobs(item);
  };

  const handleLikeClick = (groupIndex, career) => () => {
    const newRating = career.rating === 1 ? 0 : 1;
    setRated(true);

    rateCareer.mutate(
      { career, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                <JobNotificationContents rate="like" intl={intl} history={history} />
              </CustomNotification>
            ),
          });
        },
      }
    );

    if (newRating === 1) {
      trackEvent('CAREERS_GLOBAL_THUMBSUP', career);
      switch (groupIndex) {
        case 0:
          trackEvent('CAREERS_TOPCAREER_THUMBSUP', career);
          break;
        case 1:
          trackEvent('CAREERS_GOAL_THUMBSUP', career);
          break;
        case 'MORE':
          trackEvent('CAREERS_MORE_THUMBSUP', career);
          break;
        default:
          break;
      }
    }
  };

  const handleDislikeClick = (groupIndex, career) => () => {
    const newRating = career.rating === -1 ? 0 : -1;
    setRated(true);

    rateCareer.mutate(
      { career, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                <JobNotificationContents rate="dislike" intl={intl} history={history} />
              </CustomNotification>
            ),
          });
        },
      }
    );

    if (newRating === -1) {
      trackEvent('CAREERS_GLOBAL_THUMBSDOWN', career);
      switch (groupIndex) {
        case 0:
          trackEvent('CAREERS_TOPCAREER_THUMBSDOWN', career);
          break;
        case 1:
          trackEvent('CAREERS_GOAL_THUMBSDOWN', career);
          break;
        case 'MORE':
          trackEvent('CAREERS_MORE_THUMBSDOWN', career);
          break;
        default:
          break;
      }
    }
  };

  const handleTitleClick = (groupIndex, item) => () => {
    trackEvent('CAREERS_GLOBAL_MOREINFO', item);

    switch (groupIndex) {
      case 0:
        trackEvent('CAREERS_TOPCAREER_MOREINFO', item);
        break;
      case 1:
        trackEvent('CAREERS_GOAL_MOREINFO', item);
        break;
      case 'MORE':
        trackEvent('CAREERS_MORE_MOREINFO', item);
        break;
      default:
        break;
    }
  };

  const handleTagClick = (groupIndex, item) => () => {
    let eventName = 'CAREERS_MORE_CHICLET';

    if (groupIndex !== 'MORE') {
      eventName = groupIndex === 0 ? 'CAREERS_TOPCAREER_CHICLET' : 'CAREERS_GOAL_CHICLET';
    }

    trackEvent(eventName, item);
  };

  const GROUP_INDEX_TOP = 0;
  const GROUP_INDEX_GOAL = 1;
  const GROUP_INDEX_MORE = 'MORE';

  const topCount = responses['top']
    ? responses['top'].reduce((acc, curr) => (ratingsLookup[curr.id] !== 'disliked' ? acc + 1 : acc), 0)
    : 0;

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <div className={styles.careersTitleFilters}>
            <Typography variant="h1" component="h2">
              {intl.formatMessage({ id: 'careers.title' })}
            </Typography>
            <div className={styles.careersFiltersContainer}>
              <KeywordSearchInput
                keywordFilter={keywordFilter}
                setKeywordFilter={debouncedSetKeywordFilter}
                uncontrolled
                inputRef={searchInputEl}
              />
              <CareersFocusDropDown
                activeFilters={tagsActive}
                handleFiltersChange={(newValues) => dispatch(setTagsActive(newValues))}
              />
            </div>
          </div>
          <>
            <div className={filterStyles.tags}>
              {keywordFilter.trim().length || tagsActive.length > 0 ? (
                <>
                  <hr className={layoutStyles.separator} />
                  <Typography variant="h5" style={{ paddingRight: '8px' }}>
                    {intl.formatMessage({ id: 'recommendations.filterBy' })}
                  </Typography>
                  {keywordFilter.trim().length > 0 ? (
                    <Tag
                      text={keywordFilter}
                      onClick={() => {
                        dispatch(setKeywordFilter(''));
                        searchInputEl.current.value = '';
                      }}
                      type="filter"
                    />
                  ) : null}
                  {tagsActive.length > 0
                    ? tagsActive.map((option) => (
                        <Tag
                          key={option}
                          text={intl.formatMessage({ id: focusDict[option] })}
                          onClick={() => {
                            dispatch(setTagsActive(tagsActive.filter((t) => t !== option)));
                          }}
                          type="filter"
                        />
                      ))
                    : null}
                  <hr className={layoutStyles.separator} style={{ marginTop: '8px' }} />
                </>
              ) : null}
            </div>
          </>
          <div className={styles.header__content}>
            {!loadingLanguage && !filtersActive && topCount > 0 ? (
              <>
                <Box m={1} />
                <hr className={layoutStyles.separator} />
                <Box m={4} />
                <Typography variant="h3" gutterBottom>
                  {intl.formatMessage({ id: 'careers.section.topCareer' })}{' '}
                  <Tooltip
                    enterTouchDelay={0}
                    title={
                      <Fragment>
                        <span style={{ fontSize: 14 }}>
                          {intl.formatMessage({ id: `careers.section.topCareerTooltip` }, { state: stateName })}
                        </span>
                      </Fragment>
                    }
                    placement="top"
                    arrow
                    classes={tooltipClasses}
                  >
                    <img
                      alt="Info"
                      src={info}
                      className={css({
                        position: 'relative',
                        top: '1px',
                        width: '15px',
                        height: '15px',
                        cursor: 'pointer',
                      })}
                    />
                  </Tooltip>
                </Typography>
              </>
            ) : null}
            {filtersActive || topCount > 0 ? <Box m={3} /> : null}
            {(isFetching && !isFetched) || loadingLanguage ? (
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : null}
            {!loadingLanguage && isFetched && filtersActive && results.length === 0 && (
              <>
                <Typography variant="body2">{intl.formatMessage({ id: 'careers.filters.noResults' })}</Typography>
                <Box m={3} />
              </>
            )}
            {!loadingLanguage &&
              isFetched &&
              filtersActive &&
              results
                .slice(0, 10)
                .map((item) => (
                  <RecommendationsCard
                    type="career"
                    item={item}
                    key={item.id}
                    rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                    onButtonClick={handleShowJobsClick(GROUP_INDEX_TOP, item)}
                    onLikeClick={handleLikeClick(GROUP_INDEX_TOP, item)}
                    onDislikeClick={handleDislikeClick(GROUP_INDEX_TOP, item)}
                    onTitleClick={handleTitleClick(GROUP_INDEX_TOP, item)}
                    onTagClick={handleTagClick(GROUP_INDEX_TOP, item)}
                    titleHoverable={true}
                    rangeLabel={intl.formatMessage({ id: 'careers.salaryRange', description: 'string' })}
                    hideRateTooltip={allRatings.length > 0 || rated}
                  />
                ))}
            {!loadingLanguage &&
              isFetched &&
              !filtersActive &&
              topCount > 0 &&
              responses['top'].map((item) => (
                <RecommendationsCard
                  type="career"
                  item={item}
                  key={item.id}
                  rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                  onButtonClick={handleShowJobsClick(GROUP_INDEX_TOP, item)}
                  onLikeClick={handleLikeClick(GROUP_INDEX_TOP, item)}
                  onDislikeClick={handleDislikeClick(GROUP_INDEX_TOP, item)}
                  onTitleClick={handleTitleClick(GROUP_INDEX_TOP, item)}
                  onTagClick={handleTagClick(GROUP_INDEX_TOP, item)}
                  titleHoverable={true}
                  rangeLabel={intl.formatMessage({ id: 'careers.salaryRange', description: 'string' })}
                  hideRateTooltip={allRatings.length > 0 || rated}
                />
              ))}
            {!loadingLanguage && isFetched && !filtersActive && combined && combined.length ? (
              <>
                <hr className={layoutStyles.separator} />
                {topCount ? (
                  <>
                    <Box m={4} />
                    <Typography variant="h3" gutterBottom>
                      {intl.formatMessage({ id: `careers.section.${userGoal}` })}{' '}
                      <Tooltip
                        enterTouchDelay={0}
                        title={
                          <Fragment>
                            <span style={{ fontSize: 14 }}>
                              {intl.formatMessage({ id: `careers.section.${userGoal}Subtitle` }, { state: 'Colorado' })}
                            </span>
                          </Fragment>
                        }
                        placement="top"
                        arrow
                        classes={tooltipClasses}
                      >
                        <img
                          alt="Info"
                          src={info}
                          className={css({
                            position: 'relative',
                            top: '1px',
                            width: '15px',
                            height: '15px',
                            cursor: 'pointer',
                          })}
                        />
                      </Tooltip>
                    </Typography>
                  </>
                ) : null}
                <Box m={3} />
                {combined.slice(0, 10).map((item) => (
                  <RecommendationsCard
                    type="career"
                    item={item}
                    key={item.id}
                    rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                    onButtonClick={handleShowJobsClick(GROUP_INDEX_GOAL, item)}
                    onLikeClick={handleLikeClick(GROUP_INDEX_GOAL, item)}
                    onDislikeClick={handleDislikeClick(GROUP_INDEX_GOAL, item)}
                    onTitleClick={handleTitleClick(GROUP_INDEX_GOAL, item)}
                    onTagClick={handleTagClick(GROUP_INDEX_GOAL, item)}
                    titleHoverable={true}
                    rangeLabel={intl.formatMessage({ id: 'careers.salaryRange', description: 'string' })}
                    hideRateTooltip={allRatings.length > 0 || rated}
                  />
                ))}
              </>
            ) : null}
            {!loadingLanguage && isFetched && more.length > 0 && (
              <>
                {!filtersActive ? (
                  <>
                    <hr className={layoutStyles.separator} />
                    <Box m={4} />
                    <Typography variant="h3" gutterBottom>
                      {intl.formatMessage({ id: `careers.section.moreCareers` })}
                    </Typography>
                    <Box m={3} />
                  </>
                ) : null}
                {more.map((item) => (
                  <RecommendationsCard
                    type="career"
                    item={item}
                    key={item.id}
                    rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                    onLikeClick={handleLikeClick(GROUP_INDEX_MORE, item)}
                    onDislikeClick={handleDislikeClick(GROUP_INDEX_MORE, item)}
                    onTitleClick={handleTitleClick(GROUP_INDEX_MORE, item)}
                    onTagClick={handleTagClick(GROUP_INDEX_MORE, item)}
                    titleHoverable={true}
                    onButtonClick={handleShowJobsClick(GROUP_INDEX_MORE, item)}
                    rangeLabel={intl.formatMessage({ id: 'careers.salaryRange' })}
                    hideRateTooltip={allRatings.length > 0 || rated}
                  />
                ))}
              </>
            )}
            <EmploymentSupportCard />
            <div className={styles.bottomBar}>
              <span className={styles.backToTopPlaceholder} />
              {!(filtersActive && results.length === 0) && hasNextPage && !loadingLanguage && isFetched ? (
                <Button color="primary" size="large" type="submit" variant="contained" onClick={showMore}>
                  {intl.formatMessage({ id: 'recommendations.showMore' })}
                </Button>
              ) : (
                <span />
              )}
              <ScrollToTopButton toTopEvent="CAREERS_TOP" />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Careers;
