import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Paper from '@mui/material/Paper';

import vars from './vars.module.scss';

const theme = createTheme({
  typography: {
    fontFamily: vars.bodyFont,
    h1: {
      fontFamily: vars.headerFont,
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 700,
      color: vars.black,
    },

    h2: {
      fontFamily: vars.headerFont,
      fontSize: '20px',
      lineHeight: '24px',
      color: vars.black,
    },

    h3: {
      //not sure if this is being used for headers or as a style
      fontFamily: vars.headerFont,
      fontSize: '20px',
      lineHeight: '24px',
      color: vars.black,
    },

    h4: {
      //not sure if this is being used for headers or as a style
      fontFamily: vars.bodyFont,
      fontSize: 20,
      lineHeight: '27px',
      fontWeight: 700,
      color: vars.black,
    },

    h5: {
      //not sure if this is being used for headers or as a style
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 700,
      color: vars.darkGray,
    },
    h6: {
      //not sure if this is being used for headers or as a style
      fontSize: 18,
    },
    body1: {
      fontFamily: vars.bodyFont,
      fontSize: '16px',
      lineHeight: '24px',
      color: vars.darkGray,
    },
    body2: {
      fontFamily: vars.bodyFont,
      fontSize: '18px',
      lineHeight: '27px',
      color: vars.darkGray,
    },
    caption: {
      fontSize: 12,
      color: vars.darkGray,
    },
  },
  shadows: Array(25).fill('none'),
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 700,
          borderRadius: 0,
          boxShadow: 'none',
          padding: '7px 12px',
          fontFamily: vars.bodyFont,
          // height: 31
          // '@media (max-width: 479px)': {
          // 	padding: '5px 22px !important'
          // }
        },
        contained: {
          boxShadow: 'none',
        },
        containedPrimary: {
          color: vars.white,
        },
        containedSecondary: {
          color: vars.darkGray,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: vars.lightGray,
        },
        input: {
          padding: '17px 11px 9px',
          fontSize: 16,
          '@media (min-width: 640px)': {
            fontSize: 12,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: vars.mediumGray,
          },
          '&:hover:not(.Mui-focused):before': {
            borderBottomColor: vars.mediumGray,
          },
          '&:after': {
            // focused
            borderBottomColor: vars.actionBlue,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingRight: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 16,
          width: '100%',
          '&:hover': {
            '&>label:is(:not(.Mui-focused, .Mui-disabled))': {
              color: vars.darkGray,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
        formControl: {
          top: -5,
        },
        filled: {
          color: vars.darkGray,
          '&.focused': {
            color: vars.actionBlue,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: vars.mediumGray,
          '&.Mui-focused': {
            color: vars.black,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: grey['900'],
          fontSize: 9,
          margin: 10,
          padding: '0',
          textAlign: 'right',
          position: 'absolute',
          top: '100%',
          right: 0,
        },
        contained: {
          margin: '0 10px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          paddingRight: '0',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.75rem',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: '10px !important;',
        },
        option: {
          fontSize: 14,
        },
        input: {
          fontSize: 16,
          '@media (min-width: 640px)': {
            fontSize: 12,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        //this now matches filter-input.module.scss ".selecMenu" class
        outlined: {
          boxSizing: 'border-box',
          width: '100%',
          borderRadius: 0,
          borderColor: vars.mediumGray,
          // borderTop: 'none',
          '@media (min-width: 768px)': {
            width: '203px',
          },
        },
      },
    },
  },
  props: {
    MuiDivider: {
      light: true,
    },
    MuiMenu: {
      PaperProps: {
        variant: 'outlined',
      },
    },
    MuiAutocomplete: {
      PaperComponent: ({ children }) => (
        <Paper variant="outlined" style={{ width: '100%' }}>
          {children}
        </Paper>
      ),
    },
  },

  palette: {
    primary: {
      main: vars.primaryColorMain,
      dark: vars.primaryColorDark,
    },
    secondary: {
      main: vars.secondaryColorMain,
      dark: vars.secondaryColorDark,
    },
    accent: {
      main: vars.accentColorMain,
    },
    error: {
      main: vars.errorColorMain,
    },
    background: {
      default: vars.backgroundColorDefault,
    },
  },
});

export default theme;
