import { configureStore } from '@reduxjs/toolkit';
import careersSlice from 'app/slices/careersSlice';
import jobsSlice from 'app/slices/jobsSlice';
import trainingSlice from 'app/slices/trainingSlice';
import experienceSlice from 'app/slices/experienceSlice';
import skillsSlice from './slices/skillsSlice';
import ratingsSlice from 'app/slices/ratingsSlice';
import jobCoachSlice from 'app/slices/jobCoachSlice';
import jobSearchSlice from 'app/slices/jobSearchSlice';
import financialHelpSlice from 'app/slices/financialHelpSlice';
import onboardingSlice from 'app/slices/onboardingSlice';
import appSlice from 'app/slices/appSlice';
import workSearchSlice from 'app/slices/workSearchSlice';

export default configureStore({
  reducer: {
    careers: careersSlice,
    jobs: jobsSlice,
    training: trainingSlice,
    experience: experienceSlice,
    skills: skillsSlice,
    ratings: ratingsSlice,
    coach: jobCoachSlice,
    activity: jobSearchSlice,
    financialHelp: financialHelpSlice,
    onboarding: onboardingSlice,
    app: appSlice,
    worksearch: workSearchSlice,
  },
});
