import { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { TextField, Button, Box, FormControl, CircularProgress } from '@mui/material';
import DateFnsUtils from '@date-io/date-fns';
import { apiRequestV1 } from 'lib/API';
import cn from 'classnames';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

/**
 * i18n
 */
import { useIntl } from 'react-intl';

/* Analytics */
import { trackEvent } from 'lib/analytics';

import styles from 'styles/apply-job-form.module.scss';
import layoutStyles from 'styles/layout.module.scss';

import BrowseDocuments from 'components/BrowseDocuments';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function RegisterSchoolFacility({
  addActivityItem,
  setOpen,
  updateActivityItem,
  setGeneralActivity,
  selectedActivity,
  editting = false,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [refreshConter, setRefreshConter] = useState(0);
  const [documentsLinks, setDocumentsLinks] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    setRefreshConter(refreshConter + 1);
    if (selectedActivity.upload_documents && selectedActivity.upload_documents.length > 0) {
      setDocumentsLinks([...selectedActivity.upload_documents]);
    }
  }, [selectedActivity, editting]);

  useEffect(() => {
    if (documentsLinks.length > 0) {
      documentsLinks.forEach((doc, idx) => {
        apiRequestV1(
          'get',
          `/worksearch?worksearch_id=${encodeURIComponent(
            selectedActivity.worksearch_id
          )}&file_uuid=${encodeURIComponent(doc.file_uuid)}`
        ).then((res) => {
          setDocumentsLinks([
            ...documentsLinks.slice(0, idx),
            { ...doc, downloadUrl: res.download_url },
            ...documentsLinks.slice(idx + 1),
          ]);
        });
      });
    }
  }, [documentsLinks]);

  const validationSchema = yup.object({
    activity_date: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
    name_of_school: yup
      .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
      .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
  });

  const defaultValues = {
    contact_name: '',
    upload_documents: [],
    notes: '',
    name_of_school: '',
    activity_date: new Date(),
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={
        selectedActivity
          ? {
              ...selectedActivity,
              upload_documents: [],
              activity_date: new Date(
                selectedActivity.activity_date.slice(0, 4),
                selectedActivity.activity_date.slice(5, 7) - 1,
                selectedActivity.activity_date.slice(8, 10)
              ),
            }
          : defaultValues
      }
      onSubmit={async (values) => {
        setSubmitting(true);
        let year = values.activity_date.getFullYear();
        let month = values.activity_date.getMonth() + 1;
        let day = values.activity_date.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        const vals4submit = {
          ...values,
          activity_type: 'register_with_school',
          activity_date: `${year}-${month}-${day}`,
        };
        vals4submit.upload_documents = values.upload_documents.map((doc) => ({
          filename: doc.name,
          upload_contents: doc.contents,
        }));
        if (editting) {
          trackEvent('WEEK_VIEWACTIVITY_SAVE');
        } else {
          trackEvent('WEEK_SAVEACTIVITY_SCHOOL');
        }
        apiRequestV1(editting ? 'put' : 'post', '/worksearch', { data: { activity: vals4submit } })
          .then(({ activity, status }) => {
            if (editting) {
              updateActivityItem(activity);
            } else {
              addActivityItem(activity);
            }
            setGeneralActivity(status);
            setOpen(false);
            setSubmitting(false);
          })
          .catch((err) => {
            setSubmitting(false);
            console.log(err);
          });
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => {
        function isValidDate(d) {
          return d instanceof Date && !isNaN(d);
        }
        const handleDateChange = (date) => {
          if (isValidDate(date)) {
            setFieldValue('activity_date', date);
          } else {
            setFieldValue('activity_date', '');
          }
        };
        return (
          <form onSubmit={handleSubmit} className={styles.loginFlow__form}>
            {submitting ? (
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                <div className={styles.fields}>
                  <div>
                    <TextField
                      InputLabelProps={{ required: true }}
                      error={touched.name_of_school && Boolean(errors.name_of_school)}
                      disabled={submitting || (editting && !editMode)}
                      fullWidth
                      helperText={touched.name_of_school && errors.name_of_school}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.nameOfSchoolPlacement' })}
                      margin="normal"
                      name="name_of_school"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.name_of_school}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.nameOfSchoolPlacement' }),
                      }}
                    />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <TextField
                      error={touched.contact_name && Boolean(errors.contact_name)}
                      disabled={submitting || (editting && !editMode)}
                      fullWidth
                      helperText={touched.contact_name && errors.contact_name}
                      label={intl.formatMessage({ id: 'jobSearchActivity.form.contactName' })}
                      margin="normal"
                      name="contact_name"
                      classes={{ root: styles.textFieldRoot }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.contact_name}
                      variant="filled"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.contactName' }),
                      }}
                    />
                    <HelpOutlineIcon className={styles.helperTextIcon} />
                    <span className={cn(styles.fieldDescription, styles.fieldDescriptionSemiClose)}>
                      {intl.formatMessage({ id: 'jobSearchActivity.form.contactNameSub' })}
                    </span>
                  </div>
                  <div>
                    <FormControl>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disabled={submitting || (editting && !editMode)}
                          invalidDateMessage={intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })}
                          disableToolbar
                          autoOk
                          variant="filled"
                          inputVariant="filled"
                          format="MM/dd/yyyy"
                          classes={{ root: styles.textFieldRoot }}
                          label={intl.formatMessage({ id: 'jobSearchActivity.form.dateOfRegistration' })}
                          value={values.activity_date}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </div>
                </div>
                <div className={styles.boxes}>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.uploadDocuments' })}
                      </span>
                    </Box>
                    <Field
                      name="upload_documents"
                      disabled={submitting || (editting && !editMode)}
                      component={BrowseDocuments}
                    />
                    <div className={styles.downloadLinks}>
                      {documentsLinks.map((doc) =>
                        doc.downloadUrl ? <a href={doc.downloadUrl}>{doc.filename}</a> : <span>...</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <Box pt={2} pb={1}>
                      <span className={styles.fieldLabel}>
                        {intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      </span>
                    </Box>
                    <TextField
                      disabled={submitting || (editting && !editMode)}
                      placeholder={intl.formatMessage({ id: 'jobSearchActivity.form.notes' })}
                      multiline
                      rows={5}
                      variant="outlined"
                      InputProps={{ style: { fontSize: 12, height: '112px' } }}
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="notes"
                      inputProps={{
                        title: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <Box display="flex" justifyContent="space-between" mt={4}>
              <a
                href="https://www.honolulu.gov/cms-dcs-menu/site-dcs-sitearticles/735-job-seekers.html"
                target="_blank"
                rel="noopener noreferrer"
                className={layoutStyles.link}
              >
                {intl.formatMessage({ id: 'jobSearchActivity.form.meetWithCoach' })}
              </a>
              <Box display="flex">
                <Box mr={1}>
                  <Button
                    color="primary"
                    disabled={submitting}
                    size="large"
                    onClick={() => setOpen(false)}
                    variant="contained"
                  >
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.cancel' })
                    )}
                  </Button>
                </Box>
                {editting && !editMode ? (
                  <Button
                    color="secondary"
                    disabled={submitting}
                    size="large"
                    variant="contained"
                    onClick={(evt) => {
                      evt.preventDefault();
                      trackEvent('WEEK_VIEWACTIVITY_EDIT');
                      setEditMode(true);
                    }}
                  >
                    {intl.formatMessage({ id: 'layout.header.edit' })}
                  </Button>
                ) : (
                  <Button color="secondary" disabled={submitting} size="large" type="submit" variant="contained">
                    {submitting ? (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">{intl.formatMessage({ id: 'jobSearchActivity.form.save' })}</span>
                      </div>
                    ) : (
                      intl.formatMessage({ id: 'jobSearchActivity.form.save' })
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}
RegisterSchoolFacility.propTypes = {
  setOpen: PropTypes.func,
  addActivityItem: PropTypes.func,
  updateActivityItem: PropTypes.func,
  setGeneralActivity: PropTypes.func,
  selectedActivity: PropTypes.bool,
  editting: PropTypes.bool,
};
export default RegisterSchoolFacility;
