import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

/* Styles */
import styles from 'styles/drawer.module.scss';
import cn from 'classnames';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerOpen } from 'app/slices/appSlice';
import { setOpenContactInfo } from 'app/slices/experienceSlice';

/* UI Dependencies */
import { Drawer as MuiDrawer, IconButton, List, ListItem } from '@mui/material';
import Close from 'assets/images/Close';

export default function Drawer({ signOut }) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { drawerOpen, user_settings, urlPrefix } = useSelector((state) => state.app);
  const needsOnboarding = useSelector((state) => state.app.needsOnboarding);

  const handleDrawerClose = () => {
    dispatch(setDrawerOpen(false));
  };

  const routeDict = {
    HOME: '/careers',
    JOBS: '/jobs',
    TRAINING: '/training',
    JOBSEARCH: 'job-search-activity',
    EXPERIENCE: '/experience',
    RATINGS: '/ratings',
  };
  const handleMenuItemClick = (page) => () => {
    const eventName = `I_MENU_${page}`;
    trackEvent(eventName);
    history.push(`${urlPrefix}${routeDict[page]}`);
    handleDrawerClose();
  };

  const handleSignOut = () => {
    const eventName = 'I_MENU_SIGNOUT';
    trackEvent(eventName);
    handleDrawerClose();
    signOut();
  };

  const handleEditClick = () => {
    dispatch(setOpenContactInfo(true));
    history.push(`${urlPrefix}/experience`);
  };

  return (
    <MuiDrawer
      className={styles.menuDrawer}
      variant="persistent"
      anchor="right"
      open={drawerOpen}
      classes={{
        paper: styles.menuDrawerPaper,
      }}
    >
      <div>
        <IconButton
          onClick={handleDrawerClose}
          classes={{
            root: styles.menuDrawerCloseContainer,
          }}
          title={intl.formatMessage({ id: 'layout.menuDrawerCloseTitle' })}
          size="large"
        >
          <Close className={styles.menuDrawerClose} />
        </IconButton>
      </div>
      <List>
        <div
          className="divider-menu"
          style={{
            marginTop: '10px',
            width: '100%',
            height: '1px',
            backgroundColor: '#B7B7B7',
          }}
        ></div>

        <ListItem>
          <div style={{ padding: '10px 0px' }}>
            <h4
              style={{ textTransform: 'uppercase', marginBlockStart: '0', marginBlockEnd: '0', marginBottom: '10px' }}
            >
              {intl.formatMessage({ id: 'layout.menu.additionalResources' })}
            </h4>
            <a
              className={styles.menuDrawerLink}
              href="https://cdle.colorado.gov/wfc"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className={cn(styles.menuDrawerLink__text)}>
                {intl.formatMessage({ id: 'layout.menu.workforceCenters' })}
              </span>
            </a>
            <img
              alt={intl.formatMessage({ id: 'accessibility.externalLink' })}
              style={{ marginLeft: '5px' }}
              src="/images/external-link-alt-solid.svg"
              width="14"
              height="13"
            />

            <a
              className={styles.menuDrawerLink}
              href="https://www.connectingcolorado.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className={cn(styles.menuDrawerLink__text)}>
                {intl.formatMessage({ id: 'layout.menu.connectingColorado' })}
              </span>
            </a>
            <img
              alt={intl.formatMessage({ id: 'accessibility.externalLink' })}
              style={{ marginLeft: '5px' }}
              src="/images/external-link-alt-solid.svg"
              width="14"
              height="13"
            />
          </div>
        </ListItem>

        {!needsOnboarding ? (
          <>
            <div
              className="divider-menu"
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: '#B7B7B7',
              }}
            ></div>

            <ListItem>
              <div style={{ padding: '10px 0px' }}>
                <h4
                  style={{
                    textTransform: 'uppercase',
                    marginBlockStart: '0',
                    marginBlockEnd: '0',
                    marginBottom: '10px',
                  }}
                >
                  {intl.formatMessage({ id: 'layout.menu.shortcuts' })}
                </h4>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span
                    className={cn(styles.menuDrawerLink, styles.menuDrawerLink__text)}
                    onClick={handleMenuItemClick('HOME')}
                  >
                    {intl.formatMessage({ id: 'layout.menu.home' })}
                  </span>
                  <span
                    className={cn(styles.menuDrawerLink__text, styles.menuDrawerLink)}
                    onClick={handleMenuItemClick('JOBS')}
                  >
                    {intl.formatMessage({ id: 'layout.menu.jobs' })}
                  </span>
                  <span
                    className={cn(styles.menuDrawerLink__text, styles.menuDrawerLink)}
                    onClick={handleMenuItemClick('TRAINING')}
                  >
                    {intl.formatMessage({ id: 'layout.menu.training' })}
                  </span>
                  {user_settings.add_job_search_activity_hero_button ? (
                    <span
                      className={cn(styles.menuDrawerLink__text, styles.menuDrawerLink)}
                      onClick={handleMenuItemClick('JOBSEARCH')}
                    >
                      {intl.formatMessage({ id: 'jobSearchActivity.jobSearch' })}
                    </span>
                  ) : null}
                  <span
                    className={cn(styles.menuDrawerLink__text, styles.menuDrawerLink)}
                    onClick={handleMenuItemClick('EXPERIENCE')}
                  >
                    {intl.formatMessage({ id: 'layout.menu.experience' })}
                  </span>
                  <span
                    className={cn(styles.menuDrawerLink__text, styles.menuDrawerLink)}
                    onClick={handleMenuItemClick('RATINGS')}
                  >
                    {intl.formatMessage({ id: 'layout.menu.savedItems' })}
                  </span>
                </div>
              </div>
            </ListItem>

            <div
              className="divider-menu"
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: '#B7B7B7',
              }}
            ></div>
            <ListItem>
              <span
                style={{ position: 'relative', top: '10px' }}
                className={styles.menuDrawerLink}
                onClick={handleEditClick}
              >
                <span className={cn(styles.menuDrawerLink__text)}>
                  {intl.formatMessage({ id: 'layout.header.editProfile' })}
                </span>
              </span>
            </ListItem>
            <ListItem>
              <span className={styles.menuDrawerLink} onClick={handleSignOut}>
                <span className={cn(styles.menuDrawerLink__text)}>
                  {intl.formatMessage({ id: 'layout.menu.signOut' })}
                </span>
              </span>
            </ListItem>
          </>
        ) : (
          ''
        )}
      </List>
    </MuiDrawer>
  );
}

Drawer.propTypes = {
  signOut: PropTypes.func,
};
