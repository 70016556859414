import { useEffect } from 'react';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import loginStyles from 'styles/login.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Typography, Box, Container } from '@mui/material';
import CloseTabButton from 'components/CloseTabButton';

const TermsOfUse = () => {
  const productName = process.env.REACT_APP_PRODUCT_NAME;
  const stateName = process.env.REACT_APP_STATE_NAME;

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TERMSOFUSE');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
    return () => {
      console.log('Leaving Terms of Use');
    };
  }, []);

  const TermsSection = ({ children }) => (
    <Box component="section" mb={4}>
      {children}
    </Box>
  );
  const TermsSectionHeader = ({ children }) => (
    <Typography variant="h4" component="h2" gutterBottom>
      {children}
    </Typography>
  );
  const TermsSectionBody = ({ children }) => <Box my={2}>{children}</Box>;
  const TermsBodyParagraph = ({ children }) => (
    <Typography variant="body2" component="p" gutterBottom>
      {children}
    </Typography>
  );
  const TermsBodyList = ({ items }) => (
    <Typography variant="body2" component="ul" gutterBottom>
      {items.map((item, idx) => (
        <Typography key={`li-${idx}`} variant="body2" component="li" gutterBottom>
          {item}
        </Typography>
      ))}
    </Typography>
  );

  return (
    <>
      <div className={loginStyles.loginFlow__formContainer__innerShadow} />
      <div className={styles.content}>
        <Container component="article" maxWidth="lg">
          <CloseTabButton />
          <Typography variant="h1" gutterBottom>
            Terms of Use and Privacy Policy
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box my={4}>
            <TermsBodyParagraph>Welcome to {productName}!</TermsBodyParagraph>
            <TermsBodyParagraph>
              We are taking every precaution, including making our best efforts to comply with all relevant state and
              federal laws and regulations related to privacy, to ensure that your information is as secure as possible.
            </TermsBodyParagraph>
            <TermsBodyParagraph>
              By visiting and using this website, its subpages, and content (collectively known as, this website), you
              are agreeing to the Terms of Use and Privacy Policy.
            </TermsBodyParagraph>
          </Box>

          <TermsSection>
            <TermsSectionHeader>Use of Information</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                The {stateName} Department of Labor and Workforce Development (“Department”) and/or its
                agents/contractors/partners will use {stateName}'s records and the information you provide when you
                register for {productName} to connect you with personalized employment and training opportunities.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                We may also use and share the provided information as reasonably necessary to comply with law or a legal
                process (including a court or government order or subpoena); to detect, prevent, or otherwise address
                fraud, security or technical issues; to enforce the Terms of Use and Privacy Policy; to protect the
                rights, property or safety of the state, our users, and/or the public; or otherwise with your express
                consent.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                By registering for {productName}, you agree to allow the Department and/or its contractors to use the
                above information, as well as any associated data you have made available to the Department, to create
                personalized job and career opportunity recommendations for you. You further agree that the Department
                and/or its contractors and partners may contact you and/or send you communications which may include,
                without limitation: important information regarding job recruitment opportunities, information on
                available jobs, skills training opportunities, education opportunities, and other COVID-19 or
                skills-related resources and opportunities.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                By providing your phone number you specifically agree to allow the Department to send communications to
                your phone which may include, without limitation: important information regarding programs, job
                recruitment opportunities that may be suitable for you, information on available jobs that may be
                suitable for you, and available skills training opportunities.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Security and Monitoring Notice</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                If you browse through this website to read, print, or download information, navigational information may
                be collected.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                For site security purposes, all network traffic is monitored in order to identify unauthorized attempts
                to upload or change information, or otherwise conduct criminal activity. In connection with authorized
                law enforcement investigations and pursuant to required legal process, navigational information may be
                used to assist in obtaining personal information.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                Further, to protect this website as well as the associated computer system supporting the website from
                unauthorized use and to ensure that the computer system is functioning properly, individuals accessing
                this website and the associated computer system are subject to having all of their activities monitored
                and recorded by personnel authorized to do so by the state. Anyone using this website and the associated
                computer system expressly consents to such monitoring and is advised that if such monitoring reveals
                evidence of possible abuse or criminal activity, {stateName} personnel may provide the results of such
                monitoring to appropriate officials. Unauthorized attempts to upload or change the content of the
                website, or otherwise cause damage to this website or the computer system are strictly prohibited and
                may be punishable under applicable law.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Change Without Notice</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                This website is dynamic and will change over time without notice. Users of this website are therefore
                fully responsible for checking the accuracy, completeness, currency, and suitability of content found on
                the website themselves.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                In addition, we reserve the right to modify the Terms of Use and Privacy Policy at any time. We
                encourage you to review the Terms of Use and Privacy Policy on a regular basis. Your continued use of{' '}
                {productName} following any modifications to the website, the Terms of Use, and Privacy Policy
                constitutes your agreement to such modifications.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Disclaimer of Warranties</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                This website is provided “AS IS” and without warranties of any kind. To the fullest extent of the law,{' '}
                {stateName}, including each agency, officer, or employee of {stateName}, disclaims all warranties,
                expressed or implied, including but not limited to warranties of merchantability and fitness for a
                particular purpose, with respect to this website; this disclaimer of warranties includes but is not
                limited to disclaimers as regards the following: (1) that the functioning of this website will be
                uninterrupted and error-free; (2) that any defects or other errors on this website will be corrected;
                and (3) that this website as well as the computer system are or will be free of viruses or other harmful
                components.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                In addition, neither the {stateName} nor any agency, officer, or employee of the {stateName} makes any
                representations, guarantees, or warranties as to: (1) the accuracy, completeness, currency, or
                suitability of the information provided via this website; (2) the use of or the results of the use of
                this website; and (3) the accuracy, reliability, availability or completeness or usefulness of the
                content of websites created and maintained by persons other than the {stateName} and linked to or from
                this website.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Limitation of Liability</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                Under no circumstances, including but not limited to, negligence, shall the {stateName} nor any agency,
                officer, or employee of the {stateName} be liable for any special, consequential and/or incidental
                damages that may result from the use of or the inability to access or use this website, even if the{' '}
                {stateName} has been advised of the possibility of such damages. Applicable law may not allow the
                limitation or exclusion of liability or incidental or consequential damages, so the above limitation or
                exclusion may not apply to you. In no event shall the {stateName}’s total liability to you for all
                damages, losses, and causes of action, whether in contract, tort (including but not limited to
                negligence, or otherwise) exceed the amount paid by you, if any, to access this website.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Copyright Notice</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                Duplication or use of any content from this website for commercial purposes or in any manner likely to
                give the impression of official approval by the {stateName} is prohibited.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Links to External Websites and Pages</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                This website may contain hypertext links to external sites and pages containing information created and
                maintained by public and private organizations other than the {stateName}. These hypertext links may be
                created by the {stateName} if it determines that establishing the external link will be consistent with
                assisting or furthering the purpose of this website which is to significantly and diligently promote
                public service to citizens and businesses by
              </TermsBodyParagraph>
              <TermsBodyList
                items={[
                  'expanding business and citizen access to government services and government information;',
                  'offering an easy and convenient process for businesses and citizens to conduct transactions with State government online;',
                  'accelerating the development and delivery of an increased volume of quality, online government services; improving the level of customer service from State government; and',
                  'extending electronic government services to citizens of cities and county government.',
                ]}
              ></TermsBodyList>
              <TermsBodyParagraph>
                In addition, hypertext links may be created by the {stateName} for informational purposes, that is,
                where the linked external website will provide useful and valuable information to visitors to this
                website, or where the linked external website is required or authorized by law.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                The {stateName}, in its sole discretion, will determine whether the external website meets the purpose
                of this website or for the specified informational purposes. The inclusion of a hypertext link to an
                external website is not intended as an endorsement of any products or service offered or referenced on
                the linked website, the organizations sponsoring such website, or any views which might be expressed or
                referenced on the website.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                These hypertext links to external sites and pages may be removed or replaced in the sole discretion of
                the {stateName}, at any time without notice.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                In the event you discover problems with or have concerns regarding the format, accuracy, timeliness, or
                completeness of a linked external site, please contact the organization responsible for the linked
                external site – the {stateName} does not control nor is it responsible for any linked external sites and
                pages.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Disclaimer of Endorsement</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                Any reference in this website to any specific commercial products, processes, or services by trade name,
                trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or
                favoring by the {stateName}.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Jurisdiction</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                Any person choosing to access and use this website is subject to the jurisdiction of the {stateName}.
              </TermsBodyParagraph>
              <TermsBodyParagraph>
                These terms shall be governed by and construed in accordance with the laws of the {stateName}. Any
                dispute arising out of or relating to these terms, or access and use of this website shall be decided
                under the laws of and in the state courts in the {stateName}, and you hereby consent to and submit to
                the personal jurisdiction of such courts for the purpose of adjudicating such dispute.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Other Websites</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                This website may contain hypertext links to other websites and pages containing information created and
                maintained by other public and private organizations. If you decide to visit these linked sites, you
                should check the privacy statements of these other websites. The {stateName} is not responsible for the
                content provided on these other websites.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>

          <TermsSection>
            <TermsSectionHeader>Severability</TermsSectionHeader>
            <TermsSectionBody>
              <TermsBodyParagraph>
                If any provision in the above Terms of Use and Privacy Policy shall be determined to be unlawful, void,
                or unenforceable by a court of competent jurisdiction, then that provision shall be deemed severable
                from the remaining terms and shall not affect the validity and enforceability of the remaining
                provisions.
              </TermsBodyParagraph>
            </TermsSectionBody>
          </TermsSection>
        </Container>
      </div>
    </>
  );
};

export default TermsOfUse;
