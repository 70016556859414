import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, Link as ReactLink, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import cn from 'classnames';
import styles from 'styles/layout.module.scss';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setLocale, setDrawerOpen, setLoadingLanguage } from 'app/slices/appSlice';
import { setOpenContactInfo } from 'app/slices/experienceSlice';

/* Assets */
// import { co as vars } from 'lib/varsDict';
import vars from 'styles/vars.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* API */
import { updateExperience } from 'shared/src/lib/API/experience';

/* Material UI and other UI Dependencies */
import Container from '@mui/material/Container';
import SearchIcon from '@mui/icons-material/Search';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { ListItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import useMediaQuery from '@mui/material/useMediaQuery';
import HamburgerMenu from 'react-hamburger-menu';
import LanguagesSelect from 'components/LanguagesSelect';
import { useQueryClient } from '@tanstack/react-query';

/* Colors */
import colors from 'styles/vars.module.scss';

const useStyles = makeStyles(() => ({
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLogo: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showHamburger = useMediaQuery('(max-width:767px)');
  const mobileSearchInputEl = useRef(null);
  const [hamOpen, setHamOpen] = useState(false);
  const queryClient = useQueryClient();

  const intl = useIntl();

  let history = useHistory();
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth0();
  const { urlPrefix, locale, loadingRecommendations } = useSelector((state) => state.app);
  const needsOnboarding = useSelector((state) => state.app.needsOnboarding);

  const languageEventDict = {
    en: 'ENGLISH',
    es: 'ESPANOL',
    zh: 'CHINESE',
    ja: 'JAPANESE',
    tl: 'TAGALOG',
    ilo: 'ILOKANO',
  };

  const resetLanguage = async (e) => {
    e.preventDefault();
    const language = e.target.value;
    if (typeof window !== 'undefined') {
      localStorage.setItem('lng', language);
      trackEvent(`I_MENU_${languageEventDict[language]}`);
      const newPath =
        language === 'en'
          ? `/#${location.pathname.substring(1 + intl.locale.length)}`
          : intl.locale === 'en'
          ? `/#/${language}${location.pathname}`
          : `/#/${language}${location.pathname.substring(1 + intl.locale.length)}`;
      dispatch(setLocale(language));
      dispatch(setLoadingLanguage(true));
      window.location.href = newPath;
      if (isAuthenticated) {
        await updateExperience({ language }).catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
      }
      queryClient.resetQueries({
        predicate: (query) => {
          if (newPath.includes('contact-info')) {
            return false;
          }
          return newPath.includes('onboarding') ? query.queryKey[0] !== '/users/me/experience/' : true;
        },
      });
      dispatch(setLoadingLanguage(false));
    }
  };

  const formik = useFormik({
    initialValues: {
      query: '',
    },
    onSubmit: async ({ query }) => {
      if (query.trim() === '') {
        return false;
      }
      history.push(`${urlPrefix}/coach?query=${query}`);
    },
  });

  const handleDrawerOpen = (e) => {
    e.preventDefault();
    dispatch(setDrawerOpen(true));
  };
  const handleBlur = (...params) => {
    formik.resetForm();
    formik.handleBlur(...params);
  };
  const handleSignOut = () => {
    const eventName = 'I_HAMBURGER_SIGNOUT';
    trackEvent(eventName);
    logout({
      returnTo: window.location.origin,
    });
  };

  const handleEditClick = () => {
    dispatch(setOpenContactInfo(true));
    history.push(`${urlPrefix}/experience`);
  };

  return (
    <>
      <div
        className={cn({
          [styles.header]: true,
          [styles.header__alt]: isAuthenticated,
          [styles.header__alt__mobileMenuOpen]: hamOpen,
          [styles.header__borderBottom]: loadingRecommendations,
        })}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {process.env.REACT_APP_JOB_COACH === 'true' ? (
            <form onSubmit={formik.handleSubmit}>
              <FilledInput
                id="filled-adornment-mobile-search"
                type="text"
                name="query"
                value={formik.values.query}
                onBlur={handleBlur}
                onChange={formik.handleChange}
                classes={{ root: styles.mobileSearchInputRoot, focused: styles.inputFocused }}
                disableUnderline
                inputProps={{
                  ref: mobileSearchInputEl,
                  title: intl.formatMessage({ id: 'layout.submitCoachSearch' }),
                }}
              />
            </form>
          ) : null}

          {/* Logo */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a
              href={vars.headerLogoHref.slice(1, -1)}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.headerLogo}
            >
              <img
                className={cn({
                  [styles.headerLogo]: true,
                })}
                src={vars.logoHeaderUrl.slice(1, -1)}
                alt={vars.organizationName.slice(1, -1)}
              />
            </a>
          </div>

          <Box display="flex" alignItems="center" flexGrow={1} justifyContent="flex-end">
            {/* Language Selector */}
            {!showHamburger || !isAuthenticated ? (
              <LanguagesSelect locale={locale} resetLanguage={resetLanguage} />
            ) : null}

            {/* Hamburger */}
            {isAuthenticated ? (
              <>
                {showHamburger ? (
                  <div className={styles.hamburgerButton__container}>
                    <HamburgerMenu
                      className={styles.hamburgerButton}
                      isOpen={hamOpen}
                      menuClicked={() => setHamOpen(!hamOpen)}
                      width={27}
                      height={14}
                      strokeWidth={3}
                      rotate={0}
                      color={colors.actionBlue}
                      borderRadius={0}
                      animationDuration={0.5}
                    />
                    <div className={styles.hamburgerButton__text}>
                      {intl.formatMessage({ id: hamOpen ? 'layout.hamburger.close' : 'layout.hamburger.menu' })}
                    </div>
                  </div>
                ) : (
                  <span
                    className={cn({
                      [styles.link]: true,
                      [styles.infoIcon__container]: true,
                    })}
                    onClick={handleDrawerOpen}
                  >
                    <HamburgerMenu
                      className={styles.hamburgerButton}
                      isOpen={false}
                      menuClicked={() => setHamOpen(!hamOpen)}
                      width={27}
                      height={14}
                      strokeWidth={3}
                      rotate={0}
                      color={colors.actionBlue}
                      borderRadius={0}
                      animationDuration={0.5}
                    />
                  </span>
                )}
                {process.env.REACT_APP_JOB_COACH === 'true' && (
                  <form onSubmit={formik.handleSubmit}>
                    <FilledInput
                      id="filled-adornment-search"
                      type="text"
                      name="query"
                      value={formik.values.query}
                      onBlur={handleBlur}
                      onChange={formik.handleChange}
                      classes={{ root: styles.searchInputRoot, focused: styles.inputFocused }}
                      disableUnderline
                      endAdornment={
                        <label className={styles.searchFieldLabel} htmlFor="filled-adornment-search">
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        </label>
                      }
                      inputProps={{
                        title: intl.formatMessage({ id: 'layout.askJobCoach' }),
                      }}
                    />
                  </form>
                )}
              </>
            ) : null}
          </Box>
        </Container>
      </div>
      {showHamburger ? (
        <div
          className={cn({
            [styles.mobileMenu]: true,
            [styles.mobileMenuOpen]: hamOpen,
          })}
        >
          <div className={styles.mobileMenu__languagesContainer}>
            <LanguagesSelect locale={locale} resetLanguage={resetLanguage} />
          </div>

          <ListItem>
            <div>
              <div style={{ padding: '10px 0px' }}>
                <h4
                  style={{
                    textTransform: 'uppercase',
                    marginBlockStart: '0',
                    marginBlockEnd: '0',
                    marginBottom: '10px',
                    color: 'white',
                  }}
                >
                  {intl.formatMessage({ id: 'layout.menu.additionalResources' })}
                </h4>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <a
                      style={{ textDecoration: 'none', fontSize: '14px', textTransform: 'uppercase' }}
                      href="https://cdle.colorado.gov/wfc"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span className={cn(styles.menuDrawerLink__text)} style={{ color: 'white' }}>
                        {intl.formatMessage({ id: 'layout.menu.workforceCenters' })}
                      </span>
                    </a>
                    <img
                      alt={intl.formatMessage({ id: 'accessibility.externalLink' })}
                      style={{ marginLeft: '5px', filter: 'invert(100%)' }}
                      src="/images/external-link-alt-solid.svg"
                      width="14"
                      height="13"
                    />
                  </div>

                  <div>
                    <a
                      style={{ textDecoration: 'none', fontSize: '14px', textTransform: 'uppercase' }}
                      href="https://www.connectingcolorado.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span className={cn(styles.menuDrawerLink__text)} style={{ color: 'white' }}>
                        {intl.formatMessage({ id: 'layout.menu.connectingColorado' })}
                      </span>
                    </a>
                    <img
                      alt={intl.formatMessage({ id: 'accessibility.externalLink' })}
                      style={{ marginLeft: '5px', filter: 'invert(100%)' }}
                      src="/images/external-link-alt-solid.svg"
                      width="14"
                      height="13"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ListItem>

          <div
            className="divider-menu"
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#2E6AD1',
            }}
          ></div>

          {!needsOnboarding ? (
            <>
              <ul>
                <h4
                  style={{
                    textTransform: 'uppercase',
                    marginBlockStart: '0',
                    marginBlockEnd: '0',
                    marginBottom: '10px',
                    color: 'white',
                    marginTop: '10px',
                  }}
                >
                  {intl.formatMessage({ id: 'layout.menu.shortcuts' })}
                </h4>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/careers`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>{intl.formatMessage({ id: 'layout.menu.careers' })}</span>
                  </ReactLink>
                </li>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/jobs`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>{intl.formatMessage({ id: 'layout.menu.jobs' })}</span>
                  </ReactLink>
                </li>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/training`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>
                      {intl.formatMessage({ id: 'layout.menu.training' })}
                    </span>
                  </ReactLink>
                </li>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/experience`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>
                      {intl.formatMessage({ id: 'layout.menu.experience' })}
                    </span>
                  </ReactLink>
                </li>
                <li className={styles.mobileMenu__item}>
                  <ReactLink to={`${urlPrefix}/ratings`} onClick={() => setHamOpen(false)}>
                    <span className={styles.mobileMenu__link}>
                      {intl.formatMessage({ id: 'layout.menu.savedItems' })}
                    </span>
                  </ReactLink>
                </li>
                {process.env.REACT_APP_JOB_COACH === 'true' && (
                  <li className={styles.mobileMenu__item}>
                    <ReactLink to={`${urlPrefix}/coach`} onClick={() => setHamOpen(false)}>
                      <span className={styles.mobileMenu__link}>{intl.formatMessage({ id: 'layout.menu.coach' })}</span>
                    </ReactLink>
                  </li>
                )}
              </ul>
              <ul className={styles.mobileMenu__items}>
                <li
                  className={cn(styles.mobileMenu__item, styles.noTextTransform)}
                  style={{ fontSize: '14px', textTransform: 'uppercase', color: 'white' }}
                >
                  <span className={cn(styles.mobileMenu__item, styles.noTextTransform)} onClick={handleEditClick}>
                    {intl.formatMessage({ id: 'layout.header.editProfile' })}
                  </span>
                </li>
                <li
                  className={cn(styles.mobileMenu__item, styles.noTextTransform)}
                  style={{ fontSize: '14px', textTransform: 'uppercase', color: 'white' }}
                >
                  <span onClick={handleSignOut}>{intl.formatMessage({ id: 'layout.menu.signOut' })}</span>
                </li>
              </ul>
            </>
          ) : (
            ''
          )}
        </div>
      ) : null}
    </>
  );
};

export default Header;
