export const REC_ENGINE_ROUTES = {
  CAREERS: 'careers',
  JOBS: 'jobs',
  TRAINING: 'training',
  EXPERIENCE: 'experience',
  RATINGS: 'ratings',
};

export const UNAUTHENTICATED_PAGE_ROUTES = {
  LOGIN: 'login',
};

export const ONBOARDING_ROUTES = {
  ROOT: 'onboarding',
  CONTACT: 'onboarding/contact-info',
  RESUME: 'onboarding/resume-upload',
  WORK_EXPERIENCE: 'onboarding/experience',
  SKILLS_EDUCATION: 'onboarding/education',
  GOALS: 'onboarding/goals',
};
