import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

/* Redux */
import { useDispatch } from 'react-redux';
import { setActivePath } from 'app/slices/appSlice';

/* Styles */
import styles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Browser */
import { isIE, isSafari } from 'react-device-detect';

const Layout = ({ children }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const productName = process.env.REACT_APP_PRODUCT_NAME;
  const locality = process.env.REACT_APP_LOCALITY;
  const environment = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    dispatch(setActivePath(pathname));
  }, [pathname]);

  return (
    <div className={styles.layout}>
      <div>
        {isIE && <div className={styles.browserBanner}>{intl.formatMessage({ id: 'browsers.ie.banner' })}</div>}
        {/* {environment !== 'prod' && ( */}
        <div className={styles.envBanner}>
          <span>{intl.formatMessage({ id: 'app.envBanner.uiClaimant' })}</span>
        </div>
        {/* )} */}
        <Header />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
