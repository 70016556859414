import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Link, useParams, useHistory } from 'react-router-dom';
import { arrayToCsv, downloadBlob } from 'lib/csv';
import { apiRequest } from 'lib/API';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { getJobSearchActivity, getProfile } from 'lib/API';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import editJobStyles from 'styles/edit-job-activity.module.scss';
import 'react-circular-progressbar/dist/styles.css';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  useMediaQuery,
} from '@mui/material';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setActivities, setStatus, setLoaded } from 'app/slices/workSearchSlice';
import { setUserSettings } from 'app/slices/appSlice';
import { cleanAppliedIds } from 'app/slices/jobsSlice';

/* UI Components */
import EditJobActivity from 'components/EditJobActivity';
import SearchActivityCard from 'components/SearchActivityCard';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import SSNDialog from 'components/forms/SSNDialog';

const JobSearchActivity = () => {
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const user_settings = useSelector((state) => state.app.user_settings);
  const loaded_worksearch = useSelector((state) => state.worksearch.loaded);
  const worksearch_status = useSelector((state) => state.worksearch.status);
  const worksearch_activities = useSelector((state) => state.worksearch.activities);

  const [editModalsOpen, setEditModalsOpen] = useState([false]);
  const [formsSelectedActivities, setFormSelectedActivities] = useStateCallback(['']);
  const [ssnDialogOpen, setSsnDialogOpen] = useState(Boolean(worksearch_status.ssn_required));
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false);
  const [detailedActivity, setDetailedActivity] = useState([]);
  const [generalActivity, setGeneralActivity] = useState(useSelector((state) => state.worksearch.status));
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const { lang } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  if (lang === 'onboarding') {
    history.push(`${urlPrefix}/careers`);
  }

  const completedOverall =
    (generalActivity.overall_activities_completed / generalActivity.overall_activities_required) * 100;
  const completedCurrentWeek =
    (generalActivity.weekly_activities_completed / generalActivity.weekly_activities_required) * 100;

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOBSEARCH');
    TimeMe.startTimer();
    return () => {
      console.log('Leaving WorkSearch');
    };
  }, []);

  const getTo = (fromStr) => {
    const year = fromStr.slice(0, 4);
    const month = fromStr.slice(5, 7);
    const day = fromStr.slice(8, 10);
    const from = new Date(year, month - 1, day);
    from.setDate(from.getDate() + 6);
    return from.toISOString().slice(0, 10);
  };

  const getWeekArr = (status, activities) => {
    if (!status.week_start_dates || !status.week_start_dates.length) {
      return [];
    }
    const weeksObj = status.week_start_dates.reduce((acc, curr) => {
      acc[curr] = {
        from: curr,
        to: getTo(curr),
        required: 3,
        items: [],
      };
      return acc;
    }, {});
    activities.forEach((curr) => {
      const week = curr.week_start_date;
      weeksObj[week].items.push(curr);
    });
    const weeks = Object.keys(weeksObj).sort((a, b) => {
      return new Date(b) - new Date(a);
    });
    const weekArr = weeks.map((w) => weeksObj[w]);
    return weekArr;
  };

  useEffect(() => {
    if (!loaded_worksearch) {
      setLoadingActivity(true);
      getProfile().then(function (response) {
        if (typeof response.user_settings !== 'undefined') {
          dispatch(setUserSettings('value' in response.user_settings ? response.user_settings.value : {}));
        }
        dispatch(cleanAppliedIds());
        setLoadingActivity(false);
      });
      apiRequest('GET', '/worksearch').then(({ activities, status }) => {
        const weekArr = getWeekArr(status, activities);
        setDetailedActivity(weekArr);
        dispatch(setActivities(activities));

        setGeneralActivity(status);
        dispatch(setStatus(status));

        const newEditModalsOpen = new Array(weekArr.length).fill(false);
        const newFormSelectedActivities = new Array(weekArr.length).fill('');
        setEditModalsOpen(newEditModalsOpen);
        setFormSelectedActivities(newFormSelectedActivities);

        setSsnDialogOpen(status.ssn_required);
        if (!status.ssn_required) {
          dispatch(setLoaded(true));
        }
      });
    } else {
      const weekArr = getWeekArr(worksearch_status, worksearch_activities);
      setDetailedActivity(weekArr);
      setGeneralActivity(worksearch_status);

      const newEditModalsOpen = new Array(weekArr.length).fill(false);
      const newFormSelectedActivities = new Array(weekArr.length).fill('');
      setEditModalsOpen(newEditModalsOpen);
      setFormSelectedActivities(newFormSelectedActivities);
    }
  }, [refreshCounter]);

  const addActivityItem = (item) => {
    let found = false;
    const newDetailedActivity = detailedActivity.map((w) => {
      if (w.from === item.week_start_date) {
        w.items.push(item);
        found = true;
      }
      w.items.sort((a, b) => {
        return new Date(a.activity_date) - new Date(b.activity_date);
      });
      return w;
    });
    if (!found) {
      newDetailedActivity.push({
        from: item.week_start_date,
        to: getTo(item.week_start_date),
        required: 3,
        items: [item],
      });
    }
    newDetailedActivity.sort((a, b) => {
      return new Date(b.from) - new Date(a.from);
    });
    const newEditModalsOpen = new Array(newDetailedActivity.length).fill(false);
    const newFormSelectedActivities = new Array(newDetailedActivity.length).fill('');
    setDetailedActivity(newDetailedActivity);
    setEditModalsOpen(newEditModalsOpen);
    setFormSelectedActivities(newFormSelectedActivities);
    dispatch(setActivities(worksearch_activities.concat(item)));
  };
  const updateActivityItem = (item) => {
    const newDetailedActivity = detailedActivity.map((w) => {
      if (w.from === item.week_start_date) {
        let found = false;
        w.items = w.items.map((i) => {
          if (i.worksearch_id === item.worksearch_id) {
            found = true;
            return item;
          }
          return i;
        });
        if (!found) {
          w.items.push(item);
        }
      } else {
        w.items = w.items.filter((i) => {
          return i.worksearch_id !== item.worksearch_id;
        });
      }
      w.items.sort((a, b) => {
        return new Date(a.activity_date) - new Date(b.activity_date);
      });
      return w;
    });
    setDetailedActivity(newDetailedActivity);
    const newWorksearchActivities = worksearch_activities.map((i) => {
      if (i.worksearch_id === item.worksearch_id) {
        return item;
      }
      return i;
    });
    dispatch(setActivities(newWorksearchActivities));
  };

  const months = {
    '01': intl.formatMessage({ id: 'jobSearchActivity.january' }),
    '02': intl.formatMessage({ id: 'jobSearchActivity.february' }),
    '03': intl.formatMessage({ id: 'jobSearchActivity.march' }),
    '04': intl.formatMessage({ id: 'jobSearchActivity.april' }),
    '05': intl.formatMessage({ id: 'jobSearchActivity.may' }),
    '06': intl.formatMessage({ id: 'jobSearchActivity.june' }),
    '07': intl.formatMessage({ id: 'jobSearchActivity.july' }),
    '08': intl.formatMessage({ id: 'jobSearchActivity.august' }),
    '09': intl.formatMessage({ id: 'jobSearchActivity.september' }),
    10: intl.formatMessage({ id: 'jobSearchActivity.october' }),
    11: intl.formatMessage({ id: 'jobSearchActivity.november' }),
    12: intl.formatMessage({ id: 'jobSearchActivity.december' }),
  };

  function formatDateRange(from, to) {
    if (!from || !to) {
      return '';
    }
    const year1 = from.substr(0, 4);
    const month1 = from.substr(5, 2);
    const day1 = from.substr(8, 2);
    const year2 = to.substr(0, 4);
    const month2 = to.substr(5, 2);
    const day2 = to.substr(8, 2);

    if (year1 !== year2) {
      return `${months[month1]} ${parseInt(day1, 10)}, ${year1}-${months[month2]} ${parseInt(day2, 10)}, ${year2}`;
    } else if (month1 !== month2) {
      return `${months[month1]} ${parseInt(day1, 10)}-${months[month2]} ${parseInt(day2, 10)}, ${year1}`;
    }
    return `${months[month1]} ${parseInt(day1, 10)}-${parseInt(day2, 10)}, ${year1}`;
  }
  function activityString(from, to) {
    return `${intl.formatMessage({ id: 'jobSearchActivity.activityFor' })} ${formatDateRange(from, to)}`;
  }
  function formatDate(date) {
    const year = date.substr(2, 2);
    const month = date.substr(5, 2);
    const day = date.substr(8, 2);
    return `${parseInt(month, 10)}.${parseInt(day, 10)}.${year}`;
  }
  const activitiesDict = {
    applied_for_job: intl.formatMessage({ id: 'jobSearchActivity.applyForAJob' }),
    attend_job_seminar: intl.formatMessage({ id: 'jobSearchActivity.attendJobSearch' }),
    job_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.scheduleMeeting' }),
    interview_with_employer: intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' }),
    follow_up_with_employer: intl.formatMessage({ id: 'jobSearchActivity.directContact' }),
    register_with_school: intl.formatMessage({ id: 'jobSearchActivity.registerFacility' }),
    resume_upload: intl.formatMessage({ id: 'jobSearchActivity.activity.uploadedResume' }),
  };
  const fieldsDict = {
    company_name: intl.formatMessage({ id: 'jobSearchActivity.form.companyName' }),
    contact_name: intl.formatMessage({ id: 'jobSearchActivity.form.contactName' }),
    contact_email: intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' }),
    contact_phone_number: intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumber' }),
    position_applied_for: intl.formatMessage({ id: 'jobSearchActivity.form.positionApplied' }),
    notes: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
    result_onf_contact: intl.formatMessage({ id: 'jobSearchActivity.form.resultOnfContact' }),
    method_of_contact: intl.formatMessage({ id: 'jobSearchActivity.form.methodOfContact' }),
    application_filed: intl.formatMessage({ id: 'jobSearchActivity.form.applicationFiled' }),
    name_of_school: intl.formatMessage({ id: 'jobSearchActivity.form.nameOfSchoolPlacement' }),
    result_of_contact: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfContact' }),
    result_of_seminar: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfSeminar' }),
    result_of_interview: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfInterview' }),
    result_of_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfCoachMeeting' }),
    method_of_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.form.methodOfCoachMeeting' }),
    method_of_interview: intl.formatMessage({ id: 'jobSearchActivity.form.methodOfInterview' }),
    type_of_seminar: intl.formatMessage({ id: 'jobSearchActivity.form.typeOfSeminar' }),
    type_of_activity: intl.formatMessage({ id: 'jobSearchActivity.form.typeOfActivity' }),
    attended_skills_assessment: intl.formatMessage({ id: 'jobSearchActivity.form.attendedSkills' }),
  };
  const boolVal = (val) => {
    return val
      ? intl.formatMessage({ id: 'jobSearchActivity.form.yes' })
      : intl.formatMessage({ id: 'jobSearchActivity.form.no' });
  };
  const handlePrintHistory = () => {
    setLoadingDialogOpen(true);
    trackEvent('ACTIVITY_SHARE_PRINT');
    apiRequest('GET', '/worksearch').then(({ activities }) => {
      const weeksObj = activities.reduce((acc, curr) => {
        const week = curr.week_start_date,
          to = getTo(week);
        if (!acc[week]) {
          acc[week] = { from: week, to, required: 3, items: [curr] };
        } else {
          acc[week].items.push(curr);
        }
        return acc;
      }, {});
      const weeks = Object.keys(weeksObj).sort((a, b) => {
        return new Date(b) - new Date(a);
      });
      const weekArr = weeks.map((w) => weeksObj[w]);

      const frame1 = document.createElement('iframe');
      frame1.style.display = 'none';
      document.body.appendChild(frame1);
      const frameDoc = frame1.contentWindow || frame1.contentDocument.document || frame1.contentDocument;
      let html = '';
      html += '<html><head><title></title></head><body>';
      html += `<h1>${intl.formatMessage({ id: 'jobSearchActivity.activitySummary' })}</h1>`;
      html +=
        '<table style="width: 100%; border-collapse: collapse; font-family: Verdana, Helvetica, Arial sans-serif;">';
      weekArr.forEach((w) => {
        html += '<tr>';
        html += `<td style="width: 50%; border: 1px solid black; padding: 5px;">`;
        html += `<div style="font-size: 14px">`;
        html += `<span style="font-weight: bold; padding-left: 10px; padding-top: 14px;">${formatDateRange(
          w.from,
          w.to
        )}<span><br/>`;
        html += `<ul>`;
        w.items.forEach((i) => {
          html += `<li style="margin-top: 10px;"><span style="font-weight: bold;">${i.activity_date}: ${
            activitiesDict[i.activity_type]
          }</span><br />`;
          let {
            activity_date, // eslint-disable-line no-unused-vars
            activity_type, // eslint-disable-line no-unused-vars
            worksearch_id, // eslint-disable-line no-unused-vars
            upload_documents, // eslint-disable-line no-unused-vars
            created_on, // eslint-disable-line no-unused-vars
            updated_on, // eslint-disable-line no-unused-vars
            week_start_date, // eslint-disable-line no-unused-vars
            ...rest
          } = i;
          Object.keys(rest)
            .filter((k) => i[k])
            .sort()
            .forEach((k) => {
              html += `<span style="font-size: 12px;"><span style="font-weight: 500">${fieldsDict[k]}</span>: ${
                i[k] === true || i[k] === false ? boolVal(i[k]) : i[k]
              }</span><br />`;
            });
          html += `</li>`;
        });
        html += `</li></ul></div></td></tr>`;
      });
      html += '</table>';
      html += '</body></html>';
      frameDoc.document.write(html);
      setLoadingDialogOpen(false);
      frame1.contentWindow.focus();
      frame1.contentWindow.print();
      frameDoc.document.close();
      frame1.parentNode.removeChild(frame1);
    });
  };

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          {user_settings.job_search_activity_header ? (
            <>
              <div className={styles.titleFilters}>
                <Typography variant="h1" component="h2">
                  {intl.formatMessage({ id: 'jobSearchActivity.title', description: 'string' })}
                </Typography>
                <Box pb={5} />
              </div>
              <hr className={layoutStyles.separator} />
            </>
          ) : null}
          {loadingActivity ? (
            <Box m={7} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {user_settings.job_search_activity_summary_print_or_share ? <Box pb={1} /> : null}
              {user_settings.job_search_activity_summary_print_or_share ? (
                <div className={styles.printShareToolbar}>
                  <div />
                  <div className={styles.printShareToolbar__right}>
                    {user_settings.job_search_activity_summary_print_link ? (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handlePrintHistory}
                        style={{ borderRadius: 4 }}
                      >
                        {intl.formatMessage({ id: 'jobSearchActivity.downloadPdf' })}
                        <PdfIcon
                          style={{ fontSize: 18, marginLeft: 4, position: 'relative', bottom: 1 }}
                          alt={intl.formatMessage({ id: 'jobSearchActivity.downloadPdf' })}
                          name={intl.formatMessage({ id: 'jobSearchActivity.downloadPdf' })}
                        />
                      </Button>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <Box m={4} />
              {user_settings.job_search_activity_summary ? (
                <div className={styles.jobSearchActivityMain}>
                  <div className={cn(styles.jobSearchActivityMain__half, styles.firstHalf)}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {intl.formatMessage({ id: 'jobSearchActivity.youreRequired' })}
                    </Typography>
                    <ul className={styles.jobSearchActivityMain__list}>
                      {user_settings.job_search_activity_form_option_apply ? (
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <Link
                              to={`${urlPrefix}/jobs`}
                              className={layoutStyles.link}
                              onClick={() => {
                                trackEvent('ACTIVITY_LINK_APPLYJOB');
                              }}
                            >
                              {intl.formatMessage({ id: 'jobSearchActivity.applyForJob' })}
                            </Link>
                          </Typography>
                        </li>
                      ) : null}
                      {user_settings.job_search_activity_form_option_attend_seminar ? (
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.attendSeminar1' })}</span>
                            <a
                              href="https://labor.hawaii.gov/wdd/job-fairs-and-training-opportunities/"
                              onClick={() => {
                                trackEvent('ACTIVITY_LINK_SEMINAR');
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={layoutStyles.link}
                            >
                              {intl.formatMessage({ id: 'jobSearchActivity.attendSeminar2' })}
                            </a>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.attendSeminar3' })}</span>
                          </Typography>
                        </li>
                      ) : null}
                      {user_settings.job_search_activity_form_option_schedule_meeting ? (
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.scheduleMeeting1' })}</span>
                            <a
                              href="https://www.honolulu.gov/cms-dcs-menu/site-dcs-sitearticles/735-job-seekers.html"
                              onClick={() => {
                                trackEvent('ACTIVITY_LINK_JOBCOACH');
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={layoutStyles.link}
                            >
                              {intl.formatMessage({ id: 'jobSearchActivity.scheduleMeeting2' })}
                            </a>
                          </Typography>
                        </li>
                      ) : null}
                      {user_settings.job_search_activity_form_option_interview ? (
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' })}</span>
                          </Typography>
                        </li>
                      ) : null}
                      {user_settings.job_search_activity_form_option_make_contact ? (
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.directContact' })}</span>
                          </Typography>
                        </li>
                      ) : null}
                      {user_settings.job_search_activity_form_option_register_school_facility ? (
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.registerFacility' })}</span>
                          </Typography>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className={cn(styles.jobSearchActivityMain__half, styles.jobSearchActivityMain__cards)}>
                    {user_settings.job_search_activity_required_weekly_activity_chart ? (
                      <div className={styles.requiredActivityCard}>
                        <div className={styles.requiredActivityCard__text}>
                          <h3 className={styles.requiredActivityCard__title}>
                            {intl.formatMessage({ id: 'jobSearchActivity.requiredWeekly' })}
                            <br />
                            {formatDateRange(
                              generalActivity.weekly_activity_start_date,
                              generalActivity.weekly_activity_end_date
                            )}
                          </h3>
                          <span className={styles.requiredActivityCard__description}>
                            {intl.formatMessage(
                              { id: 'jobSearchActivity.completedTasks' },
                              {
                                completed: generalActivity.weekly_activities_completed,
                                total: generalActivity.weekly_activities_required,
                              }
                            )}
                          </span>
                        </div>
                        <div className={styles.requiredActivityCard__circle}>
                          <CircularProgressbarWithChildren
                            value={completedCurrentWeek > 100 ? 100 : completedCurrentWeek}
                            strokeWidth={18}
                            styles={buildStyles({
                              strokeLinecap: 'butt',
                              pathColor: completedCurrentWeek >= 100 ? '#0E6E10' : '#FCCB00',
                              backgroundColor: '#B7B7B7',
                            })}
                          >
                            <span className={styles.circleCompleted}>
                              {generalActivity.weekly_activities_completed}
                            </span>
                            <span className={styles.circleTotal}>
                              {intl.formatMessage({ id: 'jobSearchActivity.of' })}{' '}
                              {generalActivity.weekly_activities_required}
                            </span>
                          </CircularProgressbarWithChildren>
                        </div>
                      </div>
                    ) : null}
                    {user_settings.job_search_activity_required_overall_activity_chart ? (
                      <div className={styles.requiredActivityCard}>
                        <div className={styles.requiredActivityCard__text}>
                          <h3 className={styles.requiredActivityCard__title}>
                            {intl.formatMessage({ id: 'jobSearchActivity.requiredOverall' })}
                            <br />
                            {formatDateRange(
                              generalActivity.overall_activity_start_date,
                              generalActivity.overall_activity_end_date
                            )}
                          </h3>
                          <span className={styles.requiredActivityCard__description}>
                            {intl.formatMessage(
                              { id: 'jobSearchActivity.completedTasks' },
                              {
                                completed: generalActivity.overall_activities_completed,
                                total: generalActivity.overall_activities_required,
                              }
                            )}
                          </span>
                        </div>
                        <div className={styles.requiredActivityCard__circle}>
                          <CircularProgressbarWithChildren
                            value={completedOverall > 100 ? 100 : completedOverall}
                            strokeWidth={18}
                            styles={buildStyles({
                              strokeLinecap: 'butt',
                              pathColor: completedOverall >= 100 ? '#0E6E10' : '#FCCB00',
                              backgroundColor: '#B7B7B7',
                            })}
                          >
                            <span className={styles.circleCompleted}>
                              {generalActivity.overall_activities_completed}
                            </span>
                            <span className={styles.circleTotal}>
                              {intl.formatMessage({ id: 'jobSearchActivity.of' })}{' '}
                              {generalActivity.overall_activities_required}
                            </span>
                          </CircularProgressbarWithChildren>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {user_settings.job_search_activity_summary ? <Box mb={3} /> : null}
              {user_settings.job_search_activity_weekly_activity
                ? detailedActivity.map((week, idx) => (
                    <Fragment key={`${week.from}${week.to}`}>
                      {user_settings.job_search_activity_summary || idx !== 0 ? (
                        <hr className={layoutStyles.separator} />
                      ) : null}
                      <Box mb={5} />
                      <Typography variant="h4" component="h3">
                        {activityString(week.from, week.to)}
                      </Typography>
                      <Typography variant="body2">
                        {intl.formatMessage(
                          { id: 'jobSearchActivity.tasksCompleted' },
                          { completed: week.items.length, total: week.required }
                        )}
                      </Typography>
                      <div className={editJobStyles.editJobContainer}>
                        <EditJobActivity
                          open={editModalsOpen[idx]}
                          setOpen={(open) =>
                            setEditModalsOpen(
                              editModalsOpen
                                .slice(0, idx)
                                .concat([open])
                                .concat(editModalsOpen.slice(idx + 1))
                            )
                          }
                          addActivityItem={addActivityItem}
                          updateActivityItem={updateActivityItem}
                          setGeneralActivity={(newStatus) => {
                            setGeneralActivity(newStatus);
                            dispatch(setStatus(newStatus));
                          }}
                          selectedActivity={{ ...formsSelectedActivities[idx] }}
                        />
                        <div className={editJobStyles.cardsContainer}>
                          {week.items.map((item) => (
                            <SearchActivityCard
                              key={item.worksearch_id}
                              type={item.state}
                              item={item}
                              date={formatDate(item.activity_date)}
                              notClickable={item.activity_type === 'resume_upload'}
                              onClick={() => {
                                if (item.activity_type === 'resume_upload') {
                                  return;
                                }
                                trackEvent('WEEK_VIEWACTIVITY_COMPLETE');
                                setFormSelectedActivities(
                                  formsSelectedActivities
                                    .slice(0, idx)
                                    .concat([item])
                                    .concat(formsSelectedActivities.slice(idx + 1)),
                                  () =>
                                    setEditModalsOpen(
                                      editModalsOpen
                                        .slice(0, idx)
                                        .concat([true])
                                        .concat(editModalsOpen.slice(idx + 1))
                                    )
                                );
                              }}
                            />
                          ))}
                          {week.items.length < week.required ? (
                            <SearchActivityCard
                              type="ADD_NEW"
                              title={`[${intl.formatMessage({ id: 'jobSearchActivity.addContactInformation' })}]`}
                              onClick={() => {
                                trackEvent('WEEK_ADDACTIVITY_NEW');
                                setFormSelectedActivities(
                                  formsSelectedActivities
                                    .slice(0, idx)
                                    .concat([false])
                                    .concat(formsSelectedActivities.slice(idx + 1)),
                                  () =>
                                    setEditModalsOpen(
                                      editModalsOpen
                                        .slice(0, idx)
                                        .concat([true])
                                        .concat(editModalsOpen.slice(idx + 1))
                                    )
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <Box m={6} />
                    </Fragment>
                  ))
                : null}
            </>
          )}
        </Container>
      </div>
      <LoadingDialog open={loadingDialogOpen} />
      <SSNDialog
        open={ssnDialogOpen}
        setOpen={setSsnDialogOpen}
        refreshCounter={refreshCounter}
        setRefreshCounter={setRefreshCounter}
      />
    </>
  );
};

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb;
    setState(state);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
}

export default JobSearchActivity;

function LoadingDialog({ open }) {
  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent>
        <Box pt={1} pl={6} pr={6} pb={2}>
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
LoadingDialog.propTypes = {
  open: PropTypes.bool,
};
function DownloadDialog({ open, setOpen, dialogTitle }) {
  const intl = useIntl();
  const isSmall = useMediaQuery('(max-width:767px)');
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [modalLoading, setModalLoading] = useState(false);

  const handleFromChange = (date) => {
    setFrom(date);
  };
  const handleToChange = (date) => {
    setTo(date);
  };
  const handleDownload = () => {
    setModalLoading(true);
    getJobSearchActivity(from.toISOString(), to.toISOString()).then((data) => {
      const csvReadyData = arrayToCsv(data);
      downloadBlob(csvReadyData, 'job-search-activity.csv');
      setModalLoading(false);
    });
  };
  const handlePrint = () => {
    setModalLoading(true);
    getJobSearchActivity(from.toISOString(), to.toISOString()).then((data) => {
      console.log('printing', data);
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      fullWidth
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogContent>
        <Box mt={1} mb={3}>
          <Typography variant="h5" component="h2" style={{ color: '#000' }} align="center">
            {intl.formatMessage({ id: `jobSearchActivity.${dialogTitle}` })}
          </Typography>
        </Box>
        <Box pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <Typography variant="caption" align="left">
            {intl.formatMessage({ id: 'jobSearchActivity.dateRange' })}
          </Typography>
        </Box>
        <Box pt={1} pl={isSmall ? 0 : 6} pr={isSmall ? 0 : 6}>
          <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                InputLabelProps={{ required: true }}
                disableToolbar
                variant="filled"
                inputVariant="filled"
                format="MM/dd/yyyy"
                label={intl.formatMessage({ id: 'jobs.filters.from' })}
                value={from}
                onChange={handleFromChange}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                InputLabelProps={{ required: true }}
                disableToolbar
                variant="filled"
                inputVariant="filled"
                format="MM/dd/yyyy"
                label={intl.formatMessage({ id: 'jobs.filters.to' })}
                value={to}
                onChange={handleToChange}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mb={2}>
          <Button
            disabled={modalLoading}
            onClick={dialogTitle === 'printActivity' ? handlePrint : handleDownload}
            color="primary"
            variant="contained"
          >
            {intl.formatMessage({ id: `jobSearchActivity.${dialogTitle === 'printActivity' ? 'print' : 'download'}` })}
          </Button>
        </Box>
        <Box mb={2} pr={2}>
          <Button onClick={() => setOpen(false)} color="primary" variant="contained">
            {intl.formatMessage({ id: 'layout.hamburger.close' })}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
DownloadDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  dialogTitle: PropTypes.string,
};
