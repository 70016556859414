import { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

/* Styles */
import styles from 'styles/financial-help-card.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* UI Elements */
import { Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';

export default function FinancialHelpCard({
  title,
  description,
  toQualifyTitle,
  toQualifyItems,
  image,
  imageDescription,
  imageStyles,
  children,
}) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  return (
    <article className={styles.card}>
      <div className={styles.top}>
        <div className={styles.imageSection}>
          <div className={styles.imageWrapper}>
            <img src={image} alt={title} style={imageStyles} />
          </div>
          <span className={styles.imageDescription}>{imageDescription}</span>
        </div>
        <div className={styles.textSection}>
          <span className={styles.iconButton} onClick={() => setOpen(!open)}>
            {open ? <RemoveIcon className={styles.addIcon} /> : <AddIcon className={styles.addIcon} />}
          </span>
          <Typography variant="h4" component="h2" gutterBottom>
            {title}
          </Typography>
          <Box mb={1} />
          <Typography variant="body2" gutterBottom>
            {description}
          </Typography>
          <Box mb={3} />
          <span className={styles.toQualifyTitle}>{toQualifyTitle}</span>
          <ul className={styles.toQualifyItems}>
            {toQualifyItems.map((q) => (
              <li key={q}>
                <Typography variant="body2" gutterBottom>
                  {q}
                </Typography>
              </li>
            ))}
          </ul>
          {!open ? (
            <>
              <Box mb={3} />
              <span className={layoutStyles.link} onClick={() => setOpen(true)}>
                + {intl.formatMessage({ id: 'financialHelp.learnToApply' })}
              </span>
            </>
          ) : null}
        </div>
      </div>
      <div
        className={cn({
          [styles.learnMore]: true,
          [styles.open]: open,
        })}
      >
        <span className={styles.iconButton} onClick={() => setOpen(false)}>
          <CloseIcon className={styles.addIcon} />
        </span>
        {children}
      </div>
    </article>
  );
}

FinancialHelpCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  toQualifyTitle: PropTypes.string,
  toQualifyItems: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
  imageDescription: PropTypes.string,
  imageStyles: PropTypes.any,
  children: PropTypes.any,
};
