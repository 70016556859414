import { createSlice } from '@reduxjs/toolkit';
import { getWorkSearch } from 'lib/API';

export const workSearchSlice = createSlice({
  name: 'worksearch',
  initialState: {
    activities: [],
    guid: '',
    status: {
      overall_activities_completed: 0,
      overall_activities_required: 0,
      weekly_activities_completed: 0,
      weekly_activities_required: 0,
      overall_activity_start_date: '',
      overall_activity_end_date: '',
      weekly_activity_start_date: '',
      weekly_activity_end_date: '',
    },
    loaded: false,
  },
  reducers: {
    setActivities: (state, action) => {
      state.activities = action.payload;
    },
    setGuid: (state, action) => {
      state.guid = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActivities, setGuid, setStatus, setLoaded } = workSearchSlice.actions;

export default workSearchSlice.reducer;

// Asynchronous thunk actions
export function loadWorkSearchHistory() {
  return (dispatch) => {
    dispatch(setLoaded(false));
    getWorkSearch()
      .then((res) => {
        if (res.status && res.status.worksearch_enabled) {
          dispatch(setActivities(res.activities));
          dispatch(setGuid(res.guid));
          dispatch(setStatus(res.status));
          dispatch(setLoaded(true));
        } else if (res.status) {
          dispatch(setStatus(res.status));
          dispatch(setLoaded(true));
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(setLoaded(true));
      });
  };
}
