import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { languages } from 'i18n/config';
import { useLocation } from 'react-router-dom';

/*-- SEO Reference materials from Google:
Meta tags that Google understands
https://developers.google.com/search/docs/advanced/crawling/special-tags
Tell Google about localized versions of your page
https://developers.google.com/search/docs/advanced/crawling/localized-versions#html
Understand the JavaScript SEO basics
https://developers.google.com/search/docs/advanced/javascript/javascript-seo-basics
*/

export const metaDefaults = {
  title: process.env.REACT_APP_PRODUCT_NAME,
  description: 'Access personalized career recommendations and explore valuable training opportunities.',
  icons: {
    favicon: '/favicon.ico',
  },
};

const MetaContent = ({ pageTitle = '', intlDescription = '', customMetaObjectList = {} }) => {
  const { locale } = useIntl();
  const { pathname } = useLocation();
  const urlPrefix = locale === 'en' ? '' : `/${locale}`;

  const { title, description, icons, ...otherMetaContent } = metaDefaults;

  const renderOptionalMetaContent = (objectList) =>
    Object.keys(objectList).length
      ? Object.entries(objectList).map((k, v) => (
          <>
            <meta key={k} name={k} content={v} />
          </>
        ))
      : null;

  return (
    <Helmet>
      <title>{pageTitle || title}</title>
      <meta name="description" content={intlDescription || description} />
      {languages.map((lang) => (
        <link
          rel="alternate"
          href={`${window.location.origin}${urlPrefix}${pathname.replace(`/${locale}`, '')}`}
          hrefLang={lang}
          key={lang}
        />
      ))}
      {renderOptionalMetaContent(otherMetaContent)};{renderOptionalMetaContent(customMetaObjectList)};
      <link rel="icon" href={'favicon' in icons ? icons.favicon : metaDefaults.favicon} />
      <link
        rel="apple-touch-icon"
        href={'appleTouchIcon' in icons ? icons.appleTouchIcon : metaDefaults.appleTouchIcon}
      />
    </Helmet>
  );
};

MetaContent.propTypes = {
  pageTitle: PropTypes.string,
  intlDescription: PropTypes.string,
  customMetaObjectList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

export default MetaContent;
