import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export default function FullSSNInput(props) {
  const { onChange, type, ...rest } = props;
  return (
    <div>
      <NumberFormat
        {...rest}
        displayType={'input'}
        type={type}
        format="###-##-####"
        placeholder="###-##-####"
        mask="_"
        allowNegative={false}
        allowLeadingZeros={true}
        isNumericString={true}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    </div>
  );
}

FullSSNInput.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
};
