import PropTypes from 'prop-types';
import { css } from '@emotion/css';
import { useState } from 'react';

/* Styles */
import cn from 'classnames';

/* Material UI and other UI Dependencies */
import {
  FormControl,
  Collapse,
  Slider,
  DialogTitle,
  Box,
  IconButton,
  DialogContentText,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import ZipcodeInput from 'components/inputs/ZipcodeInput';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/* i18n */
import { useIntl } from 'react-intl';

/* Styles */
import cssVars from 'styles/vars.module.scss';
import GenericAutocompleteV2 from './GenericAutocompleteV2';

/* CONSTANTS */
const DURATION_FILTER = 'duration';
const LOCATION_FILTER = 'location';
const CAREER_PATHS_FILTER = 'career';
const DISTANCE_FILTER = 'distance';

const styles = {
  textFieldRoot: css(
    {
      marginTop: '0 !important',
    },
    { label: 'text-field-root' }
  ),
  formControl: css(
    {
      width: '100% !important',
      position: 'relative',
      marginBottom: '0 !important',
      '@media screen and (min-width: 768px)': {
        width: '203px !important',
      },
    },
    { label: 'form-control' }
  ),
  ratingsSortingFocused: css({
    '@media screen and (max-width: 767px)': {
      height: '155px',
    },
  }),
  selectLabel: css(
    {
      position: 'absolute',
      zIndex: 1,
      fontSize: '12px',
      color: cssVars.darkGray,
      left: '13px',
      top: '13px',
      pointerEvents: 'none',
      '@media screen and (max-width: 767px)': {
        fontSize: '16px',
      },
    },
    { label: 'select-label' }
  ),
  selectMenu: css(
    {
      boxSizing: 'border-box',
      width: '100% !important',
      borderRadius: '0 !important',
      borderLeft: `solid 1px ${cssVars.mediumGray}`,
      borderRight: `solid 1px ${cssVars.mediumGray}`,
      borderBottom: `solid 1px ${cssVars.mediumGray}`,
      '@media screen and (min-width: 768px)': {
        width: '203px !important',
      },
    },
    { label: 'select-menu' }
  ),
  arrowDown: css(
    {
      width: 15,
      position: 'absolute',
      top: 18,
      right: 14,
      zIndex: 2,
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    { label: 'arrow-down' }
  ),
  selectRoot: css(
    {
      boxSizing: 'border-box',
      border: `solid 1px ${cssVars.mediumGray} !important`,
      borderRadius: 0,
      height: 40,
      width: '100%',
      padding: '12px',
      fontSize: '12px',
      left: '-1px',
      '&:focus': {
        outline: 'none',
      },
      '&::placeholder': {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: cssVars.darkGray,
        opacity: 1 /* Firefox */,
      },
      '&:-ms-input-placeholder': {
        /* Internet Explorer 10-11 */ color: cssVars.darkGray,
      },
      '&::-ms-input-placeholder': {
        /* Microsoft Edge */ color: cssVars.darkGray,
      },
      '&__tall': {
        height: '56px',
      },
      '&__MobileTall': {
        '@media screen and (max-width: 767px)': {
          height: '56px',
        },
      },
    },
    { label: 'select-root' }
  ),
  selectSelect: css(
    {
      boxSizing: 'border-box !important',
      width: '100% !important',
      height: '40px !important',
      backgroundColor: 'white !important',
      '&__tall': {
        height: '56px !important',
      },
      '&__MobileTall': {
        '@media screen and (max-width: 767px)': {
          height: '58px !important',
        },
      },
    },
    { label: 'select-select' }
  ),
  checkboxRoot: css({
    color: 'black !important',
  }),
  checkboxLabel: css({
    color: cssVars.darkGray,
    fontSize: '12px',
    lineHeight: '18px',
    whiteSpace: 'break-spaces',
  }),
  separator: css(
    {
      width: '100%',
      borderTop: `solid 1px ${cssVars.mediumGray}`,
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'none',
      marginTop: '3px',
      marginBottom: '8px',
    },
    { label: 'separator' }
  ),
  focused: css({
    '@media screen and (max-width: 767px)': {
      height: '201px',
    },
  }),
  filterLabel: css({
    marginBottom: '0 !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px !important',
  }),
  formControlLabeLabel: css({
    fontSize: '0.8rem !important',
    color: cssVars.darkGray,
    '@media screen and (max-width: 767px)': {
      fontSize: '0.68rem !important',
    },
  }),
  listItemTextStyle: css({
    fontSize: '0.8rem !important',
    color: cssVars.darkGray,
    '@media screen and (max-width: 767px)': {
      fontSize: '0.68rem !important',
    },
  }),
  transparent: css(
    {
      opacity: 0.5,
    },
    { label: 'transparent' }
  ),
  biggerMargin: css(
    {
      marginTop: 32,
    },
    { label: 'bigger-margin' }
  ),
};

function FilterHeader({ title, isOpen, handleToggleOpen, transparent = false, locality }) {
  return (
    <DialogContentText className={cn({ [styles.filterLabel]: true, [styles.transparent]: transparent })}>
      {title}
      <IconButton
        onClick={handleToggleOpen}
        color={locality === 'ar' ? 'secondary' : 'primary'}
        style={{ padding: 8 }}
        size="large"
      >
        {isOpen ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
      </IconButton>
    </DialogContentText>
  );
}

FilterHeader.propTypes = {
  title: PropTypes.string,
  locality: PropTypes.string,
  isOpen: PropTypes.bool,
  handleToggleOpen: PropTypes.func,
  transparent: PropTypes.bool,
};

function TrainingFiltersV2({
  popper,
  handleClose,
  handleReset,
  // Duration
  minDuration,
  maxDuration,
  durationFilterValues,
  handleChangeDurationFilterValues,
  // Location
  locationFilterTitle = 'jobs.filters.location',
  locations,
  locationsFilterValues,
  handleChangeLocationsFilterValues,
  // Career paths
  careerPaths,
  careerPathsFilterValues,
  handleChangeCareerPathsFilterValues,
  // Distance
  minDistance,
  maxDistance,
  distanceFilterValue,
  handleChangeDistanceFilterValue,
  handleChangeZipcodeFilterValue,
  zipcode,
  zipcodeFailure,
  AutocompleteComponent = GenericAutocompleteV2,
}) {
  const intl = useIntl();
  const locality = process.env.REACT_APP_LOCALITY;
  const [openFilter, setOpenFilter] = useState('');
  const [durationSliderValue, setDurationSliderValue] = useState([
    durationFilterValues[0] || minDuration,
    durationFilterValues[1] || maxDuration,
  ]);
  const [locationTransparent, setLocationTransparent] = useState(false);
  const [distanceTransparent, setDistanceTransparent] = useState(false);
  const [distanceSliderValue, setDistanceSliderValue] = useState(distanceFilterValue || maxDistance);
  const [zipcodeFieldValue, setZipcodeFieldValue] = useState(zipcode);
  const [zipcodeFieldError, setZipcodeFieldError] = useState(false);
  const doFilterByDuration =
    locality !== 'wi' &&
    durationSliderValue[0] !== null &&
    durationSliderValue[1] !== null &&
    minDuration !== '' &&
    maxDuration !== '' &&
    minDuration !== maxDuration;

  const localityDict = {
    wi: 'Wisconsin',
    ripl: 'Colorado',
    co: 'Colorado',
    hi: "Hawai'i",
    md: 'Maryland',
    nj: 'New Jersey',
  };

  const handleChangeDurationSliderValue = (_, newValue) => {
    setDurationSliderValue(newValue);
  };
  const handleChangeDistanceSliderValue = (_, newValue) => {
    setDistanceSliderValue(newValue);
  };
  const handleChangeZipcodeField = (e) => {
    const newZipCode = e.target?.value;
    const newZipCodeLength = e.target?.value?.length;
    setZipcodeFieldValue(newZipCode);
    if (newZipCodeLength === 5) {
      setZipcodeFieldError(false);
    }
    handleChangeZipcodeFilterValue(newZipCodeLength === 5 ? newZipCode : '');
  };
  const handleDistanceSliderClick = () => {
    if (zipcodeFieldValue?.length !== 5) {
      setZipcodeFieldError(true);
    } else {
      setZipcodeFieldError(false);
    }
  };

  const handleToggleOpenFilter = (section) => {
    if (openFilter === section) {
      setOpenFilter('');
    } else {
      setOpenFilter(section);
    }
  };

  const valuetext = (value) => {
    return value;
  };

  return (
    <>
      {!popper ? (
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h5" component="h2" style={{ paddingTop: '16px' }}>
            {intl.formatMessage({ id: 'training.filter' })}
          </Typography>
        </DialogTitle>
      ) : null}
      <DialogContent>
        {/* Career paths Filter */}
        <FilterHeader
          title={intl.formatMessage({ id: 'jobs.filters.careerPaths' })}
          isOpen={openFilter === CAREER_PATHS_FILTER}
          handleToggleOpen={() => handleToggleOpenFilter(CAREER_PATHS_FILTER)}
          locality={locality}
        />
        <Collapse in={openFilter === CAREER_PATHS_FILTER}>
          <Box mb={1}>
            <FormControl className={styles.formControl}>
              <AutocompleteComponent
                activeOptions={careerPathsFilterValues}
                label={intl.formatMessage({ id: 'jobs.filters.startTyping' })}
                options={careerPaths}
                getOptionLabel={(option) => option.title}
                handleChange={(_, newValue) => {
                  handleChangeCareerPathsFilterValues({ target: { value: newValue } });
                }}
              />
            </FormControl>
          </Box>
        </Collapse>
        <hr className={styles.separator} />

        {/* Location Filter */}
        <FilterHeader
          title={intl.formatMessage({ id: locationFilterTitle })}
          isOpen={openFilter === LOCATION_FILTER}
          handleToggleOpen={() => handleToggleOpenFilter(LOCATION_FILTER)}
          transparent={locationTransparent}
          locality={locality}
        />
        <Collapse in={openFilter === LOCATION_FILTER} className={cn({ [styles.transparent]: locationTransparent })}>
          <Box mb={1}>
            <FormControl className={styles.formControl}>
              <AutocompleteComponent
                activeOptions={locationsFilterValues}
                label={intl.formatMessage({ id: 'jobs.filters.startTyping' })}
                options={[...(locations.length ? locations : [])].sort()}
                handleChange={(event, newValue) => {
                  handleChangeDistanceFilterValue(null, maxDistance);
                  setLocationTransparent(false);
                  if (!distanceTransparent && distanceSliderValue !== 100) {
                    setDistanceTransparent(true);
                  }
                  handleChangeLocationsFilterValues(event, newValue);
                }}
              />
            </FormControl>
          </Box>
        </Collapse>
        <hr className={styles.separator} />

        {/* Distance Filter */}
        {maxDistance > 0 ? (
          <>
            <FilterHeader
              title={intl.formatMessage({ id: 'jobs.filters.distance' })}
              isOpen={openFilter === DISTANCE_FILTER}
              handleToggleOpen={() => handleToggleOpenFilter(DISTANCE_FILTER)}
              transparent={distanceTransparent}
              locality={locality}
            />
            <Collapse in={openFilter === DISTANCE_FILTER} className={cn({ [styles.transparent]: distanceTransparent })}>
              <TextField
                error={zipcodeFieldError || zipcodeFailure}
                fullWidth
                helperText={
                  (zipcodeFieldError || zipcodeFailure) &&
                  intl.formatMessage(
                    {
                      id: zipcodeFailure
                        ? 'training.filters.zipcodeFromStateValidation'
                        : 'training.filters.zipcodeValidation',
                    },
                    { state: localityDict[locality] }
                  )
                }
                label={intl.formatMessage({
                  id: zipcodeFieldValue?.length ? 'training.filters.distanceFrom' : 'training.filters.zipcode',
                })}
                margin="normal"
                classes={{ root: styles.textFieldRoot }}
                onChange={handleChangeZipcodeField}
                type="text"
                variant="filled"
                value={zipcodeFieldValue}
                InputProps={{
                  inputComponent: ZipcodeInput,
                }}
                inputProps={{
                  title: intl.formatMessage({ id: 'training.filters.zipcode' }),
                }}
              />
              <Box
                pr={3}
                pl={3}
                onClick={handleDistanceSliderClick}
                className={cn({ [styles.biggerMargin]: zipcodeFailure })}
              >
                <Slider
                  disabled={zipcodeFieldValue?.length !== 5}
                  min={minDistance}
                  max={maxDistance}
                  value={distanceSliderValue}
                  onChange={handleChangeDistanceSliderValue}
                  onChangeCommitted={(event, newValue) => {
                    handleChangeLocationsFilterValues(null, []);
                    setDistanceTransparent(false);
                    if (!locationTransparent && locationsFilterValues.length) {
                      setLocationTransparent(true);
                    }
                    handleChangeDistanceFilterValue(event, newValue);
                  }}
                  aria-labelledby="range-slider"
                  valueLabelDisplay="auto"
                  marks={[
                    {
                      value: minDistance,
                      label: `${intl.formatNumber(minDistance)} ${intl.formatMessage({ id: 'jobs.filters.miles' })}`,
                    },
                    {
                      value: maxDistance,
                      label: `${intl.formatNumber(maxDistance)}+ ${intl.formatMessage({ id: 'jobs.filters.miles' })}`,
                    },
                  ]}
                />
              </Box>
            </Collapse>
            <hr className={styles.separator} />
          </>
        ) : null}

        {/* Duration Filter */}
        {doFilterByDuration && (
          <>
            <FilterHeader
              title={intl.formatMessage({ id: 'training.filters.duration' })}
              isOpen={openFilter === DURATION_FILTER}
              handleToggleOpen={() => handleToggleOpenFilter(DURATION_FILTER)}
              locality={locality}
            />
            <Collapse in={openFilter === DURATION_FILTER}>
              <Box pr={3} pl={3}>
                <Slider
                  min={minDuration}
                  max={maxDuration}
                  value={[...durationSliderValue]}
                  onChange={handleChangeDurationSliderValue}
                  onChangeCommitted={handleChangeDurationFilterValues}
                  aria-labelledby="range-slider"
                  getAriaValueText={valuetext}
                  valueLabelFormat={valuetext}
                  valueLabelDisplay="auto"
                  marks={[
                    {
                      value: minDuration,
                      label: `${minDuration} ${
                        minDuration === 1
                          ? intl.formatMessage({ id: 'training.duration.month' })
                          : intl.formatMessage({ id: 'training.duration.months' })
                      }`,
                    },
                    {
                      value: maxDuration,
                      label: `${maxDuration} ${
                        maxDuration === 1
                          ? intl.formatMessage({ id: 'training.duration.month' })
                          : intl.formatMessage({ id: 'training.duration.months' })
                      }`,
                    },
                  ]}
                />
              </Box>
            </Collapse>
            <hr className={styles.separator} />
            <Box mb={2} />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            // Reset local state
            setDurationSliderValue([minDuration, maxDuration]);
            setDistanceSliderValue(maxDistance);
            // Reset parent state
            handleReset();
          }}
        >
          {intl.formatMessage({ id: 'jobs.filters.reset' })}
        </Button>
        <Button variant="contained" onClick={handleClose} color="primary">
          {intl.formatMessage({ id: 'layout.hamburger.close' })}
        </Button>
      </DialogActions>
    </>
  );
}

TrainingFiltersV2.propTypes = {
  popper: PropTypes.bool,
  handleClose: PropTypes.func,
  handleReset: PropTypes.func,
  minDuration: PropTypes.number,
  maxDuration: PropTypes.number,
  durationFilterValues: PropTypes.arrayOf(PropTypes.number.isRequired),
  handleChangeDurationFilterValues: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.string.isRequired),
  locationsFilterValues: PropTypes.arrayOf(PropTypes.string.isRequired),
  locationFilterTitle: PropTypes.string,
  handleChangeLocationsFilterValues: PropTypes.func,
  careerPaths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      soc: PropTypes.string.isRequired,
    })
  ),
  careerPathsFilterValues: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      soc: PropTypes.string.isRequired,
    })
  ),
  handleChangeCareerPathsFilterValues: PropTypes.func,
  minDistance: PropTypes.number,
  maxDistance: PropTypes.number,
  distanceFilterValue: PropTypes.number,
  handleChangeDistanceFilterValue: PropTypes.func,
  handleChangeZipcodeFilterValue: PropTypes.func,
  zipcode: PropTypes.string,
  zipcodeFailure: PropTypes.bool,
};

export default TrainingFiltersV2;
