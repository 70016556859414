import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { propOr } from 'ramda';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import onboardingStyles from 'styles/onboarding.module.scss';
import cn from 'classnames';
import cssVars from 'styles/vars.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { updateUserData, createUser, updateExperience } from 'shared/src/lib/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setNeedsContactInfo, setPreferredName, setContactUpdate, setOnboardingGuid } from 'app/slices/appSlice';
import { setZipcode } from 'app/slices/experienceSlice';

/* Material UI and other UI Dependencies */
import Typography from '@mui/material/Typography';
import OnboardingSteps from 'components/OnboardingSteps';
import {
  TextField,
  Button,
  Box,
  FormControl,
  CircularProgress,
  Checkbox,
  FormHelperText,
  useMediaQuery,
} from '@mui/material';
import { PhoneNumberInput } from 'shared/components';
import ZipcodeInput from 'components/inputs/ZipcodeInput';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Utils */
import { preventSubmitOnEnter } from 'shared/utils';
import validZipCodes from 'lib/validZipCodes.json';

export default function OnboardingContact() {
  const notRefetch = { retry: false, refetchOnWindowFocus: false };
  const idQuery = useQuery({ queryKey: ['/users/me'], ...notRefetch });
  const experienceQuery = useQuery({ queryKey: ['/users/me/experience/'], enabled: idQuery.isSuccess, ...notRefetch });

  if (idQuery.isLoading || (idQuery.isSuccess && experienceQuery.isLoading)) {
    return (
      <Box m={2} pb={6} pt={4} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  return <ContactForm idQuery={idQuery} experienceQuery={experienceQuery} />;
}

function ContactForm({ idQuery, experienceQuery }) {
  const { isSuccess: idSuccess, data } = idQuery;
  const [submitting, setSubmitting] = useState(false);
  const [contactError, setContactError] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();
  const { contactUpdate } = useSelector((state) => state.app);

  const isSmall = useMediaQuery('(max-width:767px)');

  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/; // eslint-disable-line no-useless-escape
  const emailRegExp = /^\S+@\S+\.\w\w+$/;

  const validationSchema = yup.object({
    first_name: yup
      .string('forms.onboarding.firstNameValidation')
      .min(2, 'forms.onboarding.firstNameMinLength')
      .max(50, 'forms.onboarding.firstNameMaxLength')
      .required('forms.onboarding.firstNameRequired'),
    last_name: yup
      .string('forms.onboarding.lastNameValidation')
      .min(2, 'forms.onboarding.lastNameMinLength')
      .max(50, 'forms.onboarding.lastNameMaxLength')
      .required('forms.onboarding.lastNameRequired'),
    phone_number: yup
      .string('forms.onboarding.phoneNumber')
      .matches(phoneRegExp, 'forms.onboarding.phoneNumberValidation'),
    email: yup
      .string('forms.onboarding.emailRequired')
      .matches(emailRegExp, 'forms.onboarding.emailValidation')
      .required('forms.onboarding.emailRequired'),
    tos: yup.bool().oneOf([true], 'forms.onboarding.tosError'),
    zipcode: yup
      .string('forms.onboarding.zipcode')
      .matches(/^[0-9]+$/, 'forms.onboarding.onlyDigits')
      .oneOf(
        validZipCodes,
        intl.formatMessage(
          {
            id: 'training.filters.zipcodeFromStateValidation',
          },
          { state: 'Colorado' }
        )
      )
      .min(5, 'forms.onboarding.exactlyXDigits')
      .max(5, 'forms.onboarding.exactlyXDigits'),
  });
  const { user: auth0_user } = useAuth0();

  const getInitialValue = (key) => {
    const auth0Dict = {
      first_name: 'given_name',
      last_name: 'family_name',
      phone_number: 'phone_number',
      email: 'email',
    };
    let fromServer = propOr('', key, data);
    if (key === 'zipcode' && experienceQuery.isSuccess) {
      fromServer = propOr('', key, experienceQuery.data);
    }
    const fromAuth0 = key in auth0Dict ? propOr('', auth0Dict[key], auth0_user) : '';
    if (fromServer) {
      return fromServer;
    }
    return fromAuth0;
  };
  const initialValues = {
    first_name: getInitialValue('first_name'),
    last_name: getInitialValue('last_name'),
    phone_number: getInitialValue('phone_number'),
    email: getInitialValue('email'),
    zipcode: getInitialValue('zipcode'),
    tos: contactUpdate ? true : false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async ({ zipcode, tos, ...values }) => {
      trackEvent('ONBOARDING_CONTACT_NEXT');
      setContactError('');
      setSubmitting(true);
      const valuesToSubmit = { ...values, preferred_name: propOr('', 'first_name', values) };

      let data = {
        zipcode,
        language: intl.locale,
      };

      let actionFunc = idSuccess ? updateUserData : createUser;

      actionFunc(valuesToSubmit)
        .then(function (response) {
          dispatch(setContactUpdate(false));
          dispatch(setNeedsContactInfo(false));
          dispatch(setPreferredName(values.first_name));
          dispatch(setZipcode(zipcode));
          setSubmitting(false);
          updateExperience(data).catch((error) => {
            console.log(error);
          });
          history.push(`${urlPrefix}/onboarding/resume-upload`);
          trackEvent('ONBOARDING_CONTACT_FIRST_NAME');
          trackEvent('ONBOARDING_CONTACT_LAST_NAME');
          trackEvent('ONBOARDING_CONTACT_EMAIL');
          if (values.phone_number) {
            trackEvent('ONBOARDING_CONTACT_PHONE');
          }
          if (zipcode) {
            trackEvent('ONBOARDING_CONTACT_ZIP');
          }
          dispatch(setOnboardingGuid({ zipcode, ...values }));
        })
        .catch(function () {
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3}>
          <Typography align="center" variant="h1" gutterBottom>
            {intl.formatMessage({ id: 'onboarding.contact.title', description: 'string' })}
          </Typography>
        </Box>
        <OnboardingSteps activeStep={0} />
      </div>
      <hr className={layoutStyles.separatorBold} />
      <div className={styles.loadingScreen__cardsContainer}>
        <form
          onSubmit={formik.handleSubmit}
          className={cn(styles.loginFlow__form, styles.signInFormNoStripe, onboardingStyles.formContainer)}
        >
          <TextField
            FormHelperTextProps={{
              className: styles.helperText,
            }}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            fullWidth
            helperText={
              formik.touched.first_name &&
              formik.errors.first_name &&
              intl.formatMessage({ id: formik.errors.first_name })
            }
            label={
              <>
                {intl.formatMessage({ id: 'forms.onboarding.firstName' })}
                <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
              </>
            }
            name="first_name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.first_name}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.firstName' }),
            }}
          />
          <TextField
            FormHelperTextProps={{
              className: styles.helperText,
            }}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            fullWidth
            helperText={
              formik.touched.last_name && formik.errors.last_name && intl.formatMessage({ id: formik.errors.last_name })
            }
            label={
              <>
                {intl.formatMessage({ id: 'forms.onboarding.lastName' })}
                <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
              </>
            }
            name="last_name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.last_name}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.lastName' }),
            }}
          />
          <TextField
            FormHelperTextProps={{
              className: styles.helperText,
            }}
            error={formik.touched.email && Boolean(formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email && intl.formatMessage({ id: formik.errors.email })}
            label={
              <>
                {intl.formatMessage({ id: 'forms.signIn.username' })}
                <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
              </>
            }
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onKeyDown={preventSubmitOnEnter}
            type="text"
            value={formik.values.email}
            variant="filled"
            inputProps={{
              title: intl.formatMessage({ id: 'forms.signIn.username' }),
            }}
          />
          <TextField
            FormHelperTextProps={{
              className: styles.helperText,
            }}
            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
            name="phone_number"
            type="text"
            variant="filled"
            fullWidth
            helperText={
              formik.touched.phone_number &&
              formik.errors.phone_number &&
              intl.formatMessage({ id: formik.errors.phone_number })
            }
            autoComplete="off"
            label={intl.formatMessage({ id: 'forms.onboarding.phoneNumber' })}
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            onKeyDown={preventSubmitOnEnter}
            onBlur={formik.handleBlur}
            InputProps={{
              inputComponent: PhoneNumberInput,
            }}
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.phoneNumber' }),
            }}
          />
          <TextField
            FormHelperTextProps={{
              className: styles.helperText,
            }}
            error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
            name="zipcode"
            type="text"
            variant="filled"
            fullWidth
            helperText={
              formik.touched.zipcode &&
              formik.errors.zipcode &&
              intl.formatMessage({ id: formik.errors.zipcode }, { amount: 5 })
            }
            autoComplete="off"
            label={intl.formatMessage({ id: 'forms.onboarding.zipcode' })}
            value={formik.values.zipcode}
            onChange={formik.handleChange}
            onKeyDown={preventSubmitOnEnter}
            onBlur={formik.handleBlur}
            InputProps={{
              inputComponent: ZipcodeInput,
            }}
            inputProps={{
              title: intl.formatMessage({ id: 'forms.onboarding.zipcode' }),
            }}
          />
          {contactError.length > 0 && (
            <div className={styles.errorMessage__container}>
              <label className={styles.errorMessage}>{contactError}</label>
            </div>
          )}
          <div className={onboardingStyles.tosContainer}>
            <FormControl
              classes={{
                root: onboardingStyles.formControlRoot,
              }}
            >
              <div
                style={
                  isSmall
                    ? { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }
                    : { display: 'flex', flexDirection: 'row' }
                }
              >
                <div style={{ position: 'relative', right: '10px' }}>
                  <Checkbox
                    id="tos"
                    checked={formik.values.tos}
                    value={formik.values.tos}
                    onChange={(e) => formik.handleChange({ target: { name: 'tos', value: e.currentTarget.checked } })}
                    name="tos"
                    color="primary"
                  />
                </div>

                <div
                  style={
                    isSmall
                      ? { paddingTop: '5px', position: 'relative', right: '9px' }
                      : {
                          paddingTop: '8px',
                          display: 'flex',
                          alignContent: 'center',
                          position: 'relative',
                          right: '9px',
                        }
                  }
                >
                  <label
                    htmlFor="tos"
                    style={
                      isSmall ? { fontSize: '0.68rem' } : { fontSize: '0.8rem', marginRight: '5px', paddingTop: '2px' }
                    }
                  >
                    {intl.formatMessage({ id: 'forms.onboarding.tos', description: 'string' })}
                  </label>
                  <a
                    rel="noreferrer noopener"
                    style={isSmall ? { marginLeft: '2%' } : null}
                    className={onboardingStyles.tosLink}
                    href={`/#${urlPrefix}/terms-of-use`}
                    target="_blank"
                    onClick={() => trackEvent('ONBOARDING_CONTACT_TERMS')}
                  >
                    {intl.formatMessage({ id: 'forms.onboarding.tosLink', description: 'string' })}
                  </a>
                </div>
              </div>

              {formik.touched.tos && formik.errors.tos && (
                <FormHelperText classes={{ root: onboardingStyles.tosHelperTextRoot }}>
                  {intl.formatMessage({ id: formik.errors.tos })}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className={styles.submitBtn__container}>
            <Button color="secondary" disabled={submitting} size="large" type="submit" variant="contained">
              {submitting ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">
                    {intl.formatMessage({ id: 'forms.signIn.loading', description: 'string' })}
                  </span>
                </div>
              ) : (
                intl.formatMessage({ id: 'forms.onboarding.next', description: 'string' })
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

ContactForm.propTypes = {
  experienceQuery: PropTypes.shape({
    isSuccess: PropTypes.bool,
    data: PropTypes.object,
  }),
  idQuery: PropTypes.shape({
    isSuccess: PropTypes.bool,
    data: PropTypes.object,
  }),
};
