import cn from 'classnames';

/* Styles */
import styles from 'styles/financial-help-careers-card.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';
/* API */

/* UI Elements */
import { Typography } from '@mui/material';
import EmploymentSupport from 'assets/images/employment-support.svg';
import LaunchIcon from '@mui/icons-material/Launch';

import { useSelector } from 'react-redux';

export default function EmploymentSupportCard({ toQualifyTitle }) {
  const intl = useIntl();
  const preferredName = useSelector((state) => state.app.preferredName);
  const title = intl.formatMessage({ id: 'careers.banner.title' }, { name: preferredName });

  return (
    <article className={styles.card}>
      <div className={styles.top}>
        <div className={styles.imageSection}>
          <div className={styles.imageWrapperEmploymentSupport}>
            <img src={EmploymentSupport} alt={title} />
          </div>
        </div>
        <div className={styles.textSection}>
          <Typography variant="h4" component="h2" style={{ color: 'white' }} gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" style={{ color: 'white' }} gutterBottom>
            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'careers.banner.subtitle' }) }} />{' '}
            <a
              href="https://cdle.colorado.gov/wfc"
              rel="noopener noreferrer"
              target="_blank"
              className={layoutStyles.linkWhite}
            >
              {intl.formatMessage({ id: 'careers.banner.link' })}
              <LaunchIcon fontSize="small" style={{ marginLeft: '.15em', position: 'relative', top: '5px' }} />
            </a>
          </Typography>
          <span className={styles.toQualifyTitle}>{toQualifyTitle}</span>
        </div>
      </div>
      <div
        className={cn({
          [styles.learnMore]: true,
          [styles.open]: open,
        })}
      >
        <div />
      </div>
    </article>
  );
}
