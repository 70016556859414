/*-- Routing --*/
import { Route, Switch, Redirect } from 'react-router-dom';
import { REC_ENGINE_ROUTES } from 'lib/routes';

/* Redux */
import { useSelector } from 'react-redux';

/*-- Layout --*/
import RecommendationsHeader from 'components/RecommendationsHeader';

/*-- Components --*/
// import { Chat } from 'shared/components';

/*-- Pages --*/
import LoadingRecommendations from 'components/LoadingRecommendations';
import Careers from 'components/Careers';
import Jobs from 'components/Jobs';
import Training from 'components/Training';
import Experience from 'components/Experience';
import Ratings from 'components/Ratings';

import JobSearchActivity from 'components/JobSearchActivity';
import FinancialHelp from 'components/FinancialHelp';

const AuthenticatedPagesSwitch = () => {
  const { loadingRecommendations, user_settings, urlPrefix } = useSelector((state) => state.app);

  return loadingRecommendations ? (
    <LoadingRecommendations />
  ) : (
    <>
      <RecommendationsHeader />
      <Switch>
        <Route path={[`/${REC_ENGINE_ROUTES.CAREERS}`, `/:lang/${REC_ENGINE_ROUTES.CAREERS}`]}>
          <Careers />
        </Route>
        <Route path={[`/${REC_ENGINE_ROUTES.JOBS}`, `/:lang/${REC_ENGINE_ROUTES.JOBS}`]}>
          <Jobs />
        </Route>
        <Route path={[`/${REC_ENGINE_ROUTES.TRAINING}`, `/:lang/${REC_ENGINE_ROUTES.TRAINING}`]}>
          <Training />
        </Route>
        <Route path={[`/${REC_ENGINE_ROUTES.EXPERIENCE}`, `/:lang/${REC_ENGINE_ROUTES.EXPERIENCE}`]}>
          <Experience />
        </Route>
        <Route path={[`/${REC_ENGINE_ROUTES.RATINGS}`, `/:lang/${REC_ENGINE_ROUTES.RATINGS}`]}>
          <Ratings />
        </Route>
        <Route path={['/job-search-activity', '/:lang/job-search-activity']}>
          {user_settings.add_job_search_activity_hero_button ? (
            <JobSearchActivity />
          ) : (
            <Redirect to={`${urlPrefix}/${REC_ENGINE_ROUTES.CAREERS}`} />
          )}
        </Route>
        <Route path={['/financial-help', '/:lang/financial-help']}>
          {user_settings.view_benefits_for_job_seekers_button ? (
            <FinancialHelp />
          ) : (
            <Redirect to={`${urlPrefix}/${REC_ENGINE_ROUTES.CAREERS}`} />
          )}
        </Route>
        <Route path={['/onboarding', '/:lang/onboarding']} children={<Redirect to={`${urlPrefix}/careers`} />} />
        <Route>
          <Redirect to={`${urlPrefix}/${REC_ENGINE_ROUTES.CAREERS}`} />
        </Route>
      </Switch>
      {/* <Chat preferredName={preferredName} /> */}
    </>
  );
};

export default AuthenticatedPagesSwitch;
