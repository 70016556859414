import { createSlice } from '@reduxjs/toolkit';
import { formatTimestamp } from 'lib/utils';

export const ratingsSlice = createSlice({
  name: 'ratings',
  initialState: {
    results: {
      up: [],
      down: [],
      lookup: {},
    },
    currentTab: 'up',
    availableFilters: ['career', 'job', 'training'],
    activeFilters: [],
  },
  reducers: {
    setRatings: (state, action) => {
      state.results = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setActiveFilters: (state, action) => {
      state.activeFilters = action.payload;
    },
    thumbsUpClick: (state, { payload: item }) => {
      // action.payload represents the new state of the card after the click
      if (item.rating !== 'liked') {
        state.results.up.push({ ...item, date: formatTimestamp(new Date()), rating: 'liked' });
        const thumbsDownIndex = state.results.down.reduce(
          (prev, curr, idx) => (curr.id === item.id && curr.type === item.type ? idx : prev),
          -1
        );
        if (thumbsDownIndex > -1) {
          state.results.down.splice(thumbsDownIndex, 1);
        }
        ratingsSlice.caseReducers.addToLookup(state, { payload: { id: item.id, rating: 'liked' } });
      } else {
        const thumbsUpIndex = state.results.up.reduce(
          (prev, curr, idx) => (curr.id === item.id && curr.type === item.type ? idx : prev),
          -1
        );
        if (thumbsUpIndex > -1) {
          state.results.up.splice(thumbsUpIndex, 1);
        }
        ratingsSlice.caseReducers.removeFromLookup(state, { payload: item.id });
      }
    },
    thumbsDownClick: (state, { payload: item }) => {
      // action.payload represents the new state of the card after the click
      if (item.rating !== 'disliked') {
        state.results.down.push({ ...item, date: formatTimestamp(new Date()), rating: 'disliked' });
        const thumbsUpIndex = state.results.up.reduce(
          (prev, curr, idx) => (curr.id === item.id && curr.type === item.type ? idx : prev),
          -1
        );
        if (thumbsUpIndex > -1) {
          state.results.up.splice(thumbsUpIndex, 1);
        }
        ratingsSlice.caseReducers.addToLookup(state, { payload: { id: item.id, rating: 'disliked' } });
      } else {
        const thumbsDownIndex = state.results.down.reduce(
          (prev, curr, idx) => (curr.id === item.id && curr.type === item.type ? idx : prev),
          -1
        );
        if (thumbsDownIndex > -1) {
          state.results.down.splice(thumbsDownIndex, 1);
        }
        ratingsSlice.caseReducers.removeFromLookup(state, { payload: item.id });
      }
    },
    revertThumbsClick: (state, { payload: item }) => {
      // action.payload represents the state of the card before the click
      ratingsSlice.caseReducers.removeFromLookup(state, { payload: item.id });
      if (item.rating === 'liked') {
        state.results.up.push(item);
        ratingsSlice.caseReducers.addToLookup(state, { payload: { id: item.id, rating: 'liked' } });
      } else {
        const thumbsUpIndex = state.results.up.reduce(
          (prev, curr, idx) => (curr.id === item.id && curr.type === item.type ? idx : prev),
          -1
        );
        if (thumbsUpIndex > -1) {
          state.results.up.splice(thumbsUpIndex, 1);
        }
      }
      if (item.rating === 'disliked') {
        state.results.down.push(item);
        ratingsSlice.caseReducers.addToLookup(state, { payload: { id: item.id, rating: 'disliked' } });
      } else {
        const thumbsDownIndex = state.results.down.reduce(
          (prev, curr, idx) => (curr.id === item.id && curr.type === item.type ? idx : prev),
          -1
        );
        if (thumbsDownIndex > -1) {
          state.results.down.splice(thumbsDownIndex, 1);
        }
      }
    },
    addToLookup: (state, action) => {
      state.results.lookup[action.payload.id] = action.payload.rating;
    },
    removeFromLookup: (state, action) => {
      delete state.results.lookup[action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setRatings,
  setCurrentTab,
  setActiveFilters,
  thumbsUpClick,
  thumbsDownClick,
  revertThumbsClick,
  addToLookup,
  removeFromLookup,
} = ratingsSlice.actions;

export default ratingsSlice.reducer;
