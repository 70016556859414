/* Styles */
import styles from 'styles/onboarding.module.scss';

export default function OnboardingHeader() {
  return (
    <div className={styles.onboardingHeader}>
      <div className={styles.topBar} />
      <div className={`${styles.colorBar} ${styles.colorBar__color1}`} />
      <div className={`${styles.colorBar} ${styles.colorBar__color2}`} />
      <div className={`${styles.colorBar} ${styles.colorBar__color3}`} />
      <div className={`${styles.colorBar} ${styles.colorBar__color4}`} />
    </div>
  );
}
