import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';
import cn from 'classnames';

/* i18n */
import { useIntl, FormattedMessage } from 'react-intl';

/* Material UI and other UI Dependencies */
import Typography from '@mui/material/Typography';
import alert from 'assets/images/Alert.svg';

export default function ErrorMessage() {
  const intl = useIntl();
  const { logout } = useAuth0();
  const { urlPrefix } = useSelector((state) => state.app);

  return (
    <div className={styles.loginFlow}>
      <div className={styles.loginFlow__formContainer}>
        <div className={styles.loginFlow__form}>
          <Typography align="center" variant="h1" gutterBottom>
            <FormattedMessage id="app.unrecoverableError1" />
            <Link
              to={`${urlPrefix}/login`}
              className={cn(layoutStyles.link, styles.editLink)}
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              {intl.formatMessage({ id: 'app.unrecoverableError2' })}
            </Link>
            <FormattedMessage id="app.unrecoverableError3" />
          </Typography>
        </div>
        <hr className={layoutStyles.separatorBold} />
        <div className={styles.loadingScreen__cardsContainer}>
          <p style={{ textAlign: 'center' }}>
            <img width="80" src={alert} alt={intl.formatMessage({ id: 'app.error' })} />
          </p>
        </div>
      </div>
    </div>
  );
}
